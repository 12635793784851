// Standard library import
import React, { useContext, useEffect, useState } from 'react';

// External library imports
import { get as LodashGet } from 'lodash';

// Internal module imports
import RequestBid from './RequestBid';
import TabsComponent from '../../components/Tabs/Tabs';
import MaintenanceHours from './MaintenanceHour';
import { AuthContext } from '../../context/AuthContext';
import { LicenseService } from '../../services/LicenseService';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';
import { sortByName } from '../../utils/objectHelper';
import { CamelCaseToTextCase } from '../../utils/stringHelper';

// Css imports
import classes from '../../styles/Bids.module.css';

const RequestBidIndex = ({ licensesData, market, updateQueryParams, queryParamsData }) => {
    const { state } = useContext(AuthContext);
    const licenses = state.isAuthorized && licensesData ? licensesData : state.isAuthorized ? LodashGet(state, 'user.customerId.license') : '';
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(queryParamsData.license ? queryParamsData.license : {});
    const [groups, setGroups] = useState([]);
    const [selectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    useEffect(() => {
        const licenseOptions = [];
        if (licenses && licenses.length && Array.isArray(licenses)) {
            for (let license of licenses) {
                licenseOptions.push({ label: CamelCaseToTextCase(license._id), value: license.licenseId });
            }
        } else if (licenses) {
            for (let license in licenses) {
                if (licenses[license].enabled) {
                    licenseOptions.push({ label: CamelCaseToTextCase(license), value: licenses[license].id });
                }
            }
        }
        if (licenseOptions.length) {
            setOptions(licenseOptions);
        }
    }, [licenses]);

    const handleError = (err) => {
        console.log(err);
    };

    const handleGetLicenseSuccess = ({ data }) => {
        const licenseDetails = data.data;

        let allGroupsArray = [];
        allGroupsArray = sortByName(licenseDetails);
        setGroups(allGroupsArray.flat());
    };

    useEffect(() => {
        if (selectedOption.value) {
            getLicense();
        }
    }, [selectedOption]);

    const getLicense = async () => {
        if (selectedOption.value) {
            LicenseService.getLicenseDetailsV2(
                selectedOption.value,
                () => startLoader('getLicense'),
                handleGetLicenseSuccess,
                handleError,
                () => stopLoader('getLicense')
            );
        }
    };

    const onChange = (data) => {
        setSelectedOption(data);
        updateQueryParams('license', JSON.stringify(data));
    };

    const tabs = [
        {
            name: 'Request Bid',
            component: (
                <RequestBid
                    market={market}
                    groups={groups}
                    licenseId={selectedOption.value}
                    options={options}
                    selectedOption={selectedOption}
                    onChange={onChange}
                    updateQueryParams={updateQueryParams}
                    queryParamsData={queryParamsData}
                />
            ),
        },
        {
            name: 'Maintenance Hours',
            component: (
                <MaintenanceHours
                    market={market}
                    groups={groups}
                    licenseId={selectedOption.value}
                    options={options}
                    selectedOption={selectedOption}
                    onChange={onChange}
                    updateQueryParams={updateQueryParams}
                    queryParamsData={queryParamsData}
                />
            ),
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                tabs={tabs}
                selectedIndex={selectedIndex}
                onChangeTab={(index) => {
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default RequestBidIndex;
