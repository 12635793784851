import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const CustomerPasswordValidation = yup.object().shape({
    currentPassword: yup.string().required(ERRORS.required),
    newPassword: yup.string().required(ERRORS.required).min(8).max(40),
    confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword')], "Passwords don't match!")
        .required(ERRORS.required),
});
