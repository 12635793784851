import React, { useEffect, useState, useContext } from 'react';
import classes from '../Home.module.css';
import { useLoader } from '../../../hooks/use-loader.hook';
import { LicenseService } from '../../../services/LicenseService';
import ChartWithoutAxis from '../../../components/ApexCharts/ChartWithoutAxis';
import { AuthContext } from '../../../context/AuthContext';

const MonthsRevenueCard = () => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [revenue, setRevenue] = useState(0);
    const { state } = useContext(AuthContext);
    const customRoutes = state?.user?.allowed || null;

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        LicenseService.getRevenue(startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        setRevenue(parseInt(data?.data?.totalRevenue).toLocaleString('da'));
        setData(data.data.revenueData);
    };

    const handleError = (error) => {
        console.error(error);
    };

    return (
        <div style={{}}>
            {customRoutes?.eConomics && (
                <div className={classes.Revenue}>
                    <div className={classes.Title}>Month's Revenue</div>
                    {<ChartWithoutAxis disableZoom series={data} title="" curve="smooth" />}
                    <div className={classes.Subtitle}>{revenue} € </div>
                </div>
            )}
        </div>
    );
};

export default MonthsRevenueCard;
