import { HttpClient } from '../utils/httpClient';

const PATH = {
    login: '/user/login',
    verify: '/user/profile',
    logout: '/user/logout',
    requestPassword: '/user/requestPassword',
    updatePassword: '/user/update-password',
    resetPassword: '/user/reset-password',
    resendOtp: '/user/resend-otp',
    resetWithOtp: '/user/reset-with-otp',
    updateUserProfile: '/user/update-profile',
};

const login = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.login}`, payload).then(callback).catch(error).finally(next);
};

const requestPassword = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.requestPassword}`, payload).then(callback).catch(error).finally(next);
};

const verify = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.verify}`).then(callback).catch(error).finally(next);
};

const logout = (start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.logout}`).then(callback).catch(error).finally(next);
};

const updatePasswordInfo = (payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.updatePassword}`, { ...payload })
        .then(callback)
        .catch(error)
        .finally(next);
};

const resetPassword = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.resetPassword}`, payload).then(callback).catch(error).finally(next);
};

const resendOtp = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.resendOtp}`, payload).then(callback).catch(error).finally(next);
};

const resetWithOtp = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.resetWithOtp}`, payload).then(callback).catch(error).finally(next);
};

const updateUserProfile = (payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.updateUserProfile}`, payload).then(callback).catch(error).finally(next);
};

export const UserService = {
    login,
    verify,
    logout,
    requestPassword,
    updatePasswordInfo,
    resetPassword,
    resendOtp,
    resetWithOtp,
    updateUserProfile,
};
