import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

const validateNumber = () => yup.number().typeError(ERRORS.number).min(0).required(ERRORS.required);
const capacityAndPriceValidation = () =>
    yup
        .array()
        .of(validateNumber())
        .min(['FCR'].includes(yup.ref('biddingMarket')) ? 6 : ['FFR', 'FCR-D-INC'].includes(yup.ref('biddingMarket')) ? 24 : 1);

export const RequestBidValidation = yup.object().shape({
    capacity: capacityAndPriceValidation(),
    price: capacityAndPriceValidation(),
    groupId: yup.string().required(ERRORS.required),
    startDate: yup.date().required(ERRORS.required),
    endDate: yup.date().min(yup.ref('startDate'), "end date can't be before start date").required(ERRORS.required),
    excludedDays: yup
        .array()
        .of(
            yup.object({
                date: yup.string().required(ERRORS.required),
                reason: yup.string().required(ERRORS.required),
            })
        )
        .min(0),
});

export const MaintenanceHourValidationValidation = yup.object().shape({
    groupId: yup.string().required(ERRORS.required),
    date: yup.date().required(ERRORS.required),
    hours: yup
        .array()
        .of(yup.object().shape({ label: yup.string(), value: yup.number() }))
        .required()
        .min(1),
});
