import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const LoginValidation = yup.object().shape({
    email: yup.string().email(ERRORS.email).required(ERRORS.required),
    password: yup.string().required(ERRORS.required),
});

export const ForgetState1Validation = yup.object().shape({
    email: yup.string().email(ERRORS.email).required(ERRORS.required),
});

export const ForgetStage2Validation = yup.object().shape({
    newPassword: yup.string().required(ERRORS.required).min(8).max(40),
    confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword')], "Passwords don't match")
        .required(ERRORS.required),
    otp: yup.number().required(ERRORS.required),
});
