import classes from '../../../styles/AllDevices.module.css';
import React, { useContext } from 'react';
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { USER_ROLE } from '../../../constants';
import { AuthContext } from '../../../context/AuthContext';
import { get as LodashGet } from 'lodash';
import CreateButton from '../../../components/Buttons/CreateButton';

const AllDevices = ({ groups, addKeyModal, setAddKeyModal, processedDevices }) => {
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');

    return (
        <div className={classes.AllDevices}>
            <div className={classes.Header}>
                <div>
                    <Typography content="All Devices" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={processedDevices.length} />
                        </span>
                    </div>
                </div>
                {[USER_ROLE.SUDOER].includes(userRole) ? (
                    <div>
                        <CreateButton size="medium" text="Add Key" onClick={() => setAddKeyModal(true)} />
                    </div>
                ) : null}
            </div>
            <div>
                <Table
                    addIndex
                    head={['S No', 'KEY/CVR', 'Meter Name', 'Meter Id', ...([USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? ['Actions'] : [])]}
                    keys={['cvr', 'name', 'id', ...([USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? ['actions'] : [])]}
                    data={processedDevices}
                />
            </div>
        </div>
    );
};

export default AllDevices;
