export const getThemeFromLC = () => {
    const storageItem = localStorage.getItem('THEME');
    if (storageItem && storageItem !== 'undefined') {
        return JSON.parse(storageItem);
    } else {
        return null;
    }
};

export const saveUser = ({ id, email, customerId, customerName, profileImage, token }) => {
    const storageItem = localStorage.getItem('GM_USERS');
    let existingUsers = [];
    if (storageItem && storageItem !== 'undefined') existingUsers = JSON.parse(storageItem);
    const extUserIndex = existingUsers.findIndex((u) => u.id === id && u.customerId === customerId);
    const user = { id, email, customerId, customerName, profileImage, token };
    if (extUserIndex >= 0) {
        if (existingUsers[extUserIndex].recentApps) {
            user.recentApps = existingUsers[extUserIndex].recentApps;
        }
        existingUsers[extUserIndex] = user;
    } else {
        existingUsers.push(user);
    }
    localStorage.setItem('GM_USERS', JSON.stringify(existingUsers));
};

export const getUsers = () => {
    const storageItem = localStorage.getItem('GM_USERS');
    let existingUsers = [];
    if (storageItem && storageItem !== 'undefined') existingUsers = JSON.parse(storageItem);
    return existingUsers;
};

export const removeUser = (id, customerId) => {
    const storageItem = localStorage.getItem('GM_USERS');
    let existingUsers = [];
    if (storageItem && storageItem !== 'undefined') existingUsers = JSON.parse(storageItem);
    const extUserIndex = existingUsers.findIndex((u) => u.id === id && u.customerId === customerId);
    if (extUserIndex >= 0) {
        existingUsers.splice(extUserIndex, 1);
        localStorage.setItem('GM_USERS', JSON.stringify(existingUsers));
    }
};

export const removeAllUsers = () => {
    let existingUsers = [];
    localStorage.setItem('GM_USERS', JSON.stringify(existingUsers));
};

const getActiveUser = (state) => {
    const {
        user: { _id, customerId },
    } = state;

    const storageItem = localStorage.getItem('GM_USERS');
    let users = [];
    if (storageItem && storageItem !== 'undefined') users = JSON.parse(storageItem);
    const activeUserIndex = users.findIndex((u) => u.id === _id && u.customerId === customerId._id);

    return {
        activeUser: users[activeUserIndex],
        users,
    };
};

export const saveRecentApps = (data, state) => {
    const { activeUser, users } = getActiveUser(state);

    if (!activeUser?.recentApps) activeUser.recentApps = [];
    const filtered = activeUser.recentApps.filter((app) => app.id !== data.id);
    filtered.unshift(data);
    filtered.splice(5, 1);
    activeUser.recentApps = filtered;

    localStorage.setItem('GM_USERS', JSON.stringify(users));
};

export const getRecentApps = (state) => {
    const { activeUser } = getActiveUser(state);

    return activeUser?.recentApps ? activeUser.recentApps : [];
};
