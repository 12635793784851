import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import PublicLayout from './layouts/PublicLayout';
import { useContext, useEffect } from 'react';
import { get as lodashGet } from 'lodash';
import { LoaderContext } from './context/LoaderContext';
import { REMOVE_USER, SET_USER, START_LOADER, STOP_LOADER } from './constants';
import { AuthContext } from './context/AuthContext';
import { PrivateRoute } from './routes/PrivateRoute';
import { UserService } from './services/UserService';
import PrivacyPolicy from './layouts/PrivacyPolicy';
import { getThemeFromLC } from './utils/localStrorage';
import { toast } from 'react-toastify';

function App() {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const { state } = useContext(AuthContext);
    const { dispatch: authDispatch } = useContext(AuthContext);
    const dispatchSetUser = (payload) => authDispatch({ type: SET_USER, payload });
    const removeUser = (payload) => authDispatch({ type: REMOVE_USER });

    useEffect(() => {
        const theme = lodashGet(state, 'user.customerId.theme') || getThemeFromLC() || { primaryColor: '#0f123f' };
        document.documentElement.style.setProperty('--color-primary', theme.primaryColor);
    }, [lodashGet(state, 'user.customerId.theme')]);

    useEffect(() => {
        verifyUser();
    }, []);
    const verifyUser = () => {
        UserService.verify(
            () => startLoader('verifyUser'),
            handleVerifySuccess,
            handleVerifyError,
            () => stopLoader('verifyUser')
        );
    };
    useEffect(() => {
        startLoader('firstLoad');
        let timer1 = setTimeout(() => stopLoader('firstLoad'), 1000);
        return () => {
            clearTimeout(timer1);
        };
    }, []);

    const handleVerifySuccess = ({ data }) => {
        if (data.success) {
            dispatchSetUser({ user: data.data });
        }
    };

    const handleVerifyError = (err) => {
        removeUser();
        const message = err && err.response ? err.response.data.message : '';
        if (message && message !== 'jwt malformed') {
            toast.error(message);
        }
    };

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/auth" component={AuthLayout} />
                    <Route path="/privacyPolicy" component={PrivacyPolicy} />
                    <PrivateRoute path="/" component={PublicLayout} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
