import classes from '../../../styles/Alerts.module.css';
import React, { useContext, useEffect, useState } from 'react';
import TabsComponent from '../../../components/Tabs/Tabs';
import AlertsComponent from './AlertsComponent';
import TriggeredAlerts from './TriggeredAlerts';
import { LicenseService } from '../../../services/LicenseService';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { AuthContext } from '../../../context/AuthContext';
import { get as LodashGet } from 'lodash';
import DeleteModalComponent from '../../../components/DeleteModal/DeleteModal';
import { Capitalize } from '../../../utils/stringHelper';
import { UilPen, UilTrash } from '@iconscout/react-unicons';
import buttonClasses from '../../../components/Buttons/Button.module.css';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';

const defaultValues = {
    name: '',
    criteria: 'gt',
    value: '',
    email: '',
    properties: {
        notification: false,
        group: '',
        type: 'power',
        startTime: '00:00',
        endTime: '23:59',
        alertInterval: 0
    },
};

const Alerts = ({ license, startLoader, stopLoader, updateQueryParams, queryParamsData }) => {
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');

    const [initialValues, setInitialValues] = useState(defaultValues);
    const [createAlertModal, setCreateAlertModal] = useState(queryParamsData.createAlertModal ? queryParamsData.createAlertModal : false);
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date());
    const [selectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    const [alerts, setAlerts] = useState([]);
    const [triggeredAlerts, setTriggeredAlerts] = useState([]);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: true });

    const { _id: licenseId, type: licenseType } = license;

    const getStatus = (status) => <div className={classes.ActionIcons}>{Boolean(status) ? <div className={classes.Active}></div> : <div className={classes.InActive}></div>}</div>;

    const getActions = (id, alert) => {
        return (
            <div className={classes.ActionIcons}>
                <div onClick={() => handleEdit(alert)}>
                    <CustomTooltip content={'Edit'}>
                        <UilPen className={buttonClasses.ButtonIcon} />
                    </CustomTooltip>
                </div>
                <div onClick={() => setDeleteModal({ status: true, id })}>
                    <CustomTooltip content={'Delete'}>
                        <UilTrash className={buttonClasses.ButtonIcon} />
                    </CustomTooltip>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (licenseType && licenseId) {
            getAllAlerts();
            getTriggeredAlerts();
        }
    }, [licenseId, licenseType]);

    useEffect(() => {
        if (licenseType && licenseId) {
            getTriggeredAlerts();
        }
        updateQueryParams('date', date);
    }, [date]);

    const getAllAlerts = () => {
        LicenseService.getAllAlerts(
            licenseType,
            licenseId,
            () => startLoader('getAlerts'),
            handleGetAlertsSuccess,
            handleError,
            () => stopLoader('getAlerts')
        );
    };

    const getTriggeredAlerts = () => {
        LicenseService.getTriggeredAlerts(
            licenseId,
            moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            () => startLoader('getTriggeredAlerts'),
            handleGetTriggeredAlertsSuccess,
            handleError,
            () => stopLoader('getTriggeredAlerts')
        );
    };

    const handleGetAlertsSuccess = ({ data }) => {
        let processedData = data.data.map((alert) => {
            return {
                ...alert,
                criteriaN: alert.criteria === 'lt' ? 'Less Than' : 'Greater Than',
                alertInterval: alert.properties?.alertInterval ? `${alert.properties?.alertInterval} mins` : `5 mins`,
                scheduleStartAndEnd: alert.properties?.startTime && alert.properties?.endTime ? `${alert.properties?.startTime}/${alert.properties?.endTime}` : `00:00/23:59`,
                typeN: Capitalize(alert.properties.type),
                status: getStatus(alert.properties?.notification),
                action: getActions(alert._id, alert),
            };
        });
        setAlerts(processedData);
    };

    const handleGetTriggeredAlertsSuccess = ({ data }) => {
        setTriggeredAlerts(data.data);
    };

    const handleEdit = (alert) => {
        setInitialValues(alert);
        setCreateAlertModal(true);
    };
    useEffect(() => {
        if (!createAlertModal) setInitialValues(defaultValues);
    }, [createAlertModal]);
    const handleDelete = (id) => {
        LicenseService.deleteAlert(
            id,
            () => startLoader('deleteAlert'),
            ({ data }) => handleDeleteSuccess(data, id),
            handleError,
            () => stopLoader('deleteAlert')
        );
    };

    const handleDeleteSuccess = (data, id) => {
        toast.success('Alert Deleted!');
        getAllAlerts();
    };

    const handleCreateAlert = (val, resetForm) => {
        if (!val._id)
            LicenseService.createAlert(
                licenseId,
                licenseType,
                val,
                () => startLoader('createAlert'),
                ({ data }) => handleCreateSuccess(data),
                handleError,
                () => stopLoader('createAlert')
            );
        else
            LicenseService.updateAlert(
                val._id,
                licenseType,
                val,
                () => startLoader('createAlert'),
                ({ data }) => handleCreateSuccess(data, true),
                handleError,
                () => stopLoader('createAlert')
            );
    };

    const handleCreateSuccess = (data, edit) => {
        getAllAlerts();
        setCreateAlertModal(false);
        !edit ? toast.success('Alert Created') : toast.success('Alert Updated');
    };

    const handleClose = () => {
        setInitialValues(defaultValues);
        setCreateAlertModal(false);
        updateQueryParams('createAlertModal', false);
    };

    const handleError = (err) => console.log(err);

    const tabs = [
        {
            name: 'Alerts',
            component: (
                <AlertsComponent
                    license={license}
                    alerts={alerts}
                    initialValues={initialValues}
                    handleSubmit={handleCreateAlert}
                    open={createAlertModal}
                    setOpen={setCreateAlertModal}
                    getAllAlerts={getAllAlerts}
                    handleClose={handleClose}
                    updateQueryParams={updateQueryParams}
                />
            ),
        },
        {
            name: 'Triggered Alerts',
            component: <TriggeredAlerts getTriggeredAlerts={getTriggeredAlerts} alerts={triggeredAlerts} date={date} setDate={setDate} />,
        },
    ];
    return (
        <div className={classes.Alert + ' ' + classes.ContainerBorderRadius}>
            <TabsComponent tabs={tabs} selectedIndex={selectedIndex} onChangeTab={(index) => updateQueryParams('subTab', index)} />
            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ id: '', status })}
                deviceId={deleteModal.id}
            ></DeleteModalComponent>
        </div>
    );
};

export default Alerts;
