import XLSX from 'xlsx';

export const DownloadAsExcel = (data, fileName, passedHeaders, index = null) => {
    //Process the raw data - Change default headers to custom headers
    let processedData = [...data],
        processedHeader = {};

    Object.keys(data[index ? index : 0]).forEach((header, i) => {
        processedHeader[header] = passedHeaders[i];
    });

    processedData.splice(0, 0, processedHeader);

    // Create blank workbook
    const workBook = XLSX.utils.book_new();

    // Convert json to worksheet
    const workSheet = XLSX.utils.json_to_sheet(processedData, { skipHeader: true });

    // Insert the worksheet into workbook
    XLSX.utils.book_append_sheet(workBook, workSheet, ['sheet1']);

    // Download the sheet
    XLSX.writeFile(workBook, `${fileName}.xlsx`);
};

export const DownloadAsExcelMultipleSheet = (data, sheetName, fileName, passedHeaders) => {
    // Create blank workbook
    const workBook = XLSX.utils.book_new();

    data.map((item, index) => {
        //Process the raw data - Change default headers to custom headers
        let processedData = [...item],
            processedHeader = {};

        Object.keys(item[0] || {}).forEach((header, i) => {
            processedHeader[header] = passedHeaders[i];
        });

        processedData.splice(0, 0, processedHeader);

        // Convert json to worksheet
        const workSheet = XLSX.utils.json_to_sheet(processedData, { skipHeader: true });

        // Insert the worksheet into workbook
        XLSX.utils.book_append_sheet(workBook, workSheet, sheetName[index]);
    });

    // Download the sheet

    XLSX.writeFile(workBook, `${fileName}.xlsx`);
};
