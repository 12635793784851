import React, { useState } from 'react';
import classes from '../E3App.module.css';
import DeviceList from './DeviceList';
import Overview from './Overview';

const Details = ({ license, device, setDevice, startLoader, stopLoader, setEditGroup: setEditDevice, setRefresh, setPrefillGroup, updateQueryParams, queryParamsData = {} }) => {
    const [createGroupModal, setCreateGroupModal] = useState(false);
    const [editGroup, setEditGroup] = useState(false);

    return (
        <div>
            <div className={classes.DeviceDashboard}>
                <DeviceList
                    license={license}
                    groups={license}
                    editGroup={editGroup}
                    createGroupModal={createGroupModal}
                    setCreateGroupModal={setCreateGroupModal}
                    setDevice={setDevice}
                    setEditGroup={setEditGroup}
                    setPrefillGroup={setPrefillGroup}
                />
                <Overview
                    setEditGroup={setEditDevice}
                    device={device}
                    startLoader={startLoader}
                    stopLoader={stopLoader}
                    updateQueryParams={updateQueryParams}
                    queryParamsData={queryParamsData}
                />
            </div>
        </div>
    );
};

export default Details;
