import * as yup from 'yup';
import { ERRORS, phoneRegExp } from '../../utils/validationHelper';

export const CreateAlertValidation = yup.object().shape({
    name: yup.string().required(ERRORS.required).min(3).max(25),
    criteria: yup.string().required(ERRORS.required),
    value: yup.number().typeError(ERRORS.number).required(ERRORS.required).min(0).max(10000),
    email: yup.string().email(ERRORS.email).required(ERRORS.required).max(100),
    properties: yup.object({
        notification: yup.bool().required(ERRORS.required),
        group: yup.string().required(ERRORS.required).max(100),
        type: yup.string().required(ERRORS.required),
        startTime: yup.string().optional(),
        endTime: yup.string().optional(),
        alertInterval: yup.number().typeError(ERRORS.number).required(ERRORS.required).min(0).max(10000)
    }),
});
