import * as yup from 'yup';
import { phoneRegExp, emailRegex } from '../../utils/validationHelper';

export const CustomerProfileValidation = yup.object({
    contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(),
    countryCode: yup.string().required(),
    primaryContactName: yup.string().min(3).max(32).required(),
    billingEmail: yup.string().matches(emailRegex, 'invalid email').required(),
    primaryContactEmail: yup.string().matches(emailRegex, 'invalid email').required(),
});

export const userProfileValidation = yup.object({
    name: yup.string().required(),
    contact: yup.string().required(),
    countryCode: yup.string().required(),
    email: yup.string().email().required(),
});
