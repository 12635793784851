import React, { useContext, useEffect, useState } from 'react';
import classes from '../../styles/Bids.module.css';
import Typography from '../../components/Typography/Typography';
import { useLoader } from '../../hooks/use-loader.hook';
import { AuthContext } from '../../context/AuthContext';
import { get as LodashGet } from 'lodash';
import { USER_ROLE } from '../../constants';
import Table from '../../components/Table/Table';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import AddMaintenanceHours from './ModalComponent/AddMaintenanceHours';
import { BidRequestService } from '../../services/BidRequestService';
import { momentTimeFormater } from '../../utils/dateHelper';
import { UilPen } from '@iconscout/react-unicons';
import { UilTrash } from '@iconscout/react-unicons';
import DeleteModalComponent from '../../components/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import { DropdownComponent } from '../../components/Inputs/Input';

const getGroupHashMap = (groups) => {
    const hashMap = {};
    groups.forEach((g) => {
        hashMap[g._id] = g;
    });
    return hashMap;
};

const MaintenanceHours = ({ market, groups = [], licenseId, options = [], selectedOption, onChange, updateQueryParams, queryParamsData }) => {
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const [startLoader, stopLoader] = useLoader();
    const [groupHashMap] = useState(getGroupHashMap(groups));
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [addModal, setAddModal] = useState({ status: false, editData: {} });
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [filterType, setFilterType] = useState(queryParamsData.filter ? queryParamsData.filter : { label: 'Current', value: 'current' });

    const closeAddModal = () => {
        setAddModal({ status: false, editData: {} });
    };

    useEffect(() => {
        getData();
    }, [refresh, licenseId, filterType, groups]);

    useEffect(() => {
        updateQueryParams('filter', JSON.stringify(filterType));
    }, [filterType]);

    const getData = () => {
        const params = {
            filterType: filterType?.value,
            licenseId,
            market,
        };
        BidRequestService.GetMaintenanceHour(params, startLoader, handleFetchSuccess, handleError, stopLoader);
    };

    const handleFetchSuccess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const processed = data.data.map((i) => ({
                ...i,
                date: momentTimeFormater(i.date).format('YYYY-MM-DD'),
                instance: groupHashMap[i.groupId]?.name,
                hoursT: i.hourSlots?.toString(),
                action: (
                    <div style={{ display: 'flex', gap: '1vw', justifyContent: 'center', alignItems: 'center' }}>
                        {[USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) && (
                            <>
                                <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setAddModal({ status: true, editData: i })} />
                                <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, id: i._id })} />
                            </>
                        )}
                    </div>
                ),
            }));
            setData(processed);
        }
    };

    const handleDelete = (id) => {
        BidRequestService.DeleteMaintenanceHour(id, startLoader, deleteSuccess, handleError, stopLoader);
    };

    const deleteSuccess = () => {
        toast.success('Maintenance record deleted successfully!');
        setRefresh(Math.random() * 1000);
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className={classes.Bids}>
            <div className={classes.Header} style={{ marginTop: '1vw' }}>
                <div>
                    <Typography content="Maintenance Hours" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={data.length} />
                        </span>
                    </div>
                    <div className={classes.InputContainer}>
                        <div className={classes.FieldControl2}>
                            <div style={{ minWidth: '10vw' }}>
                                <DropdownComponent
                                    name="license"
                                    options={options}
                                    defaultValue={selectedOption?.value ? selectedOption : options[0]}
                                    onChange={(e) => {
                                        if (e.value !== selectedOption?.value) {
                                            onChange(e);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '0.5vw' }}>
                    <div className={classes.InputContainer}>
                        <div className={classes.FieldControl2}>
                            <div style={{ minWidth: '10vw' }}>
                                <DropdownComponent
                                    name="type"
                                    options={[
                                        { label: 'Current', value: 'current' },
                                        { label: 'Past', value: 'past' },
                                    ]}
                                    defaultValue={filterType}
                                    onChange={(e) => {
                                        if (e.value !== filterType?.value) {
                                            setFilterType(e);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.ButtonsContainer}>
                        <div>
                            {[USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? (
                                <button
                                    onClick={() => {
                                        setAddModal({ status: true, editData: {} });
                                    }}
                                    style={{ width: '7vw', margin: 0 }}
                                    className="btn-primary"
                                >
                                    Add Hours
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <Table
                head={['Date', 'Instance', 'Market', 'Hour Slots', ...([USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? ['Actions'] : [])]}
                keys={['date', 'instance', 'biddingMarket', 'hoursT', ...([USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? ['action'] : [])]}
                data={data}
            />
            <ModalComponent isOpen={addModal.status} setOpen={closeAddModal} style={{ overflowY: 'initial' }}>
                <AddMaintenanceHours market={market} groups={groups} setOpen={closeAddModal} setRefresh={setRefresh} licenseId={licenseId} editRequest={addModal.editData} />
            </ModalComponent>
            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ id: '', status })}
                deviceId={deleteModal.id}
            ></DeleteModalComponent>
        </div>
    );
};

export default MaintenanceHours;
