import classes from './CollapsibleWithRename.module.css';
import React, { useContext, useEffect } from 'react';
import Typography from '../Typography/Typography';
import Collapsible from 'react-collapsible';
import DeviceIcon from '../../assets/device.png';
import AddIcon from '../../assets/add.png';
import { AuthContext } from '../../context/AuthContext';
import { get as LodashGet } from 'lodash';
import { USER_ROLE } from '../../constants';

const CollapsibleWithRename = ({
    group,
    setDevice,
    open,
    setOpen,
    setCreateGroupModal,
    setEditGroup,
    deviceIcon,
    disableDoubleClick,
    source,
    addDevice,
    tempName,
    openFirstGroup = false,
    setFirstDevice = false,
}) => {
    let timeout = null;

    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');

    const { name, devices, _id: groupId } = group;

    const onClick = (e, name) => {
        e.preventDefault();
        console.log(name);
        if (timeout === null) {
            timeout = window.setTimeout(() => {
                timeout = null;
                open === name ? setOpen('') : setOpen(name);
            }, 300);
        }
    };
    useEffect(() => {
        if (openFirstGroup) {
            open !== name && setOpen(name);
        }
        if (setFirstDevice) devices[0] && setDevice({ ...devices[0], group: groupId });
    }, [openFirstGroup, setFirstDevice, JSON.stringify(devices)]);

    const onDoubleClick = (e) => {
        e.preventDefault();
        window.clearTimeout(timeout);
        setEditGroup &&
            setEditGroup({
                id: group?._id,
                name: group.name,
                properties: group?.properties,
                _id: group?._id,
                updatedAt: group?.updatedAt,
            });
        timeout = null;
        setCreateGroupModal && setCreateGroupModal(true);
    };

    return (
        <div className={classes.Container}>
            <div className={classes.GroupsContainer}>
                <div
                    role="button"
                    onClick={(e) => onClick(e, name)}
                    onDoubleClickCapture={!disableDoubleClick && [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? onDoubleClick : ''}
                    className={classes.Label}
                >
                    <div className="img-container">
                        <img src={DeviceIcon} alt="" />
                    </div>
                    <div>
                        <Typography size="14" content={name} />
                    </div>
                    <div></div>
                </div>
                <Collapsible open={open === name}>
                    <ul className={classes.DeviceList}>
                        {devices.map((device) => {
                            if (source === 'dataHub' && device.enabled === false) return;
                            return (
                                <li onClick={() => setDevice({ ...device, group: groupId })}>
                                    <div className={classes.Label} role="button">
                                        <div className="img-container">
                                            <img src={deviceIcon || DeviceIcon} alt="" />
                                        </div>
                                        <div>
                                            <Typography size="14" content={tempName ? device?.tempName : device.name} />
                                        </div>
                                        <div></div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </Collapsible>
            </div>
        </div>
    );
};

export default CollapsibleWithRename;
