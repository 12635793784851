import React, { useContext, useEffect, useState } from 'react';
import TabsComponent from '../../components/Tabs/Tabs';
import Details from './Details/Details';
import AllDevices from './AllDevices/AllDevices';
import { DataHubService } from '../../services/DataHubService';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';
import { Input, ToggleButtonWithState, TextArea } from '../../components/Inputs/Input';
import classes from './DataHub.module.css';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import Typography from '../../components/Typography/Typography';
import { Form, Formik } from 'formik';
import { AuthContext } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import { DataHubValidation } from '../../validations/DataHub/DataHub';
import { get as LodashGet } from 'lodash';
import { sortByName } from '../../utils/objectHelper';
import Dropdown from '../../components/Inputs/Dropdown';
import ExportModal from './Details/ExportModal';
import { useLocation, useHistory } from 'react-router-dom';
import { UilSetting } from '@iconscout/react-unicons';
import CustomTooltip from '../../components/CustomToolTip/CustomTooltip';

const getPriceDropdown = (customer) => {
    const { powerCost = [] } = customer;
    const options = [{ label: 'None', value: '' }];
    powerCost.forEach((item) => options.push({ label: item.name, value: item._id }));
    return options;
};

const DataHub = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [queryParamsData] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        device: queryParams.get('device') ? JSON.parse(queryParams.get('device')) : '',
    });

    const [initialValues, setInitialValues] = useState({});
    const { state } = useContext(AuthContext);
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [refresh, setRefresh] = useState({});
    const [open, setOpen] = useState(false);
    const [addKeyModal, setAddKeyModal] = useState(false);
    const [groups, setGroups] = useState([]);
    const [device, setDevice] = useState({});
    const [editDevice, setEditDevice] = useState({});
    const [processedDevices, setProcessedDevices] = useState([]);
    const [priceDropdown] = useState(getPriceDropdown(LodashGet(state, 'user.customerId')));
    const [showExportModal, setShowExportModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);

    useEffect(() => {
        readAllData();
    }, [refresh]);

    useEffect(() => {
        if (queryParamsData.tab !== selectedIndex) {
            updateQueryParams('tab', selectedIndex);
        }
    }, [selectedIndex]);

    const readAllData = () => {
        DataHubService.readAll(
            () => startLoader('readAll'),
            handleReadAllSuccess,
            handleError,
            () => stopLoader('readAll')
        );
    };

    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            queryParamsNew = new URLSearchParams();
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };

    const handleOpen = (device, groupId) => {
        if (groupId) {
            device.groupId = groupId;
        }
        if (device?.dataHub) {
            setInitialValues({
                ...device.dataHub,
            });
        } else {
            setInitialValues({
                ...device,
            });
        }

        setDevice(device);
        setOpen(true);
    };

    const handleReadAllSuccess = ({ data }) => {
        const allMeters = data.data;
        const processedData = allMeters.map((meter) => {
            return {
                name: meter.key || '--',
                _id: meter?._id,
                devices: meter.meterIdData.map((data) => {
                    const name = LodashGet(data, 'dataHub.name') || data.id;
                    const priceSignal = LodashGet(data, 'dataHub.priceSignal');
                    const taxValue = LodashGet(data, 'dataHub.taxValue');
                    return {
                        ...data.dataHub,
                        name: name,
                        id: data.id,
                        groupId: meter._id,
                        priceSignal: data.dataHub.priceSignal,
                        taxValue: data.dataHub.taxValue,
                        cvr: meter.key,
                        actions: (
                            <div className={classes.ActionContainer}>
                                <div>
                                    <CustomTooltip content={'Settings'}>
                                        <UilSetting style={{ color: 'var(--color-primary)' }} size={'1.2vw'} onClick={() => handleOpen(data, meter._id)} />
                                    </CustomTooltip>
                                </div>
                                <div className={classes.ToggleContainer}>
                                    <ToggleButtonWithState
                                        disabled={!Boolean(priceSignal && !(taxValue === null || taxValue === undefined))}
                                        value={data.dataHub.enabled}
                                        onChange={(e) => handleToggleChange(data, meter._id, e.target.checked)}
                                    />
                                </div>
                            </div>
                        ),
                    };
                }),
            };
        });
        setGroups(processedData);
        const processedDevices = processedData.map((group) => group.devices).flat();
        setProcessedDevices(processedDevices);
    };

    const handleToggleChange = (device, groupId, value) => {
        device.groupId = groupId;
        DataHubService.updateStatus(
            device.groupId,
            device.id,
            { dataHub: { ...device.dataHub, enabled: value } },
            () => startLoader('updateStatus'),
            handleUpdateStatusSuccess,
            handleError,
            () => stopLoader('updateStatus')
        );
    };

    const handleUpdateStatusSuccess = ({ data }) => {
        toast.success('Configuration Updated!');
        readAllData();
    };

    const handleUpdateDataHub = (values) => {
        DataHubService.updateDevice(
            device.groupId,
            device.id,
            { dataHub: values },
            () => startLoader('updateMeter'),
            handleUpdateSuccess,
            handleError,
            () => stopLoader('updateMeter')
        );
    };

    const handleUpdateSuccess = ({ data }) => {
        setDevice({});
        setInitialValues({});
        readAllData();

        toast.success('Configuration Updated!');
        setOpen(false);
    };

    const handleAddKey = (val) => {
        DataHubService.addKey(
            val,
            () => startLoader('addKey'),
            handleAddKeySuccess,
            handleError,
            () => stopLoader('addKey')
        );
    };

    const handleAddKeySuccess = ({ data }) => {
        toast.success('Key added successfully!');
        setAddKeyModal(false);
        readAllData();
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    let tabs = [
        {
            name: 'Details',
            component: (
                <Details
                    setEditDevice={setEditDevice}
                    handleOpen={handleOpen}
                    setRefresh={setRefresh}
                    startLoader={startLoader}
                    stopLoader={stopLoader}
                    processedDevices={processedDevices}
                    groups={sortByName(groups)}
                    queryParamsData={queryParamsData}
                    updateQueryParams={updateQueryParams}
                />
            ),
        },
        {
            name: 'All Meters',
            component: <AllDevices processedDevices={processedDevices} groups={groups} addKeyModal={addKeyModal} setAddKeyModal={setAddKeyModal} />,
        },
    ];

    return (
        <div className={'ventilation'}>
            <TabsComponent tabs={tabs} setShowExportModal={setShowExportModal} selectedIndex={selectedIndex} onChangeTab={setSelectedIndex} />
            <ExportModal isOpen={showExportModal} setOpen={setShowExportModal} groups={groups} />

            <ModalComponent isOpen={open} setOpen={setOpen}>
                <div className={classes.AddKey}>
                    <Typography content={initialValues?.groupId ? 'Edit Config' : 'Add Config'} />
                    <Formik enableReinitialize validationSchema={DataHubValidation} initialValues={initialValues} onSubmit={(val) => handleUpdateDataHub(val)}>
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="name">Enter Name of MeterId</label>
                                        <Input name="name" id="name" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="group">Price Signal</label>
                                        <Dropdown
                                            name="priceSignal"
                                            options={priceDropdown}
                                            defaultValue={priceDropdown.filter((signal) => signal.value === initialValues.priceSignal)[0]}
                                        />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="taxValue">Tax Value (Kr/mwh)</label>
                                        <Input name="taxValue" id="taxValue" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="address">Address</label>
                                        <TextArea name="address" id="address" />
                                    </div>
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <div className="btn-secondary" onClick={() => setOpen(false)}>
                                            Cancel
                                        </div>
                                    </div>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </ModalComponent>
            <ModalComponent isOpen={addKeyModal} setOpen={setAddKeyModal}>
                <div className={classes.AddKey}>
                    <Typography content="Add Key" />
                    <Formik enableReinitialize initialValues={initialValues} onSubmit={(val) => handleAddKey(val)}>
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="key">
                                            Enter new key to add (CVR) <span className="required">*</span>
                                        </label>
                                        <Input name="key" id="key" />
                                    </div>
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <div className="btn-secondary" onClick={() => setAddKeyModal(false)}>
                                            Cancel
                                        </div>
                                    </div>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </ModalComponent>
        </div>
    );
};

export default DataHub;
