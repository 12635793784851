import DataHub from '../containers/DataHub/DataHub';
import VersionHistory from '../containers/VersionHistory/VersionHistory';
import SupportNotes from '../containers/SupportNotes/SupportNotes';
import DashboardIcon from '../assets/sidebar/Dashboard.svg';
import DemandResponseIcon from '../assets/sidebar/DemandResponse.svg';
import E3app from '../assets/sidebar/E3app.svg';
import InsightIcon from '../assets/sidebar/Insight.svg';
import VersionHistoryIcon from '../assets/sidebar/VersionHistory.svg';
import DataHubIcon from '../assets/sidebar/DataHub.svg';
import GmOnlineIcon from '../assets/sidebar/GmOnline.svg';
import CarbonIcon from '../assets/sidebar/co2.svg';
import EconomicsIcon from '../assets/sidebar/economic.svg';
import SupportNoteIcon from '../assets/supportNote.svg';

import Home from '../containers/Home/Home';
import GmOnline from '../containers/GmOnline/GmOnline';
import CarbonEmission from '../containers/CarbonEmission/CarbonEmission';
import Economics from '../containers/Economics/Economics';
import E3Apps from '../containers/E3App/E3App';
import RevenuePotentialCalculator from '../containers/RevenueCalculator';

export const PROTECTED_ROUTES = [
    {
        name: 'E3 Dashboard',
        id: 'dashboard',
        url: '/',
        component: Home,
        icon: DashboardIcon,
    },
    {
        name: 'E3 Apps',
        id: 'e3App',
        url: '/e3-App',
        icon: E3app,
        component: E3Apps,
    },
    {
        name: 'Demand Response',
        id: 'demandResponse',
        url: '/demandResponse',
        icon: DemandResponseIcon,
        childRoutes: [],
    },
    {
        name: 'Insights',
        id: 'insights',
        url: '/insights',
        icon: InsightIcon,
        childRoutes: [
            {
                name: 'Support Notes',
                id: 'supportNotes',
                url: '/supportNotes',
                component: SupportNotes,
                icon: SupportNoteIcon,
            },
            {
                name: 'Carbon Emission',
                id: 'carbonEmission',
                url: '/carbonemission',
                component: CarbonEmission,
                icon: CarbonIcon,
            },
            {
                name: 'TrueGreen Online',
                id: 'trueGreenOnline',
                url: '/trueGreenonline',
                component: GmOnline,
                icon: GmOnlineIcon,
            },
            {
                name: 'Version History',
                id: 'versionHistory',
                url: '/versionhistory',
                component: VersionHistory,
                icon: VersionHistoryIcon,
            },
            {
                name: 'Data Hub',
                id: 'dataHub',
                url: '/dataHub',
                component: DataHub,
                icon: DataHubIcon,
            },
            {
                name: 'Revenue Potential Calculator',
                id: 'revenuePotentialCalculator',
                url: '/revenuePotentialCalculator',
                component: RevenuePotentialCalculator,
                icon: DataHubIcon,
            },
        ],
    },
    {
        name: 'eConomics',
        id: 'eConomics',
        url: '/eConomics',
        component: Economics,
        icon: EconomicsIcon,
    },
];
