import React, { useState } from 'react';
import classes from '../../../styles/DeviceList.module.css';
import CollapsibleWithRenameForGroup from '../../../components/CollapsibleWithRename/CollapsibleWithRenameForGroup';
import { UilSitemap } from '@iconscout/react-unicons'

const CollapsibleComponent = ({ groups, setGroup, name, open, index, setSelectedIndex }) => {
    return (
        <div>
            <CollapsibleWithRenameForGroup
                open={open}
                setOpen={() => setSelectedIndex(index)}
                group={groups}
                setDevice={setGroup}
                deviceIcon={<UilSitemap size="1.2vw" style={{ color: 'var(--color-primary)' }}/>}
                title={name}
                setEditGroup={() => {}}
                setCreateGroupModal={() => {}}
            />
        </div>
    );
};

const GroupsList = ({ licenses, setGroup, style = {} }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const modified = licenses.map((l) => ({ ...l, name: l._id }));
    return (
        <div className={classes.GroupList}>
            <div className={classes.GroupListSubContainer}>
                <div>
                    {modified.map((l, i) => (
                        <CollapsibleComponent
                            key={l.licenseId}
                            groups={l.groups}
                            setGroup={setGroup}
                            name={l.name}
                            open={modified[selectedIndex].name}
                            index={i}
                            setSelectedIndex={setSelectedIndex}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GroupsList;
