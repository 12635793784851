import classes from '../../styles/CapacityContent.module.css';
import React from 'react';

const Details = ({ data, setOpen, revenue }) => {
    return (
        <div className={classes.CapacityContent}>
            <div className={classes.Title}>DETAILS</div>
            <div className={classes.Content}>
                <div className={classes.Container}>
                    <div>
                        <div className={classes.Title} style={{ textAlign: 'center' }}>
                            Settlement Price
                        </div>
                        <table>
                            <tr>
                                <th>Product Name</th>
                                <th>Price</th>
                            </tr>
                            {data &&
                                data.map((response) => (
                                    <tr>
                                        <td>{response.productName} </td>
                                        <td>{response.price} * 4</td>
                                    </tr>
                                ))}
                            <tr>
                                <td>Total Price </td>
                                <td>{revenue}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className={classes.ButtonContainer}>
                    <div className="btn-primary" onClick={() => setOpen(false)}>
                        Close
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Details;
