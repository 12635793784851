import classes from './Chart.module.css';
import React from 'react';
import Chart from 'react-apexcharts';

const ChartWithoutAxis = ({ title, series, curve = 'smooth', colors, strokeWidth, markerSize, xFormatter = null, type, dataLabels = { enabled: false }, disableZoom }) => {
    const state = {
        options: {
            chart: {
                type: 'line',
                sparkline: {
                    enabled: true,
                },
            },
            stroke: {
                curve: 'smooth',
                width: 3,
                colors: ['var(--color-primary)'],
            },
            xaxis: {
                tooltip: {
                    enabled: false,
                },
                labels: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                tooltip: {
                    enabled: false,
                },
                labels: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            tooltip: {
                enabled: false,
                style: {
                    fontSize: '1vw',
                },
            },
            legend: {
                show: false,
            },
        },
        series,
    };
    return (
        <div className={classes.ChartContainer} style={{ height: '90%' }}>
            <Chart options={state.options} series={state.series} type={type || 'line'} height="90%" />
        </div>
    );
};

export default ChartWithoutAxis;
