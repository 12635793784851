import classes from './RecentApps.module.css';
import React, { useState, useContext } from 'react';
import { UilHistory } from '@iconscout/react-unicons';
import { useHistory } from 'react-router-dom';
import { getRecentApps } from '../../utils/localStrorage';
import { AuthContext } from '../../context/AuthContext';
import Draggable from 'react-draggable';

const RecentApps = ({ setActive }) => {
    const [recent, setRecent] = useState(false);
    const [recentApps, setRecentApps] = useState([]);

    const { state } = useContext(AuthContext);
    const history = useHistory();

    const handleRecent = () => {
        const storedRecentApps = getRecentApps(state);
        setRecentApps(storedRecentApps);
        recent ? setRecent(false) : setRecent(true);
    };

    const handleClick = (app) => {
        setActive(app.childRouteName ? app.childRouteName : app.name);
        history.push(app.url);
    };

    return (
        <div className={classes.RecentVisitContainer}>
            <Draggable>
                <div className={classes.draggableDiv}>
                    {recent && recentApps.length > 0 && (
                        <div className={classes.RecentVisitExpand}>
                            {recentApps.map((app) => {
                                return (
                                    <span className={classes.RecentVisitExpand_Item}>
                                        <img key={app.id} src={app.icon} onClick={() => handleClick(app)} className={classes.RecentVisitIcon} alt={app.name} />
                                        <p className={classes.RecentVisitExpand_Item_P}>{app.name}</p>
                                    </span>
                                );
                            })}
                        </div>
                    )}
                    <div className={classes.RecentVisit} onDoubleClick={() => handleRecent()}>
                        <UilHistory className={classes.RecentVisitIcon} />
                    </div>
                </div>
            </Draggable>
        </div>
    );
};

export default RecentApps;
