import classes from './Sidebar.module.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { PROTECTED_ROUTES } from '../../routes/Routes';
import { AuthContext } from '../../context/AuthContext';
import { get as lodashGet } from 'lodash';
import SCLogo from '../../assets/new-sc-logo-no-background.svg';
import LogoutIcon from '../../assets/logout1.svg';
import DefaultLicenseIcon from '../../assets/sidebar/DefaultLicense.svg';
import { useHistory } from 'react-router-dom';
import { ControlledMenu, MenuItem, useClick } from '@szhsin/react-menu';
import { UilAngleRight } from '@iconscout/react-unicons';
import { UserService } from '../../services/UserService';
import { toast } from 'react-toastify';
import { REMOVE_USER } from '../../constants';
import { useLoader } from '../../hooks';
import _ from 'lodash';
import Inactive from '../../containers/ErrorPages/Inactive';
import { removeUser, saveRecentApps } from '../../utils/localStrorage';
import Pricing from '../../assets/sidebar/pricing.svg';
import Nordpool from '../../containers/Nordpool/Nordpool';
import RealtimeMarket from '../../containers/RealtimeMarket/RealtimeMarket';
import PowerCostIcon from '../../assets/sidebar/PowerCost.svg';
import RealtimeMarketIcon from '../../assets/sidebar/realtimeMarket.svg';
import DemandResponse from '../../containers/DemandResponse/DemandResponse';
import { CamelCaseToTextCase } from '../../utils/stringHelper';

const getEnabledE3License = (licenses = {}) => {
    const enabledLicenses = [];
    Object.keys(licenses).forEach((l) => {
        if (!['loadShifting', 'CFM'].includes(l)) {
            enabledLicenses.push(l);
        }
    });
    return enabledLicenses;
};

const Sidebar = ({ active, setActive }) => {
    const { state, dispatch: authDispatch } = useContext(AuthContext);
    const [startLoader, stopLoader] = useLoader();
    const isTruegreen = lodashGet(state, 'user.customerId.trueGreen.enabled');
    const licenses = lodashGet(state, 'user.customerId.license');
    const logo = lodashGet(state, 'user.customerId.logo');
    const userId = lodashGet(state, 'user._id');
    const customerId = lodashGet(state, 'user.customerId._id');
    const enabledLicense = lodashGet(state, 'user.customerId.enabledLicense');
    const enabledMarkets = lodashGet(state, 'user.customerId.biddingMarkets');
    const [mouseHover, setMouseHover] = useState(false);
    const [timer, setTimer] = useState(null);
    const history = useHistory();

    const processedFilterRoutes = [];

    PROTECTED_ROUTES.forEach((route) => {
        if (!isTruegreen && route.id === 'dashboard') {
        } else if (!isTruegreen && route.id === 'e3') {
        } else if (licenses && route.id === 'cfmdashboard' && !licenses['CFM']?.enabled) {
        } else if (licenses && route.id === 'CFM' && !licenses['CFM']?.enabled) {
        } else if (licenses && route.id === 'loadShifting' && !licenses['loadShifting']?.enabled) {
        } else {
            if (route.id === 'e3App' && enabledLicense && Object.keys(enabledLicense).length) {
                const childRoutes = [];
                for (let licenseTypeId in enabledLicense) {
                    const license = enabledLicense[licenseTypeId];
                    if (license.licenseType.name !== 'dataHub') {
                        childRoutes.push({
                            name: CamelCaseToTextCase(license.licenseType.name),
                            id: license.licenseType.name,
                            url: `/${license.licenseType.name}?licenseTypeId=${licenseTypeId}`,
                            icon: license.licenseType.icon,
                        });
                    }
                }
                route.childRoutes = childRoutes;
            }
            if (route.id === 'demandResponse') {
                const childRoutes = [
                    {
                        name: 'Day ahead',
                        id: 'dayahead',
                        url: '/dayahead',
                        component: Nordpool,
                        icon: PowerCostIcon,
                    },
                    {
                        name: 'Intraday',
                        id: 'intraday',
                        url: '/intraday',
                        component: RealtimeMarket,
                        icon: RealtimeMarketIcon,
                    },
                ];
                if (enabledMarkets && enabledMarkets.length) {
                    for (let market of enabledMarkets) {
                        if (market !== 'DC-PLANNER') {
                            childRoutes.push({
                                name: market,
                                id: market,
                                url: `/${market}`,
                                component: DemandResponse,
                                icon: Pricing,
                            });
                        }
                    }
                }

                route.childRoutes = childRoutes;
            }
            if (route.id !== 'e3') {
                if (route.id === 'e3App') {
                    route.childRoutes?.length && processedFilterRoutes.push(route);
                } else {
                    processedFilterRoutes.push(route);
                }
            }
            if (isTruegreen && route.id === 'e3') {
                const avaialbleE3Routes = [];
                route.childRoutes.forEach((croute) => avaialbleE3Routes.push(croute.id));
                const enabledE3License = getEnabledE3License(licenses);
                enabledE3License.forEach((enabledL) => {
                    if (!avaialbleE3Routes.includes(enabledL)) {
                        route.childRoutes.push({
                            name: _.startCase(enabledL),
                            id: enabledL,
                            url: `/${enabledL}`,
                            component: Inactive,
                            icon: DefaultLicenseIcon,
                        });
                    }
                });
            }
        }
    });

    const handleMouseOver = () => {
        if (!mouseHover && !timer) {
            setTimer(
                setTimeout(() => {
                    setMouseHover(true);
                }, 500)
            );
        }
    };

    const handleMouseOut = () => {
        if (timer) clearTimeout(timer);
        setTimer(null);
        setMouseHover(false);
    };

    const stopPropagation = (e) => e.stopPropagation();

    const handleClick = (route) => {
        setActive(route.name);
        history.push(route.url);
        route.url !== '/' && saveRecentApps(route, state);
    };

    const handleLogout = () => {
        removeUser(userId, customerId);
        UserService.logout(
            () => startLoader('logout'),
            handleSuccess,
            handleError,
            () => stopLoader('logout')
        );
    };

    const handleSuccess = ({ data }) => {
        toast.success('Logout Success!');
        authDispatch({ type: REMOVE_USER });
        history.push('/auth/login');
    };

    const handleError = (e) => {
        authDispatch({ type: REMOVE_USER });
        // localStorage.clear();
        history.push('/auth/login');
    };

    const BottomRoutes = [
        {
            name: 'Logout',
            id: 'logout',
            handleClick: handleLogout,
            icon: LogoutIcon,
        },
    ];

    const renderLogo = () => {
        return (
            <div className={classes.ImageWarpper}>
                {logo ? (
                    <>
                        <img src={logo} className={classes.LogoImage} />
                        <p className={classes.PoweredBy}>
                            Powered by <strong>Truegreen</strong>
                        </p>
                    </>
                ) : (
                    <>
                        <img src={SCLogo} className={classes.LogoImage} />
                    </>
                )}
            </div>
        );
    };

    return (
        <div className={classes.Sidebar}>
            <div className={classes.Container} onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}>
                <div className={classes.Content}>
                    <div onMouseMove={stopPropagation} className={classes.LogoContainer}>
                        {mouseHover ? <> {renderLogo()}</> : <div className={classes.Title}>TG</div>}
                    </div>
                    <div className={mouseHover ? classes.RoutesContainerHovered : classes.RoutesContainer}>
                        <div className={classes.AllRoutes}>
                            {processedFilterRoutes.map((route, key) => (
                                <>
                                    {route.childRoutes ? (
                                        <ControlledMenuForRoutes route={route} active={active} mouseHover={mouseHover} key={key} setActive={setActive} />
                                    ) : (
                                        <RenderableButton route={route} active={active} mouseHover={mouseHover} handleClick={handleClick} key={key} />
                                    )}
                                </>
                            ))}
                        </div>
                        <div className={classes.AllRoutes}>
                            {BottomRoutes.map((route, key) => (
                                <RenderableButton route={route} active={active} mouseHover={mouseHover} handleClick={route.handleClick} key={key} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;

const RenderableButton = ({ route, active, mouseHover, handleClick, key, reference, ...props }) => {
    const ref = useRef();
    return (
        <div key={key} onClick={() => handleClick && handleClick(route)} ref={mouseHover ? reference : ref} {...props} className={classes.RouteParent}>
            <div className={mouseHover ? (active?.includes(route.name) ? classes.RouteWrapperActive : classes.RouteWrapperHovered) : classes.RouteWrapper}>
                {mouseHover && <div></div>}
                <div className={classes.RouteImageWrapper}>
                    <img src={route.icon} alt={route.name} className={classes.RouteIcon} />
                </div>
                {mouseHover && <div>{route.name}</div>}
            </div>
            {route.childRoutes && mouseHover && <UilAngleRight className={classes.ArrowIcon} />}
        </div>
    );
};

const ControlledMenuForRoutes = ({ route, active, mouseHover, key, setActive }) => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const anchorProps = useClick(isOpen, setOpen);
    const history = useHistory();
    const { state } = useContext(AuthContext);
    const enabledLicense = lodashGet(state, 'user.customerId.enabledLicense');

    useEffect(() => {
        if (!mouseHover) setOpen(false);
    }, [mouseHover]);

    const handleClick = (subRoute) => {
        setActive(`${route.name} > ${subRoute.name}`);
        history.push(route.url + subRoute.url);
        saveRecentApps({ ...subRoute, url: `${route.url}${subRoute.url}`, childRouteName: `${route.name} > ${subRoute.name}` }, state);
    };

    return (
        <>
            <RenderableButton key={key} route={route} active={active} mouseHover={mouseHover} reference={ref} {...anchorProps} />
            <ControlledMenu
                state={isOpen ? 'open' : 'closed'}
                anchorRef={ref}
                onClose={() => setOpen(false)}
                direction="right"
                arrow="true"
                position="auto"
                offsetX={15}
                menuClassName={classes.MenuWrapper}
                arrowClassName={classes.MenuWrapperArrow}
            >
                {route.childRoutes.map((subRoute) => {
                    if (['supportNotes', 'versionHistory'].includes(subRoute.id)) return;
                    if (route.id === 'insights' && subRoute.id === 'dataHub' && !enabledLicense?.['645af12476b7895730655ba7']?.enabled) return;
                    if (route.id === 'e3' && !state?.user?.customerId?.license[subRoute.id]?.enabled) return;
                    if (subRoute.id === 'mobilepay' && !state?.user?.customerId?.license['CFM']?.mobilepay) return;
                    if (route.id === 'insights' && subRoute.id === 'loadShifting' && !state?.user?.customerId?.license[subRoute.id]?.enabled) return;
                    if (subRoute && route.id === 'demandResponse') {
                        if (['FCR', 'FFR', 'FCR-D-INC', 'FCR-D-DEC', 'FCR-N', 'aFRR'].includes(subRoute.id)) {
                            if (!state?.user?.customerId?.biddingMarkets?.includes(subRoute.id)) {
                                return;
                            }
                        }
                    }
                    return (
                        <MenuItem onClick={() => handleClick(subRoute)} className={classes.MenuItemClassName}>
                            <img src={subRoute.icon} className={classes.ChildRouteIcon} />
                            <div>{subRoute.name}</div>
                        </MenuItem>
                    );
                })}
            </ControlledMenu>
        </>
    );
};
