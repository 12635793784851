import React from 'react';

const GmOnline = () => {
    return (
        <div style={{ height: '74vw' }}>
            <iframe title="Grid Manager Online" src="https://energinet.dk/energisystem_fullscreen" style={{ border: 0, width: '100%', height: '100%' }}></iframe>
        </div>
    );
};

export default GmOnline;
