import React, { useEffect, useState, useContext } from 'react';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import classes from './ExportModal.module.css';
import Typography from '../../../components/Typography/Typography';
import MultiSelectDropdown from '../../../components/Inputs/MultiSelectDropdown';
import { Form, Formik } from 'formik';
import Dropdown from '../../../components/Inputs/Dropdown';
import DateRangePicker from '../../../components/Inputs/DateRangePicker/DateRangePicker';
import { DataHubService } from '../../../services/DataHubService';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import { LoaderContext } from '../../../context/LoaderContext';
import moment from 'moment/moment';
import { DownloadAsExcelMultipleSheet } from '../../../utils/downloadAsExcel';

const ExportModal = ({ isOpen, setOpen, groups }) => {
    const [activeDeviceOptions, setActiveDeviceOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
    });

    const initialValues = {
        group: '',
        device: '',
        date: '',
    };
    useEffect(() => {
        let temp = [];
        groups.length &&
            groups.map((item) => {
                temp.push({
                    label: item.name,
                    value: item._id,
                });
            });
        setGroupOptions(temp);
        setSelectedGroup(temp[0]);
    }, [groups]);

    useEffect(() => {
        if (selectedGroup?.value) {
            let temp = [];
            const filteredGroup = groups.filter((item) => item._id === selectedGroup.value);
            filteredGroup?.length &&
                filteredGroup[0]?.devices?.map((item) => {
                    if (item.enabled) {
                        temp.push({
                            label: item.name,
                            value: item.id,
                            priceSignal: item.priceSignal,
                            taxValue: item.taxValue,
                        });
                    }
                });
            setActiveDeviceOptions(temp);
        } else {
            setActiveDeviceOptions([]);
        }
    }, [selectedGroup]);

    const handleSubmit = (values) => {
        const params = {
            devices: selectedDevices,
            startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
            endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
        };
        if (selectedDevices.length > 0) {
            DataHubService.downloadData(
                params,
                () => startLoader('downloadData'),
                handleDownloadSuccess,
                handleError,
                () => stopLoader('downloadData')
            );
        }
    };

    const handleDownloadSuccess = ({ data }) => {
        let finalData = [];
        let deviceName = [];
        data?.data?.map((item) => {
            let temp = [];
            deviceName.push(item.deviceName);
            item?.costData?.[0]?.data.map((value, index) => {
                temp.push({
                    date: value.x,
                    cost: value.y,
                    consumption: item?.consumptionData?.[0]?.data[index]?.y,
                });
            });
            finalData.push(temp);
        });

        DownloadAsExcelMultipleSheet(finalData, deviceName, `Datahub ${moment(dateRange.startDate).format('YYYY-MM-DD')} -  ${moment(dateRange.endDate).format('YYYY-MM-DD')}`, [
            'Date & Time (CET)',
            'Cost (DKK)',
            'Consumption (kWh)',
        ]);
        setOpen(false);
    };

    const handleError = (err) => console.log(err);

    return (
        <div>
            <ModalComponent isOpen={isOpen} setOpen={setOpen} style={{ overflow: 'initial' }}>
                <div>
                    <Typography content={'Download Excel file'} />

                    <Formik enableReinitialize initialValues={initialValues} onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div className={classes.InputContainer} style={{ display: 'flex', gap: '0.5vw', alignItems: 'center' }}>
                                        <div className={classes.FieldControl}>
                                            <label>Select Group</label>
                                            <Dropdown name="group" options={groupOptions} defaultValue={groupOptions[0]} onChange={setSelectedGroup} />
                                        </div>
                                        <div className={classes.FieldControl} style={{ marginTop: '8px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <label>Start Date</label>
                                                <label style={{ marginRight: '2.604vw' }}>End Date</label>
                                            </div>
                                            <DateRangePicker
                                                startDate={dateRange.startDate}
                                                endDate={dateRange.endDate}
                                                onChange={(date) => setDateRange({ startDate: new Date(date.startDate), endDate: new Date(date.endDate) })}
                                                style={{ height: '2.5vw', width: '100%' }}
                                            />
                                        </div>
                                    </div>

                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl} style={{ width: '34vw' }}>
                                            <label>Meter Name</label>
                                            <MultiSelectDropdown
                                                name="device"
                                                options={activeDeviceOptions}
                                                defaultValue={activeDeviceOptions}
                                                label="Meter Name"
                                                onChange={setSelectedDevices}
                                            />
                                        </div>
                                    </div>

                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <div className="btn-secondary" onClick={() => setOpen(false)}>
                                                Cancel
                                            </div>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>
        </div>
    );
};

export default ExportModal;
