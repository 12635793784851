import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const PowerTaxValidation = yup.object().shape({
    DK1: yup.number().typeError(ERRORS.number).min(0).required(),
    DK2: yup.number().typeError(ERRORS.number).min(0).required(),
    'DK-GAS': yup.number().typeError(ERRORS.number).min(0).required(),
});

export const signalTaxValidations = (signals) => {
    const taxObj = {};
    signals.forEach((signal) => (taxObj[signal._id] = yup.number().typeError(ERRORS.number).min(0).required(ERRORS.required)));
    return yup.object().shape({ ...taxObj });
};
