import { HttpClient } from '../utils/httpClient';

const PATH = {
    updateInfo: '/client/customer/update-details',
    systemStatus: '/client/customer/system-status',
};

const updateProfileInfo = (values, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.updateInfo}`, values).then(callback).catch(error).finally(next);
};

const getSystemStatus = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.systemStatus}`).then(callback).catch(error).finally(next);
};

export const CustomerServices = {
    updateProfileInfo,
    getSystemStatus,
};
