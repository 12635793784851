import React from 'react';
import classes from '../../../styles/CapacityContent.module.css';
import Table from '../../../components/Table/Table';
import TabsComponent from '../../../components/Tabs/Tabs';
import { momentTimeFormater } from '../../../utils/dateHelper';

const getCapacityPriceData = (request = {}) => {
    const { capacity = [], price = [] } = request;
    const data = capacity.map((n, i) => {
        const slot = i + 1;
        return {
            slot,
            hour: `${i}-${slot}`,
            price: price[i],
            capacity: n,
        };
    });
    return data;
};

const getExcludedDaysdata = (request = {}) => {
    const { excludedDays = [] } = request;
    return excludedDays.map((e, i) => ({ ...e, 's.no': i + 1, date: momentTimeFormater(e.date).format('YYYY-MM-DD') }));
};

const RequestOverview = ({ request, setOpen }) => {
    const capacityPriceData = getCapacityPriceData(request);
    const excludedDaysdata = getExcludedDaysdata(request);
    const tabs = [
        {
            name: 'Capacity/Price',
            component: (
                <div style={{ maxHeight: '50vh', overflowY: 'scroll', padding: '0.5vw' }}>
                    <Table head={['Slot', 'Hour', 'Capacity', 'Price']} keys={['slot', 'hour', 'capacity', 'price']} data={capacityPriceData} />
                </div>
            ),
        },
        {
            name: 'Excluded Days',
            component: <Table head={['S.No', 'Date', 'Reason']} keys={['s.no', 'date', 'reason']} data={excludedDaysdata} />,
        },
    ];
    return (
        <div>
            <TabsComponent tabs={tabs} />
            <div className={classes.ButtonContainer2}>
                <div className="btn-secondary" onClick={() => setOpen(false)}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default RequestOverview;
