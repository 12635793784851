// Standard library imports
import React, { useState, useEffect } from 'react';

// Css module imports
import classes from '../../containers/MainContainer/MainContainer.module.css';

const Clock = () => {
    const [cetTime, setCETTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            const cetTime = new Date(now.toLocaleString('en-us', { timeZone: 'Europe/Berlin' }));
            setCETTime(cetTime);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const hours = cetTime.getHours().toString().padStart(2, '0');
    const minutes = cetTime.getMinutes().toString().padStart(2, '0');
    const seconds = cetTime.getSeconds().toString().padStart(2, '0');
    const day = cetTime.getDate();
    const month = cetTime.toLocaleString('default', { month: 'short' });

    return (
        <div>
            <div className={classes.ProfilePreview}>
                <div className={classes.ClockWrapper}>
                    <div className={classes.ClockBlocks}>
                        <div>
                            {day} {month}
                        </div>
                        <div className={classes.ClockDescription}>CET</div>
                    </div>
                    <div className={classes.TimeWrapper}>
                        <div className={classes.ClockBlocks}>
                            <div>{hours}</div>
                            <div className={classes.ClockDescription}>Hr</div>
                        </div>
                        <div>:</div>
                        <div className={classes.ClockBlocks}>
                            <div>{minutes}</div>
                            <div className={classes.ClockDescription}>Min</div>
                        </div>
                        <div>:</div>
                        <div className={classes.ClockBlocks}>
                            <div>{seconds}</div>
                            <div className={classes.ClockDescription}>Sec</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Clock;
