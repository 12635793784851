// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import momentTz from 'moment-timezone';

// Internal module imports
import ChartComponent from '../../../components/ApexCharts/Chart';
import { momentTimeFormater, timeOnly } from '../../../utils/dateHelper';
import { useLoader } from '../../../hooks/use-loader.hook';
import { InstanceDataLogsService } from '../../../services/InstanceDataLogsService';
import { Capitalize } from '../../../utils/stringHelper';

// Css imports
import classes from '../Home.module.css';

const InstanceCard = ({ groupId, licenseName }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [group, setGroup] = useState({});
    const [lastReportedTimestamp, setLastReportedTimeStamp] = useState();
    const [lastStatus, setLastStatus] = useState('--');

    useEffect(() => {
        lastReportedTimestamp && getData(lastReportedTimestamp);
    }, [lastReportedTimestamp]);

    useEffect(() => {
        getLastreported();
    }, []);

    const getData = () => {
        let filteredParams = {
            groupId: groupId,
            date: momentTz().tz('Europe/Berlin').format('YYYY-MM-DD'),
        };
        InstanceDataLogsService.Overview(
            filteredParams,
            () => startLoader('getInstanceOverview'),
            handleSuccess,
            handleError,
            () => stopLoader('getInstanceOverview')
        );
    };

    const handleSuccess = ({ data }) => {
        setData(data.data.powerData);
        setGroup(data.data.group);
    };

    const getLastreported = () => {
        if (groupId) {
            InstanceDataLogsService.LastReported(
                { groupId: groupId },
                () => startLoader('getOverviewGraphLastReported'),
                handleGetLastReportedSuccess,
                handleError,
                () => stopLoader('getOverviewGraphLastReported')
            );
        }
    };

    const handleGetLastReportedSuccess = ({ data }) => {
        const lastReported = data?.data?.lastReported;
        if (lastReported) {
            setLastStatus(momentTimeFormater(lastReported).format('YYYY-MM-DD'));
            setLastReportedTimeStamp(lastReported);
        }
    };

    const handleError = (error) => {
        console.error(error);
    };

    return (
        <div className={classes.TopGraph} style={{ flexGrow: 2 }}>
            <div className={classes.FilterDropdown + ' filter-dropdown'}>
                <div className={classes.ChartGroupName}>{group?.name || ''}</div>
            </div>
            <ChartComponent
                title={licenseName ? `${Capitalize(licenseName)} (kW)` : ''}
                strokeWidth={2}
                colors={['var(--color-primary)']}
                type="line"
                series={data}
                xFormatter={timeOnly}
                lastReported={lastStatus}
            />
        </div>
    );
};

export default InstanceCard;
