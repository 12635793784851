import React from 'react';
import { Form, Formik } from 'formik';
import classes from '../Nordpool.module.css';
import { Input } from '../../../components/Inputs/Input';
import { signalTaxValidations } from '../../../validations/Nordpool/PowerTaxValidation';

const getInitialValues = (signals, taxes) => {
    const vals = {};
    signals.forEach((signal) => {
        if (taxes[signal._id]) vals[signal._id] = taxes[signal._id];
        else vals[signal._id] = 0;
    });
    return vals;
};

const UpdateTax = ({ signals = [], taxes = {}, setOpen, handleSubmit }) => {
    const initialValues = getInitialValues(signals, taxes);

    return (
        <div>
            <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={() => signalTaxValidations(signals)}>
                {({ errors, touched, values, isValidating, submitCount, ...props }) => (
                    <Form>
                        <div>
                            {signals.map((signal) => (
                                <div className={classes.FieldControl}>
                                    <label for={signal._id}>{signal.name}</label>
                                    <Input name={signal._id} id={signal._id} />
                                </div>
                            ))}
                        </div>
                        <div className={classes.ButtonContainer}>
                            <div>
                                <div className="btn-secondary" onClick={() => setOpen(false)}>
                                    Cancel
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UpdateTax;
