import classes from './SupportNotes.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { SupportNotesService } from '../../services/SupportNotesService';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';
import { toast } from 'react-toastify';
import SupportNoteForm from './SupportNoteForm';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import addNoteIcon from '../../assets/addNote.svg';
import editIcon from '../../assets/edit.png';

const VersionHistory = () => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [open, setOpen] = useState(false);
    const [versions, setVersions] = useState([]);
    const [editData, setEditData] = useState({});

    useEffect(() => {
        fetchNotes();
    }, []);
    const fetchNotes = () => {
        SupportNotesService.getSupportNotes(
            () => startLoader('getVersions'),
            handleSuccess,
            handleError,
            () => stopLoader('getVersions')
        );
    };
    const handleSuccess = ({ data }) => {
        setVersions(data.data);
        console.log(data.data);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.CardsContainer}>
            <ModalComponent isOpen={open} setOpen={setOpen}>
                <SupportNoteForm fetchNotes={fetchNotes} setEditData={setEditData} setOpen={setOpen} data={editData} startLoader={startLoader} stopLoader={stopLoader} />
            </ModalComponent>
            <div
                className={classes.Card2}
                onClick={() => {
                    setOpen(true);
                }}
            >
                <div className={classes.Title}> {'Add Note'}</div>
                <div>
                    <img src={addNoteIcon} alt="addNoteIcon" />
                </div>
            </div>
            <br />
            {versions.map((version) => (
                <div className={classes.Card}>
                    <div>
                        <div className={classes.TitleWrapper}>
                            <div className={classes.Title}> {version.title}</div>
                            <div>
                                <img
                                    src={editIcon}
                                    alt="editIcon"
                                    onClick={() => {
                                        setEditData(version);
                                        setOpen(true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={classes.SubTitle}>{new Date(version.released).toISOString().split('T')[0]}</div>
                    </div>
                    <div className={classes.Content}>
                        <ul>
                            <li>{version.description}</li>
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default VersionHistory;
