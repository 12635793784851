// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import Editor from '@monaco-editor/react';
import { useField } from 'formik';

const NewJSONEditor = ({ defaultValue = {}, height = 'auto', width = 'auto', manualDefaultValueUpdate = false, setManualDefaultValueUpdate = () => {}, ...props }) => {
    const [, meta, helpers] = useField(props);

    const [editorRef, setEditorRef] = useState(null);

    const changeHandler = (e) => {
        if (JSON.parse(e)) {
            helpers.setValue && helpers.setValue(JSON.parse(e));
            props.onChange && props.onChange(JSON.parse(e));
        }
    };

    useEffect(() => {
        if (editorRef && manualDefaultValueUpdate) {
            formatJSON(editorRef, defaultValue);
        }
        setManualDefaultValueUpdate(false);
    }, [JSON.stringify(defaultValue), editorRef]);

    const formatJSON = (editor, defaultVal = null) => {
        const unformattedCode = defaultVal ? JSON.stringify(defaultVal) : editor.getValue();
        const res = JSON.parse(unformattedCode);
        editor.setValue(JSON.stringify(res, null, 2));
        return JSON.stringify(res, null, 2);
    };

    const handleEditorDidMount = (editor, monaco) => {
        setEditorRef(editor);
    };

    const formatDefaultJSON = (val = {}) => {
        try {
            const res = JSON.parse(val);
            return JSON.stringify(res, null, 2);
        } catch {
            return JSON.stringify(val, null, 2);
        }
    };

    return (
        <div>
            <Editor height={height} defaultLanguage="json" theme="vs-dark" onChange={changeHandler} defaultValue={formatDefaultJSON(defaultValue)} onMount={handleEditorDidMount} />
            {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
        </div>
    );
};

export default NewJSONEditor;
