import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { HOME_ROUTES } from '../../../constants';
import classes from '../Home.module.css';
import { Capitalize } from '../../../utils/stringHelper';
import TableWithHeading from '../../../components/TableWithHeading/TableWithHeading';
import { DashboardService } from '../../../services/DashboardService';
import { FrequencyService } from '../../../services/FrequencyService';
import TabsComponent from '../../../components/Tabs/Tabs';

const EventFaultCard = () => {
    const history = useHistory();
    const [eventsData, setEventsData] = useState([]);
    const [eventsLoading, setEventsLoading] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const today = moment().format('YYYY-MM-DD');
        DashboardService.getEventsData(
            { startDate: today, endDate: today },
            () => setEventsLoading(true),
            ({ data }) => getEventsDataSuccess(data),
            handleError,
            () => setEventsLoading(false)
        );
    };

    const getEventsDataSuccess = (data) => {
        const eventsData = data.data.map((event) => {
            return {
                ...event,
                deltaLoad: event.deltaLoad.toLocaleString('da-DK'),
                direction: Capitalize(event.direction),
                processedDeltaLoad:
                    event.direction === 'increased' ? (
                        <div style={{ color: 'darkgreen' }}>{event.deltaLoad.toLocaleString('da-DK')}</div>
                    ) : (
                        <div style={{ color: 'red' }}> {event.deltaLoad.toLocaleString('da-DK')}</div>
                    ),
            };
        });
        setEventsData(eventsData);
    };

    const handleError = (error) => {
        console.error(error);
    };

    return (
        <div className={classes.EventContainer}>
            <TableWithHeading
                heading="Events"
                head={['Type', 'Instance', 'Direction', 'Start Time (CET)', 'Delta Load (%)', 'Duration', 'Cost']}
                data={eventsData}
                keys={['type', 'instanceName', 'direction', 'startTime', 'processedDeltaLoad', 'duration', 'cost']}
                count={eventsData.length}
                loading={eventsLoading}
            />
        </div>
    );
};

export default EventFaultCard;
