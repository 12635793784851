import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import Typography from '../../../components/Typography/Typography';
import { useLoader } from '../../../hooks/index';
import { NordpoolService } from '../../../services/NordpoolService';
import classes from '../Nordpool.module.css';
import Button from '../../../components/Buttons/Button';
import DownloadIcon from '../../../assets/download.svg';
import { DropdownComponent } from '../../../components/Inputs/Input';
function DownloadFilterModal({ open, setOpen, PriceDropdown }) {
    const [startLoader, stopLoader] = useLoader();

    let lastDay = moment().endOf('M');
    const [dateRange, setDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(lastDay),
    });
    const [priceSignal, setPriceSignal] = useState('');
    useEffect(() => {
        PriceDropdown && setPriceSignal(PriceDropdown[0]);
    }, [PriceDropdown]);

    const fetchData = () => {
        let processedDate = {
            startDate: moment(dateRange.startDate).startOf('M').tz('Europe/Berlin').format('YYYY-MM-DD'),
            endDate: moment(dateRange.startDate).endOf('M').tz('Europe/Berlin').format('YYYY-MM-DD'),
            priceSignal: priceSignal.value,
        };
        const loaderKey = 'excelPrice';
        NordpoolService.getPowerCostExcelData(
            processedDate,
            () => startLoader(loaderKey),
            ({ data }) => {
                if (data?.data.length > 0) {
                    DownloadAsExcel(
                        data?.data,
                        ` ${priceSignal?.value} ${moment(dateRange.startDate).startOf('M').format('YYYY-MM-DD')}-${moment(dateRange.startDate).endOf('M').format('YYYY-MM-DD')}`,
                        Object.keys(data?.data[0]).map((item) => String(item).toUpperCase())
                    );
                    setOpen(false);
                }
            },
            (err) => {
                console.log(err);
            },
            () => stopLoader(loaderKey)
        );
    };
    const minStartDate = new Date();
    minStartDate.setDate(dateRange.endDate.getDate() - 29);

    const maxEndDate = new Date();
    maxEndDate.setDate(dateRange.startDate.getDate());
    return (
        <ModalComponent isOpen={open} setOpen={setOpen}>
            <div className={classes.DownloadFilterModal}>
                <Typography content={'Download Price'} />
                <div className={classes.DatePickers}>
                    <div>
                        <div className={classes.DateLabels}>Price Signal</div>
                        <div>
                            <DropdownComponent
                                defaultValue={priceSignal}
                                options={PriceDropdown}
                                onChange={(value) => {
                                    setPriceSignal(value);
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className={classes.DateLabels}>Month</div>
                        <input
                            type={'month'}
                            max={maxEndDate.toISOString().substring(0, 7)}
                            value={dateRange.startDate.toISOString().substring(0, 7)}
                            onChange={(e) => {
                                console.log(e.target.value);
                                setDateRange({ ...dateRange, startDate: new Date(e.target.value) });
                            }}
                        />
                    </div>
                    {/* <div style={{ marginBottom: '0.15vw' }}>To</div>
          <div>
            <div className={classes.DateLabels}>End Date</div>
            <input
              max={maxEndDate.toISOString().substring(0, 7)}
              type={'month'}
              value={dateRange.endDate.toISOString().substring(0, 7)}
              onChange={e => setDateRange({ ...dateRange, endDate: new Date(e.target.value) })}
            />
          </div> */}

                    <div>
                        {' '}
                        <Button
                            size="medium"
                            icon={DownloadIcon}
                            text="Download"
                            leftIcon={true}
                            onClick={() => {
                                fetchData();
                            }}
                        />
                    </div>
                </div>
            </div>
        </ModalComponent>
    );
}

export default DownloadFilterModal;
