import { HttpClient } from '../utils/httpClient';

const PATH = {
    notifications: '/client/alert/notification',
};

const GetNotifications = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.notifications}`, { params }).then(callback).catch(error).finally(next);
};

export const AlertService = {
    GetNotifications,
};
