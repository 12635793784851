import { HttpClient } from '../utils/httpClient';

const PATH = {
    getAllSupportNotes: '/client/supportNotes',
};

const getSupportNotes = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getAllSupportNotes}`).then(callback).catch(error).finally(next);
};
const createSupportNotes = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.getAllSupportNotes}`, payload).then(callback).catch(error).finally(next);
};
const updateSupportNotes = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.getAllSupportNotes}/${id}`, payload).then(callback).catch(error).finally(next);
};

export const SupportNotesService = {
    getSupportNotes,
    createSupportNotes,
    updateSupportNotes,
};
