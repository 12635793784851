import { HttpClient } from '../utils/httpClient';

const PATH = {
    getData: '/client/data/event/dashboard',
    getErrors: '/client/data/error',
    getActivationData: '/client/data/event/activation',
};

const getEventsData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getData}`, { params }).then(callback).catch(error).finally(next);
};

const getAppEventsData = (type, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getData}/${type}`, { params }).then(callback).catch(error).finally(next);
};

const getAppErrorData = (type, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getErrors}/${type}`, { params }).then(callback).catch(error).finally(next);
};

const getAppActivationData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getActivationData, { params }).then(callback).catch(error).finally(next);
};

export const DashboardService = {
    getEventsData,
    getAppEventsData,
    getAppErrorData,
    getAppActivationData,
};
