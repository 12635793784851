import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/client/signalTypes',
};

const readAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.readAll}`).then(callback).catch(error).finally(next);
};

export const SignalTypesService = {
    readAll,
};
