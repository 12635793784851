// Standard library imports
import React, { useEffect, useState } from 'react';

// Internal module imports
import { useLoader } from '../../hooks/use-loader.hook';
import { LicenseService } from '../../services/LicenseService';
import MeterCard from './GraphComponents/MeterCard';
import InstanceCard from './GraphComponents/InstanceCard';

const LicenseOverview = ({ groupId, license }) => {
    const [startLoader, stopLoader] = useLoader();
    const [licenseName, setLicenseName] = useState();
    const [dataHubConfig, setDataHubConfig] = useState(false);

    useEffect(() => {
        getLicense();
    }, []);

    const getLicense = () => {
        if (license) {
            LicenseService.getLicenceById(
                license,
                () => startLoader('getLicense'),
                handleGetLicenseSuccess,
                handleError,
                () => stopLoader('getLicense')
            );
        }
    };

    const handleGetLicenseSuccess = ({ data }) => {
        if (data?.data?.name === 'dataHub') {
            setDataHubConfig(true);
        }
        setLicenseName(data?.data?.name || '');
    };

    const handleError = (error) => {
        console.error(error);
    };

    return <>{dataHubConfig ? <MeterCard groupId={groupId} licenseName={licenseName} /> : <InstanceCard groupId={groupId} licenseName={licenseName} />}</>;
};

export default LicenseOverview;
