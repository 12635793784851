// Standard library imports
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// External library imports
import moment from 'moment-timezone';
import { get as LodashGet } from 'lodash';

// Internal module imports
import classes from '../Economics.module.css';
import { LoaderContext } from '../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER, HOME_ROUTES } from '../../../constants';
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { CamelCaseToTextCase, Capitalize } from '../../../utils/stringHelper';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { AuthContext } from '../../../context/AuthContext';
import { DataServices } from '../../../services/DataServices';
import HourlyCost from '../HourlyCost';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import DateRangePicker from '../../../components/Inputs/DateRangePicker/DateRangePicker';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import { DropdownComponent } from '../../../components/Inputs/Input';

const LIMIT = 15;

const Details = ({ updateQueryParams, queryParamsData = {} }) => {
    const date = new Date();
    const { state } = useContext(AuthContext);
    const customerBiddingMarkets = LodashGet(state, 'user.customerId.biddingMarkets') || [];
    const cutomerName = LodashGet(state, 'user.customerId.name') || '';
    const isFcr = customerBiddingMarkets.includes('FCR');
    const isFfr = customerBiddingMarkets.includes('FFR');
    const isFcrD = customerBiddingMarkets.includes('FCR-D-INC');
    const isFcrDMinus = customerBiddingMarkets.includes('FCR-D-DEC');
    const isDcPlanner = customerBiddingMarkets.includes('DC-PLANNER');
    const history = useHistory();
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [dateRange, setDateRange] = useState({
        startDate: queryParamsData.dateRange ? new Date(queryParamsData.dateRange.startDate) : new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: queryParamsData.dateRange ? new Date(queryParamsData.dateRange.endDate) : new Date(date.getFullYear(), date.getMonth() + 1, 0),
    });
    const [tableData, setTableData] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [TotalRevenue, setTotalRevenue] = useState({
        customer: 0,
        enablers: 0,
        sc: 0,
        partner: 0,
    });
    const [Revenue, setRevenue] = useState(0);
    const [hourlyModal, setHourlyModal] = useState(false);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const defaultValue = { label: 'All', value: 'All' };
    const [licenseTypes, setLicenseTypes] = useState([defaultValue]);
    const [licenseType, setLicenseType] = useState(defaultValue);
    const [instances, setInstances] = useState([defaultValue]);
    const [instance, setInstance] = useState(defaultValue);

    useEffect(() => {
        fetchTableData();
    }, [skip, instance]);

    useEffect(() => {
        fetchLicenseTypes();
    }, []);

    useEffect(() => {
        fetchInstance();
    }, [licenseType]);

    const fetchInstance = () => {
        if (licenseType.value !== 'All') {
            DataServices.getInstance(
                { licenseId: licenseType.value },
                () => {
                    startLoader('economicsInstance');
                },
                handleInstanceSuccess,
                handleError,
                () => {
                    stopLoader('economicsInstance');
                }
            );
        } else {
            setInstances([defaultValue]);
            setInstance(defaultValue);
        }
    };

    const fetchLicenseTypes = () => {
        DataServices.getLicenseTypes(
            () => {
                startLoader('economicsLicenseTypes');
            },
            handleLicenseTypesSuccess,
            handleError,
            () => {
                stopLoader('economicsLicenseTypes');
            }
        );
    };

    const handleInstanceSuccess = ({ data }) => {
        const processedData = [defaultValue];

        data?.data.forEach((type) => {
            processedData.push({ label: type.name, value: type._id });
        });
        setInstances(processedData);
        setInstance(defaultValue);
    };

    const handleLicenseTypesSuccess = ({ data }) => {
        const processedData = [defaultValue];

        data?.data.forEach((type) => {
            processedData.push({ label: CamelCaseToTextCase(type.name), value: type._id });
        });
        setLicenseTypes(processedData);
    };

    const fetchTableData = (skipNone = false) => {
        const processedFilter = {
            startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
            endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
            fcr: isFcr,
            ffr: isFfr,
            fcrD: isFcrD,
            fcrDMinus: isFcrDMinus,
            dcPlanner: isDcPlanner,
            limit: LIMIT,
            skip: skipNone ? 0 : skip,
            licenseId: licenseType.value,
            instanceId: instance.value,
        };

        DataServices.getEconomicData(
            processedFilter,
            () => {
                startLoader('economics');
            },
            handleEconomicDataSuccess,
            (err) => {
                console.log(err);
            },
            () => {
                stopLoader('economics');
            }
        );
    };
    const getEnablerRevenue = (enablers = {}, enablersData = []) => {
        let totalRevenue = 0;
        let tooltip = [];
        enablersData.map((e) => {
            for (const key in enablers) {
                if (key == e._id) {
                    totalRevenue = totalRevenue + Number(enablers[key]);
                    tooltip.push(`${e.name} : ${enablers[key].toLocaleString('da-DK')}`);
                }
            }
        });

        return (
            <CustomTooltip
                content={tooltip.map((e) => (
                    <div>{e}</div>
                ))}
            >
                <div className={classes.LinkTitle}>{totalRevenue.toLocaleString('da-DK')}</div>
            </CustomTooltip>
        );
    };
    const handleEconomicDataSuccess = ({ data }) => {
        const newData = [...data.data.exportData];
        const enablersData = data.data?.enablersData;
        setExcelData(newData);
        const processedData = data.data.data.map((item) => {
            return {
                ...item,
                date: moment(item.date).format('DD-MM-YYYY'),
                capacity: parseFloat(item.capacity).toLocaleString('da-DK'),
                customer: parseFloat(item.customer).toLocaleString('da-DK'),
                enablersAmount: getEnablerRevenue(item?.enablers, enablersData),
                sc: parseFloat(item.sc).toLocaleString('da-DK'),
                partner: parseFloat(item.partner).toLocaleString('da-DK'),
                productType: item?.productType || '--',
                source: (
                    <div className={classes.LinkTitle} onClick={() => history.push(HOME_ROUTES['frequency'])}>
                        {Capitalize(item.source)}
                    </div>
                ),
                action: (
                    <div
                        // className={classes.Action}
                        onClick={() => {
                            setHourlyModal(item.bidStatus);
                            setRevenue(item.revenue);
                        }}
                    >
                        {/* <div>
                        <img src={HourlyCostIcon} alt='' />
                    </div> */}
                        <div>
                            <a href="#" style={{ color: '#818e94' }}>
                                Details
                            </a>
                        </div>
                    </div>
                ),
            };
        });
        setTableData(processedData);
        setTotalRevenue({
            customer: Math.round(data.data.totalRevenue).toLocaleString('da'),
            enablers: Math.round(data.data.totalRevenueEnabler).toLocaleString('da'),
            sc: Math.round(data.data.totalRevenueSc).toLocaleString('da'),
            partner: Math.round(data.data.totalRevenuePartner).toLocaleString('da'),
        });
        setTotalCount(data.data.count);
    };

    const exportEnablerRevenue = (enablers) => {
        let totalRevenue = 0;
        for (const key in enablers) {
            totalRevenue = totalRevenue + Number(enablers[key]);
        }
        return totalRevenue;
    };

    const handleDownloadExcel = () => {
        let processExcelData = excelData.map((item) => ({
            date: moment(item.date).format('DD.MM.YYYY'),
            slot: item.timeSlot || '--',
            hour: item.hour,
            source: item.source,
            group: item.instanceIdName,
            productName: item.productName,
            productType: item?.productType || '--',
            type: item.type,
            capacity: Number(parseFloat(item.capacity).toFixed(6)),
            customer: Number(parseFloat(item.customer).toFixed(6)),
            partner: Number(parseFloat(item.partner).toFixed(6)),
            enabler: Number(parseFloat(exportEnablerRevenue(item.enablers)).toFixed(6)),
            sc: Number(parseFloat(item.sc).toFixed(6)),
        }));
        processExcelData.push({
            // date:null,
            productName: 'Total',
            customer: TotalRevenue.customer,
            sc: TotalRevenue.sc,
            enabler: TotalRevenue.enablers,
            type: 'Euro',
            source: '',
        });
        processExcelData.length > 1 &&
            DownloadAsExcel(processExcelData, `Economics ${moment(dateRange.startDate).format('YYYY-MM-DD')}-${moment(dateRange.endDate).format('YYYY-MM-DD')}`, [
                'Date',
                'Slot',
                'Hour',
                'Source',
                'Group',
                'Product Name',
                'Product Type',
                'Type',
                'Capacity (MW)',
                'Customer (€)',
                'Partner (€)',
                'Enablers (€)',
                'SC (€)',
            ]);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <>
            <div className={classes.Details}>
                <div className={classes.Header + ' ' + classes.FilterBar}>
                    <div style={{ gap: '0.51vw' }}>
                        <div className={classes.FieldControl2}>
                            <label for="license">License</label>
                            <DropdownComponent defaultValue={defaultValue} options={licenseTypes} onChange={setLicenseType} />
                        </div>
                        <div className={classes.FieldControl2}>
                            <label for="instance">Instance</label>
                            <DropdownComponent defaultValue={defaultValue} options={instances} onChange={setInstance} />
                        </div>
                    </div>

                    <div className={classes.DatePickers}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className={classes.DateLabels}>Start Date</div>
                                <div className={classes.DateLabels} style={{ marginRight: '2.604vw' }}>
                                    End Date
                                </div>
                            </div>
                            <DateRangePicker
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                onChange={(date) => {
                                    setDateRange({ startDate: new Date(date.startDate), endDate: new Date(date.endDate) });
                                    updateQueryParams('dateRange', JSON.stringify(date));
                                }}
                            />
                        </div>
                        <div
                            className={classes.SubmitButton}
                            onClick={() => {
                                fetchTableData(true);
                                setPage(0);
                            }}
                        >
                            <button className="btn-primary">Submit</button>
                        </div>
                        <div>
                            <DownloadButton size="medium" onClick={handleDownloadExcel} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.Details}>
                <div className={classes.FilterContainer}>
                    <div className={classes.DropdownContainer}>
                        <div className={classes.TableCount}>
                            Total Revenue ({cutomerName}):
                            <span>
                                <Typography size="14" content={TotalRevenue.customer + ' €'} />
                            </span>
                        </div>
                        <div className={classes.TableCount}>
                            Total Revenue (Partner):
                            <span>
                                <Typography size="14" content={TotalRevenue.partner + ' €'} />
                            </span>
                        </div>
                        <div className={classes.TableCount}>
                            Total Revenue (Enablers):
                            <span>
                                <Typography size="14" content={TotalRevenue.enablers + ' €'} />
                            </span>
                        </div>
                        <div className={classes.TableCount}>
                            Total Revenue (SC):
                            <span>
                                <Typography size="14" content={TotalRevenue.sc + ' €'} />
                            </span>
                        </div>
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount || 0} />
                            </span>
                        </div>
                    </div>
                </div>
                <Table
                    head={[
                        'Date',
                        'Slot',
                        'Hour',
                        'Group',
                        'Product Name',
                        'Product Type',
                        'Capacity (MW)',
                        'Customer Revenue (€)',
                        'Partner Revenue (€)',
                        'Enablers Revenue (€)',
                        'SC Revenue (€)',
                        // 'Source',
                        'Type',
                    ]}
                    keys={[
                        'date',
                        'timeSlot',
                        'hour',
                        'instanceIdName',
                        'productName',
                        'productType',
                        'capacity',
                        'customer',
                        'partner',
                        'enablersAmount',
                        'sc',
                        // 'source',
                        'type',
                    ]}
                    data={[...tableData]}
                    page={page}
                    Pagination={true}
                    limit={LIMIT}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
                <ModalComponent isOpen={hourlyModal} setOpen={setHourlyModal}>
                    <HourlyCost revenue={Revenue} data={hourlyModal} setOpen={setHourlyModal} />
                </ModalComponent>
            </div>
        </>
    );
};

export default Details;
