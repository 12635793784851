import { HttpClient } from '../utils/httpClient';

const PATH = {
    overview: '/client/instance-data-logs/overview',
    lastReported: '/client/instance-data-logs/last-reported',
};

const Overview = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.overview}`, { params }).then(callback).catch(error).finally(next);
};

const LastReported = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.lastReported}`, { params }).then(callback).catch(error).finally(next);
};

export const InstanceDataLogsService = {
    Overview,
    LastReported,
};
