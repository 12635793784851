// Standard library imports
import React, { useContext, useState, useEffect } from 'react';

// Extenal library imports
import { get as LodashGet } from 'lodash';
import { UilSitemap } from '@iconscout/react-unicons'

// Internal module imports
import CollapsibleWithRename from '../../../components/CollapsibleWithRename/CollapsibleWithRenameForGroup';
import { AuthContext } from '../../../context/AuthContext';

// Css imports
import classes from '../../../styles/DeviceList.module.css';

const DeviceList = ({setCreateGroupModal, groups, setDevice, license }) => {
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    useEffect(() => {
        if (groups && groups.length > 0) {
            groups[0] && setDevice(groups[0]);
        }
    }, [license, groups]);
    const [collapsibleOpen, setCollapsibleOpen] = useState('Instances');

    return (
        <div className={classes.Details}>
            <div></div>
            <div className={classes.CollapsibleContainer}>
                {groups && (
                    <CollapsibleWithRename
                        open={collapsibleOpen}
                        setOpen={setCollapsibleOpen}
                        setCreateGroupModal={setCreateGroupModal}
                        group={groups}
                        setDevice={setDevice}
                        deviceIcon={<UilSitemap size="1.2vw" style={{ color: 'var(--color-primary)' }}/>}
                    />
                )}
            </div>
        </div>
    );
};

export default DeviceList;
