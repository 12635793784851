// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import moment from 'moment-timezone';

// Internal module imports
import ChartComponent from '../../../components/ApexCharts/Chart';
import { momentTimeFormater, timeOnly } from '../../../utils/dateHelper';
import { useLoader } from '../../../hooks/use-loader.hook';
import { Capitalize } from '../../../utils/stringHelper';
import { DataHubService } from '../../../services/DataHubService';
import { usToEuCurrencyFormat } from '../../../utils/currencyHelper';
import { truncateNumber } from '../../../utils/numberHelpers';

// Css imports
import classes from '../Home.module.css';

const MeterCard = ({ groupId, licenseName }) => {
    const [startLoader, stopLoader] = useLoader();
    const [dataHubLastReportedTimestamp, setDataHubLastReportedTimeStamp] = useState();
    const [lastStatus, setLastStatus] = useState('--');
    const [dataHubGraphData, setDataHubGraphData] = useState([]);
    const [dataHubMeterName, setdataHubMeterName] = useState('');

    useEffect(() => {
        dataHubLastReportedTimestamp && getData();
    }, [dataHubLastReportedTimestamp]);

    useEffect(() => {
        getLastreported();
    }, []);

    const handleReadSuccess = ({ data }) => {
        const processedData = data.data?.graph?.map((mydata) => {
            return {
                ...mydata,
                type: mydata.name === 'cost' ? 'line' : 'column',
            };
        });
        setDataHubGraphData(processedData);
        setdataHubMeterName(data?.data?.name);
    };

    const handleReadLastStatusSuccess = ({ data }) => {
        const lastReported = data?.data?.timestamp;
        if (lastReported) {
            setLastStatus(momentTimeFormater(lastReported).format('YYYY-MM-DD'));
            setDataHubLastReportedTimeStamp(lastReported);
        }
    };

    const getData = () => {
        const processedDate = momentTimeFormater(dataHubLastReportedTimestamp).format('YYYY-MM-DD');
        if (groupId)
            DataHubService.readGraphData(
                groupId,
                { date: processedDate },
                () => startLoader('readGraphData'),
                handleReadSuccess,
                handleError,
                () => stopLoader('readGraphData')
            );
    };

    const getLastreported = () => {
        if (groupId) {
            DataHubService.readLastStatus(
                groupId,
                () => startLoader('readLastData'),
                handleReadLastStatusSuccess,
                handleError,
                () => stopLoader('readLastData')
            );
        }
    };

    const handleError = (error) => {
        console.error(error);
    };

    return (
        <div className={classes.TopGraph} style={{ flexGrow: 2 }}>
            <div className={classes.FilterDropdown + ' filter-dropdown'}>
                <div className={classes.ChartGroupName}>{dataHubMeterName || ''}</div>
            </div>

            <ChartComponent
                title={licenseName ? `${Capitalize(licenseName)} (kW)` : ''}
                dataLabels={{
                    enabled: true,
                    enabledOnSeries: [0],
                    formatter: function (val, opts) {
                        return usToEuCurrencyFormat(truncateNumber(val, 2));
                    },
                }}
                type="line"
                series={dataHubGraphData}
                xFormatter={timeOnly}
                lastReported={lastStatus}
            />
        </div>
    );
};

export default MeterCard;
