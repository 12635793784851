// Standard library imports
import React, { useContext, useEffect, useState } from 'react';

// External library imports
import moment from 'moment';
import { toast } from 'react-toastify';
import { get as lodashGet } from 'lodash';
import { UilImport } from '@iconscout/react-unicons';

// Internal module imports
import ChartComponent from '../../../components/ApexCharts/Chart';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import { LoaderContext } from '../../../context/LoaderContext';
import { FrequencyService } from '../../../services/FrequencyService';
import { momentTimeFormater, timeOnly } from '../../../utils/dateHelper';
import { AuthContext } from '../../../context/AuthContext';
import { usToEuCurrencyFormat } from '../../../utils/currencyHelper';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import CustomTimePicker from '../../../components/Inputs/TimePicker/TimePicker';
import { DropdownComponent, ToggleButtonWithState } from '../../../components/Inputs/Input';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { truncateNumber } from '../../../utils/numberHelpers';

// Css imports
import classes from '../DemandResponse.module.css';

export const frequencyAnnotations = {
    yaxis: [
        {
            y: 49.98,
            borderColor: '#219e4d',
            label: {
                borderColor: '#219e4d',
                style: {
                    color: '#fff',
                    background: '#219e4d',
                    fontSize: '0.8vw',
                },
                text: '49.98',
            },
        },
        {
            y: 50.02,
            borderColor: '#775DD0',
            label: {
                borderColor: '#775DD0',
                style: {
                    color: '#fff',
                    background: '#775DD0',
                    fontSize: '0.8vw',
                },
                text: '50.02',
            },
        },
    ],
};

const getFrequencySignals = (customer) => {
    const { frequency = [] } = customer;
    const options = [];
    frequency.forEach((item) => options.push({ label: item.name, value: item._id }));
    return options;
};

const Live = ({ updateQueryParams, queryParamsData }) => {
    const { state } = useContext(AuthContext);
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [graphData, setGraphData] = useState([]);
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));
    const [endTime, setEndTime] = useState(queryParamsData.endTime ? moment(queryParamsData.endTime, 'HH:mm').format('HH:mm') : curCetDateTime.format('HH:mm'));
    const [startTime, setStartTime] = useState(
        queryParamsData.startTime ? moment(queryParamsData.startTime, 'HH:mm').format('HH:mm') : curCetDateTime.subtract(15, 'minutes').format('HH:mm')
    );
    const [frequencyOption] = useState(getFrequencySignals(state.user.customerId));
    const [frequency, setFrequency] = useState(queryParamsData.frequency ? queryParamsData.frequency : frequencyOption[0]);
    const [lastStatus, setLastStatus] = useState('--');
    const [isAutoRefresh, setIsAutorefresh] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [counterInterval, setCounterInterval] = useState(null);

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const frequencyGraphProperties = lodashGet(state, 'user.customerId.properties');

    frequencyAnnotations.yaxis[0].y = frequencyGraphProperties.lineMin;
    frequencyAnnotations.yaxis[0].label.text = String(frequencyGraphProperties.lineMin);

    frequencyAnnotations.yaxis[1].y = frequencyGraphProperties.lineMax;
    frequencyAnnotations.yaxis[1].label.text = String(frequencyGraphProperties.lineMax);

    useEffect(() => {
        getData();
        getlastreported();
    }, [frequency]);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);
    useEffect(() => {
        updateQueryParams('startTime', startTime);
    }, [startTime]);
    useEffect(() => {
        updateQueryParams('endTime', endTime);
    }, [endTime]);
    useEffect(() => {
        if (frequency) {
            updateQueryParams('frequency', JSON.stringify(frequency));
        }
    }, [frequency]);

    const getData = () => {
        if (frequency?.value) {
            const params = {
                date: momentTimeFormater(date).format('YYYY-MM-DD'),
                start: startTime,
                end: endTime,
                dataDecimation: true,
                prefillData: false,
            };
            FrequencyService.getFrequencyData(
                frequency.value,
                params,
                () => startLoader('getData'),
                handleGetDataSuccess,
                handleError,
                () => stopLoader('getData')
            );
        }
    };

    const getlastreported = () => {
        FrequencyService.frequencyLastReported(
            () => startLoader('getDatalast'),
            handleLastReportedSuccess,
            handleError,
            () => stopLoader('getDatalast')
        );
    };

    const handleLastReportedSuccess = ({ data }) => {
        const date = moment(data.data.frequency.timestamp).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm');
        setLastStatus(date);
    };
    const handleGetDataSuccess = (res) => {
        setGraphData(res.data.data.graph);
    };

    const handleDropdownChange = (val) => {
        setFrequency(val);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };

    const handleExport = () => {
        if (frequency?.value) {
            const params = {
                date: momentTimeFormater(date).format('YYYY-MM-DD'),
                start: startTime,
                end: endTime,
                dataDecimation: false,
                prefillData: true,
            };
            FrequencyService.getFrequencyData(
                frequency.value,
                params,
                () => startLoader('downloadData'),
                handleDownloadDataSuccess,
                handleError,
                () => stopLoader('downloadData')
            );
        }
    };

    const handleDownloadDataSuccess = (res) => {
        const processedData = res?.data?.data?.graph?.[0].data?.map((item) => {
            return {
                date: moment(item.x).format('YYYY-MM-DD'),
                time: moment(item.x).format('HH:mm:ss'),
                frequency: Number(truncateNumber(item.y, 3)),
                inject: item.inject,
            };
        });

        processedData?.length &&
            DownloadAsExcel(processedData, `Frequency ${frequency?.label} ${moment(date).format('DD-MM-YYYY')}`, ['Date', 'Time (CET)', 'Frequency (Hz)', 'Inject']);
    };

    const autoRefreshLastReportedData = () => {
        FrequencyService.autoRefeshFrequencyLastReported(handleLastReportedSuccess, handleError);
    };

    const autoRefeshData = () => {
        autoRefreshLastReportedData();
        setEndTime(moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm'));
        if (frequency?.value) {
            const params = {
                date: momentTimeFormater(date).format('YYYY-MM-DD'),
                start: startTime,
                end: moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm'),
                dataDecimation: true,
                prefillData: false,
            };
            FrequencyService.autoRefreshGetFrequencyData(frequency.value, params, handleGetDataSuccess, handleError);
        }
    };

    useEffect(() => {
        if (isAutoRefresh) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    autoRefeshData();
                    setSeconds(30);
                }
            }, 1000);
            setCounterInterval(interval);
            return () => clearInterval(interval);
        }
    }, [isAutoRefresh, seconds]);

    useEffect(() => {
        if (isAutoRefresh) {
            const currentTime = moment().tz('Europe/Berlin').format('HH:mm');
            const curCetDate = moment().tz('Europe/Berlin');
            setDate(new Date(curCetDate.format()));
            setEndTime(currentTime);
        }
    }, [isAutoRefresh]);

    return (
        <div>
            <div className={classes.FilterContainer}>
                <div className="filter-dropdown" style={{ margin: '0' }}>
                    Frequency: <DropdownComponent defaultValue={frequency} options={frequencyOption} onChange={handleDropdownChange} />
                </div>
                <div className={classes.FlexCenter}>
                    <div style={{ display: 'flex', gap: '0.521vw' }}>
                        <DatePicker date={date} setDate={setDate} />
                        <CustomTimePicker startTime={startTime} setStartTime={setStartTime} type="start" />
                        <CustomTimePicker endTime={endTime} setEndTime={setEndTime} type="end" disabled={isAutoRefresh} />
                    </div>
                    <button type="submit" className="btn-primary" style={{ marginTop: '0', width: 'auto', marginLeft: '0.5vw' }} onClick={getData}>
                        Submit
                    </button>
                    <UilImport size="1.8vw" style={{ color: 'var(--color-primary)', cursor: 'pointer', marginLeft: '0.5vw' }} onClick={handleExport} />
                </div>
            </div>
            <div className={classes.BottomContainer2}>
                <ChartComponent
                    yProps={{
                        min: frequencyGraphProperties.lowVisible,
                        max: frequencyGraphProperties.highVisible,
                        tickAmount: 4,
                    }}
                    annotations={frequencyAnnotations}
                    series={graphData}
                    title="Frequency (Hz)"
                    type="line"
                    xFormatter={timeOnly}
                    lastReported={lastStatus}
                    yFormatter={formatYAxisValue}
                    rightSideElement={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5vw',
                                marginRight: '1vw',
                            }}
                        >
                            <ToggleButtonWithState
                                warpperStyle={{ marginTop: '0' }}
                                value={isAutoRefresh}
                                onChange={(e) => {
                                    setIsAutorefresh(e.target.checked);
                                    clearInterval(counterInterval);
                                    setSeconds(30);
                                }}
                            />
                            <div
                                style={{
                                    fontSize: '0.72vw',
                                    fontWeight: '500',
                                }}
                            >
                                <label>Auto refresh</label> {isAutoRefresh && <label>in {seconds} seconds</label>}
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default Live;
