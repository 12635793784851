// Standard Library Imports
import React, { useState, useEffect } from 'react';

// External Library Imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// Internal Module Imports
import { Input } from '../../../components/Inputs/Input';
import { useLoader } from '../../../hooks/use-loader.hook';
import Dropdown from '../../../components/Inputs/Dropdown';
import { momentTimeFormater } from '../../../utils/dateHelper';
import Typography from '../../../components/Typography/Typography';
import { BidRequestService } from '../../../services/BidRequestService';
import MultiSelectDropdown from '../../../components/Inputs/MultiSelectDropdown';
import { MaintenanceHourValidationValidation } from '../../../validations/BidRequest/RequestBidvalidation';

// CSS Imports
import classes from '../../../styles/BidModal.module.css';

const getGroupOptions = (groups) => {
    const options = [];
    groups.forEach((g) => {
        options.push({ label: g.name, value: g._id });
    });
    return options;
};

const getGroupHashMap = (groups) => {
    const hashMap = {};
    groups.forEach((g) => {
        hashMap[g._id] = g;
    });
    return hashMap;
};

const getBiddingOptions = (groupMap, groupId) => {
    const biddingMarkets = groupMap[groupId]?.properties?.biddingMarkets || [];
    const options = [];
    biddingMarkets.forEach((m) => {
        options.push({ label: m, value: m });
    });
    return options;
};

const getInitialValues = (editRequest) => {
    const values = {
        groupId: editRequest.groupId || '',
        date: editRequest.date ? momentTimeFormater(editRequest.date).format('YYYY-MM-DD') : '',
        biddingMarket: editRequest.biddingMarket || '',
        hours: editRequest.hourSlots || [],
    };
    return values;
};

const AddMaintenanceHours = ({ market, groups = [], setOpen, editRequest = {}, setRefresh, licenseId }) => {
    const [startLoader, stopLoader] = useLoader();
    const [initialValues, setInitialValues] = useState(getInitialValues(editRequest));
    const groupOptions = getGroupOptions(groups);
    // const groupHashMap = getGroupHashMap(groups);
    // const [biddingMarketOptions, setBiddingMarketOptions] = useState(
    //   getBiddingOptions(groupHashMap, initialValues.groupId || groupOptions[0]?.value)
    // );
    const [hoursOptions, setHoursOption] = useState([]);

    useEffect(() => {
        if (market) hanldeMarketChange(market);
    }, [market]);

    // const handleinstanceChange = ({ value }) => {
    //   const options = getBiddingOptions(groupHashMap, value);
    //   setBiddingMarketOptions(options);
    // };

    const hanldeMarketChange = (value) => {
        let tempOptions = [];
        if (['FCR'].includes(value)) {
            Array(6)
                .fill(null)
                .forEach((h, i) => {
                    const start = i * 4;
                    tempOptions.push({ label: `${start} - ${start + 4}`, value: i + 1 });
                });
        } else if (['FFR', 'FCR-D-INC', 'FCR-D-DEC'].includes(value)) {
            Array(24)
                .fill(null)
                .forEach((h, i) => {
                    tempOptions.push({ label: `${i} - ${i + 1}`, value: i + 1 });
                });
        }
        setHoursOption(tempOptions);
    };

    const handleSubmit = (values) => {
        const payload = {
            ...values,
            hours: values.hours?.map((r) => r.value),
            licenseId,
            biddingMarket: market,
        };
        if (!editRequest._id) {
            BidRequestService.CreateMaintenanceHour(payload, startLoader, handleCreateSuccess, handleError, stopLoader);
        } else {
            BidRequestService.UpdateMaintenanceHour(editRequest._id, payload, startLoader, handleUpdateSuccess, handleError, stopLoader);
        }
    };

    const handleCreateSuccess = ({}) => {
        toast.success('Maintenance record added!');
        setRefresh && setRefresh(Math.random() * 1000);
        setOpen && setOpen(false);
    };

    const handleUpdateSuccess = ({}) => {
        toast.success('Maintenance record updated!');
        setRefresh && setRefresh(Math.random() * 1000);
        setOpen && setOpen(false);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    return (
        <div style={{ minWidth: '30vw' }}>
            <Typography content={`${editRequest._id ? 'Edit' : 'Create'} Maintenance Hours`} />
            <div className={classes.FormContainer}>
                <Formik initialValues={initialValues} validationSchema={MaintenanceHourValidationValidation} onSubmit={handleSubmit}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div className={classes.InputContainer}>
                                <div className={classes.FieldControl} style={{ flexGrow: 1 }}>
                                    <label for="groupId">
                                        Instance <span className="required">*</span>
                                    </label>
                                    <Dropdown
                                        name="groupId"
                                        options={groupOptions}
                                        defaultValue={initialValues.groupId ? groupOptions.filter((group) => group.value === values.groupId)[0] : groupOptions[0]}
                                        // onChange={handleinstanceChange}
                                    />
                                </div>
                                {/* <div className={classes.FieldControl} style={{ flexGrow: 1 }}>
                  <label for="biddingMarket">
                    Bidding Market <span className="required">*</span>
                  </label>
                  <Dropdown
                    name="biddingMarket"
                    options={biddingMarketOptions}
                    defaultValue={
                      initialValues.biddingMarket
                        ? biddingMarketOptions.filter(group => group.value === values.biddingMarket)[0]
                        : biddingMarketOptions[0]
                    }
                    onChange={hanldeMarketChange}
                  />
                </div> */}
                            </div>
                            <div className={classes.InputContainer}>
                                <div className={classes.FieldControl} style={{ flexGrow: 1 }}>
                                    <label>
                                        Date <span className="required">*</span>
                                    </label>
                                    <div className="modal-date-picker">
                                        <Input name="date" id="date" type="date" />
                                    </div>
                                </div>
                                <div className={classes.FieldControl} style={{ flexGrow: 1 }}>
                                    <label>
                                        Hours Slot <span className="required">*</span>
                                    </label>
                                    <MultiSelectDropdown name="hours" options={hoursOptions} defaultValue={initialValues.hours.map((h) => ({ value: h }))} />
                                </div>
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AddMaintenanceHours;
