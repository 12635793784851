// Standard library imports
import React, { useEffect, useState, useContext } from 'react';
import Typography from '../../../components/Typography/Typography';
import classes from '../../../styles/AllDevices.module.css';
import systemClasses from './SystemStatus.module.css';
import { AuthContext } from '../../../context/AuthContext';
import { get as lodashGet } from 'lodash';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import { CamelCaseToTextCase } from '../../../utils/stringHelper';
import { CustomerServices } from '../../../services/CustomerServices';
import { useLoader } from '../../../hooks';
import { toast } from 'react-toastify';
import { momentTimeFormater } from '../../../utils/dateHelper';

const SystemStatus = ({ setSystemStatusModal }) => {
    const [startLoader, stopLoader] = useLoader();
    const { state } = useContext(AuthContext);
    const bidMarkets = lodashGet(state, 'user.customerId.biddingMarkets');
    const licenses = lodashGet(state, 'user.customerId.enabledLicense');
    const [enabledLicenses, setEnabledLicenses] = useState({
        licenses: '',
        count: 0,
    });
    const [market, setMarket] = useState({
        markets: '',
        count: 0,
    });

    const [data, setData] = useState({});

    useEffect(() => {
        getSystemStatus();
        let tempMarkets = '';
        bidMarkets.map((m) => {
            tempMarkets = `${tempMarkets} ${m},`;
        });
        setMarket({
            markets: tempMarkets,
            count: bidMarkets?.length || 0,
        });

        let tempLicenses = '';
        let tempLicenseCount = 0;
        for (let l in licenses) {
            if (licenses[l].enabled) {
                tempLicenses = `${tempLicenses} ${CamelCaseToTextCase(licenses[l].licenseType.name)},`;
                tempLicenseCount += 1;
            }
        }
        setEnabledLicenses({
            licenses: tempLicenses,
            count: tempLicenseCount,
        });
    }, []);

    const getSystemStatus = () => {
        CustomerServices.getSystemStatus(startLoader, handleSystemStatusSuccess, handleError, stopLoader);
    };

    const handleSystemStatusSuccess = ({ data }) => {
        setData(data.data);
    };

    const handleError = (error) => {
        let message = error?.response?.data?.message;
        toast.error(message);
    };

    return (
        <div>
            <div>
                <div>
                    <div style={{ marginBottom: '2vh' }}>
                        <Typography content={'System Overview'} />
                    </div>
                    <div className={systemClasses.CardContainer}>
                        <div className={systemClasses.Card}>
                            <p>Storage</p>
                            <span>{`${data.storage} Mb`}</span>
                        </div>
                        <div className={systemClasses.Card}>
                            <CustomTooltip content={enabledLicenses.licenses}>
                                <p>Licenses</p>
                                <span>{enabledLicenses.count}</span>
                            </CustomTooltip>
                        </div>
                        <div className={systemClasses.Card}>
                            <CustomTooltip content={market.markets}>
                                <p>Markets</p>
                                <span>{market?.count}</span>
                            </CustomTooltip>
                        </div>
                        <div className={systemClasses.Card}>
                            <p>Instances</p>
                            <span>{data?.instanceCount}</span>
                        </div>
                        <div className={systemClasses.Card}>
                            <p>Last Updated </p>
                            <span>{`${momentTimeFormater(data.lastUpdated).format('YYYY-MM-DD')}`}</span>
                        </div>
                    </div>
                    <div className={classes.ButtonContainer}>
                        <div>
                            <button type="button" className="btn-primary" onClick={() => setSystemStatusModal(false)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SystemStatus;
