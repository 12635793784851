import classes from '../RealtimeMarket.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { NordpoolService } from '../../../services/NordpoolService';
import moment from 'moment-timezone';
import { LoaderContext } from '../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import ChartWithDateSelector from '../../../components/ApexCharts/ChartWithDateSelector';
import ChartSelector from '../../../components/ApexCharts/Chart';
import { timeOnly } from '../../../utils/dateHelper';
import { AuthContext } from '../../../context/AuthContext';
import { DropdownComponent } from '../../../components/Inputs/Input';
const getEnabledAreas = (customer) => {
    const options = [];
    customer.areas.forEach((a) => options.push({ value: a, label: a }));
    return options;
};

const matchQueryParams = (data = [], match = {}) => {
    for (let item of data) {
        if (item.value === match?.value) {
            return true;
        }
    }
    return false;
};

const ChartContainer = ({ refresh, updateQueryParams, queryParamsData }) => {
    const [graphData, setGraphData] = useState([]);
    const [lastStatus, setLastStatus] = useState(moment().tz('Europe/Berlin').format('YYYY-MM-DD'));
    const [LastStatusDemand, setLastStatusDemand] = useState(moment().tz('Europe/Berlin').format('YYYY-MM-DD'));
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(lastStatus));
    // const [DemandGraphData, setDemandGraphData] = useState([]);

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const { state } = useContext(AuthContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [priSignalOptions] = useState(getEnabledAreas(state?.user?.customerId));
    const [PriceSignal, setPriceSignal] = useState(
        priSignalOptions && queryParamsData.priceSignal && matchQueryParams(priSignalOptions, { value: queryParamsData.priceSignal }) === true
            ? queryParamsData.priceSignal
            : priSignalOptions[0]?.value
    );

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        updateQueryParams('priceSignal', PriceSignal);
    }, [PriceSignal]);

    useEffect(() => {
        let processedDate = moment(date).tz('Europe/Berlin').format('YYYY-MM-DD');
        NordpoolService.realtimeMarket(
            PriceSignal,
            processedDate,
            () => startLoader('getData'),
            handleGetDataSuccess,
            handleError,
            () => stopLoader('getData')
        );
    }, [date, refresh, PriceSignal, lastStatus]);

    useEffect(() => {
        let signal = {
            signal: `BalancingPowerConsumptionPriceDKK_${PriceSignal},BalancingPowerPriceUpDKK_${PriceSignal},BalancingPowerPriceDownDKK_${PriceSignal}`,
            demandSignal: `ImbalanceSurplus_${PriceSignal},ImbalanceDeficit_${PriceSignal}`,
        };
        NordpoolService.lastReported(
            signal,
            () => startLoader('getData'),
            handleLastReportedSuccess,
            handleError,
            () => stopLoader('getData')
        );
    }, [PriceSignal]);
    const handleLastReportedSuccess = ({ data }) => {
        setDate(new Date(data.data.price.start_time));
        const ndate = moment(data.data.price.start_time).format('YYYY-MM-DD');
        const demanddate = moment(data.data?.demand.start_time).format('YYYY-MM-DD');
        setLastStatus(ndate);
        setLastStatusDemand(demanddate);
    };

    const handleGetDataSuccess = ({ data }) => {
        setGraphData(data.data.potentialRevenue);
        // setDemandGraphData(data.data.demand);
    };

    const handleError = (err) => console.log(err);

    return (
        <div className={classes.MainChartContainer}>
            <div className={classes.ChartContainer}>
                <div className={classes.DropdownContainer}>
                    <DropdownComponent
                        defaultValue={
                            priSignalOptions && queryParamsData.priceSignal && matchQueryParams(priSignalOptions, { value: queryParamsData.priceSignal }) === true
                                ? { value: queryParamsData.priceSignal }
                                : PriceSignal
                        }
                        options={[...priSignalOptions]}
                        onChange={(value) => setPriceSignal(value.value)}
                    />
                </div>
                <ChartWithDateSelector
                    series={graphData}
                    title="Potential Revenue"
                    xFormatter={timeOnly}
                    type="line"
                    date={date}
                    setDate={setDate}
                    curve="stepline"
                    lastReported={lastStatus}
                />
            </div>
            {/* <div className={classes.ChartContainer}>
        <ChartSelector
          lastReported={LastStatusDemand}
          series={DemandGraphData}
          title="Demand"
          xFormatter={timeOnly}
          type="line"
          curve="stepline"
          legend={true}
        />
      </div> */}
        </div>
    );
};

export default ChartContainer;
