import React, { useEffect, useState } from 'react';
import ChartComponent from '../../../components/ApexCharts/Chart';
import { momentTimeFormater, timeOnly } from '../../../utils/dateHelper';
import classes from '../Home.module.css';
import { useLoader } from '../../../hooks/use-loader.hook';
import { BidMarketService } from '../../../services/BidMarketService';

const MarketInstanceCapacityCard = ({ market, groupId }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [group, setGroup] = useState({});

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        const params = {
            date: momentTimeFormater().format('YYYY-MM-DD'),
            market,
            instanceId: groupId,
        };
        BidMarketService.GetInstanceCapacity(
            params,
            () => startLoader(`${groupId}-capacity`),
            handleSuccess,
            handleError,
            () => stopLoader(`${groupId}-capacity`)
        );
    };

    const handleSuccess = ({ data }) => {
        const { capacityData, group } = data?.data;
        if (Array.isArray(capacityData)) {
            setData(capacityData);
        } else {
            setData([]);
        }
        if (group) setGroup(group);
        else {
            setGroup({});
        }
    };

    const handleError = (error) => {
        console.error(error);
    };

    return (
        <div className={classes.TopGraph} style={{ flexGrow: 2 }}>
            <div className={classes.FilterDropdown + ' filter-dropdown'}>
                <div className={classes.ChartGroupName}>{group?.name || ''}</div>
            </div>
            <ChartComponent
                title={`${market} Sold Capacity (kW)`}
                strokeWidth={2}
                type="line"
                curve="stepline"
                colors={['var(--color-primary)']}
                series={data}
                xFormatter={timeOnly}
                yFormatter={parseFloat}
                yProps={{ tickAmount: 5 }}
                xType={'category'}
                xProps={{ tickAmount: 5 }}
                xLabelProps={{ rotate: 0 }}
            />
        </div>
    );
};

export default MarketInstanceCapacityCard;
