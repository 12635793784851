// Standard library imports
import React, { useContext, useEffect, useState, useRef } from 'react';

// External library imports
import { Route, Switch, useHistory } from 'react-router-dom';
import { get as lodashGet, random as lodashRandom } from 'lodash';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { UilBell } from '@iconscout/react-unicons';
import { ControlledMenu, MenuItem, useClick, Menu, MenuDivider, SubMenu } from '@szhsin/react-menu';
import moment from 'moment';

// Internal module imports

import { PROTECTED_ROUTES } from '../../routes/Routes';
import { AuthContext } from '../../context/AuthContext';
import NotFound from '../ErrorPages/NotFound';
import Inactive from '../ErrorPages/Inactive';
import { REMOVE_USER, START_LOADER, STOP_LOADER, USER_ROLE } from '../../constants';
import N1 from '../../assets/notifications/1.svg';
import N2 from '../../assets/notifications/2.svg';
import N3 from '../../assets/notifications/3.svg';
import N4 from '../../assets/notifications/4.svg';
import N5 from '../../assets/notifications/5.svg';
import UnAuthorized from '../ErrorPages/UnAuthorized';
import { LoaderContext } from '../../context/LoaderContext';
import { UserService } from '../../services/UserService';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import Typography from '../../components/Typography/Typography';
import { Input, Password } from '../../components/Inputs/Input';
import PhoneNumberComponent from '../../components/Inputs/PhoneNumberComponent';
import { SET_USER } from '../../constants';
import { CustomerProfileValidation, userProfileValidation } from '../../validations/CustomerProfile/CustomerProfileValidation';
import { CustomerServices } from '../../services/CustomerServices';
import { CustomerPasswordValidation } from '../../validations/CustomerProfile/PasswordValidation';
import { AlertService } from '../../services/AlertService';

import TabsComponent from '../../components/Tabs/Tabs';
import FileUpload from '../../components/Inputs/FileUpload/FileUpload';
import { getUsers, removeAllUsers } from '../../utils/localStrorage';
import AddUserIcon from '../../assets/sidebar/AddUser.svg';
import LogoutAllIcon from '../../assets/sidebar/LogoutAll.svg';
import Clock from './Clock';
import SystemStatus from './ModalComponent/SystemStatus';
import RecentApps from '../../components/RecentApps/RecentApps';

// Css module imports
import classes from './MainContainer.module.css';

const NOTIFICATION_ICONS = [N1, N2, N3, N4, N5];

const getProtectedRoutesWithPermissions = (routes, userRole) => {
    //key is route id
    const toBeRemovedRoutes = {
        CFM: [],
    };
    if (userRole === 'auditor') {
        toBeRemovedRoutes.CFM.push('tools');
    }
    const updatedRoutes = routes.map((item) => {
        if (toBeRemovedRoutes[item.id] && item.childRoutes) {
            toBeRemovedRoutes[item.id].forEach((child) => {
                const index = item.childRoutes.findIndex((i) => i.id === child);
                if (index >= 1) item.childRoutes.splice(index, 1);
            });
        }
        return item;
    });
    return updatedRoutes;
};

const MainContainer = ({ title, setActive }) => {
    const history = useHistory();
    const { state, dispatch: authDispatch } = useContext(AuthContext);
    const [notifications, setNotifications] = useState([]);
    const [showNotifications, setShowNotifications] = useState(false);
    const [open, setOpen] = useState(false);
    const [systemStatusModal, setSystemStatusModal] = useState(false);
    const [userProfileImage, setUserProfileImage] = useState([]);
    const passwordExpired = lodashGet(state, 'user.passwordUpdatedOn') + 7890000 < moment().utc().unix();
    const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
    const isAditorUser = lodashGet(state, 'user.userRole') === 'auditor';
    const isCustomUser = lodashGet(state, 'user.userRole') === 'custom';
    const customRoutes = isCustomUser ? state.user.allowed : null;
    const isTruegreen = lodashGet(state, 'user.customerId.trueGreen.enabled');
    const bidMarkets = lodashGet(state, 'user.customerId.biddingMarkets');
    const logo = lodashGet(state, 'user.customerId.logo');
    const profileImage = lodashGet(state, 'user.profileImage');
    const details = lodashGet(state, 'user');
    const isEditingDisabled = ![USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(details.userRole);
    const notificationRef = useRef(null);
    const notificationAnchorProps = useClick(showNotifications, setShowNotifications);

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const dispatchSetUser = (payload) => authDispatch({ type: SET_USER, payload });
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const savedUsers = getUsers();
    const userId = lodashGet(state, 'user._id');
    const customerId = lodashGet(state, 'user.customerId._id');
    const enabledLicense = lodashGet(state, 'user.customerId.enabledLicense');
    const curUser = savedUsers.find((u) => u.id === userId && u.customerId === customerId);
    const canSwitchToUsers = savedUsers.filter((u) => u.id !== userId || u.customerId !== customerId);

    const PROTECTED_ROUTES_WITH_PERMISSIONS = getProtectedRoutesWithPermissions(PROTECTED_ROUTES, lodashGet(state, 'user.userRole'));

    useEffect(() => {
        if (passwordExpired) setOpenUpdatePassword(true);
    }, [passwordExpired]);

    const initialValues = {
        primaryContactEmail: details.customerId?.address?.primaryContactEmail,
        billingEmail: details.customerId?.address?.billingEmail,
        primaryContactName: details?.customerId?.address.primaryContactName,
        countryCode: details.customerId?.address?.countryCode,
        contact: details.customerId?.address?.contact,
        name: details.name,
        email: details.email,
        userContact: details.contact,
        userCountryCode: details.countryCode,
    };

    const initialUserProfileValues = {
        name: details.name,
        email: details.email,
        contact: details.contact,
        countryCode: details.countryCode,
    };

    const initialPasswordValues = {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    const handleLogout = () => {
        UserService.logout(
            () => startLoader('logout'),
            handleSuccess,
            handleErrorAndlogout,
            () => stopLoader('logout')
        );
    };

    const handleSuccess = ({ data }) => {
        toast.success('Logout Success!');
        authDispatch({ type: REMOVE_USER });
        history.push('/auth/login');
    };

    const handleSubmit = (values) => {
        CustomerServices.updateProfileInfo(
            values,
            () => startLoader('updateProfile'),
            handleUpdateSuccess,
            handleError,
            () => stopLoader('updateProfile')
        );
    };

    const handleErrorAndlogout = () => {
        authDispatch({ type: REMOVE_USER });
        localStorage.clear();
        history.push('/auth/login');
    };

    const handleError = (error) => {
        let message = error?.response?.data?.message;
        toast.error(message);
    };

    const handleUpdateSuccess = ({ data }) => {
        toast.success('Company profile updated!');
        setOpen(false);
        refreshProfile();
    };

    const refreshProfile = () => {
        UserService.verify(
            () => startLoader('profilefetch'),
            handleRefresh,
            handleErrorAndlogout,
            () => stopLoader('profilefetch')
        );
    };

    const handleRefresh = ({ data }) => {
        dispatchSetUser({ user: data.data });
    };

    const handleUpdatePassword = (values) => {
        const payload = {
            currentPassword: values?.currentPassword,
            newPassword: values?.newPassword,
            confirmNewPassword: values?.confirmNewPassword,
        };
        UserService.updatePasswordInfo(
            payload,
            () => startLoader('updatePassword'),
            handlePasswordUpdateSuccess,
            handleError,
            () => stopLoader('updatePassword')
        );
    };

    const handlePasswordUpdateSuccess = () => {
        toast.success('Password updated successfully!');
        setOpenUpdatePassword(false);
    };

    const licenses = lodashGet(state, 'user.customerId.license');
    let processedRoutes =
        licenses &&
        PROTECTED_ROUTES_WITH_PERMISSIONS.map((route) => {
            if (route.id === 'e3' && !isTruegreen) {
                let componentToShow = UnAuthorized;
                return <Route path={`${route.url}`} component={componentToShow} />;
            }
            if (route.id === 'e3App' && enabledLicense && Object.keys(enabledLicense).length) {
                let childRoutes = [];
                for (let licenseTypeId in enabledLicense) {
                    const license = enabledLicense[licenseTypeId];
                    let componentToShow;
                    if (license.enabled) {
                        componentToShow = route.component;
                    } else {
                        componentToShow = Inactive;
                    }
                    childRoutes.push(<Route path={`${route.url}/${license.licenseType.name}`} component={componentToShow} />);
                }
                return childRoutes;
            } else if (route.childRoutes && route.id === 'e3') {
                return route.childRoutes.map((childRoute) => {
                    if (childRoute.id !== 'datahub') {
                        let componentToShow;
                        if (isCustomUser && customRoutes[childRoute.id] !== true) {
                            componentToShow = UnAuthorized;
                        } else if (!childRoute.notConfigured && licenses[childRoute.id] && licenses[childRoute.id].enabled) {
                            componentToShow = childRoute.component;
                        } else if (licenses[childRoute.id]) {
                            componentToShow = Inactive;
                        } else {
                            componentToShow = NotFound;
                        }

                        return <Route path={`${route.url + childRoute.url}`} component={componentToShow} />;
                    } else {
                        const componentToShow = Boolean(!isCustomUser || (isCustomUser && customRoutes[childRoute.id])) ? childRoute.component : UnAuthorized;
                        return <Route path={`${route.url + childRoute.url}`} component={componentToShow} />;
                    }
                });
            } else if (route.childRoutes && route.id === 'demandResponse') {
                return route.childRoutes.map((childRoute) => {
                    let componentToShow = UnAuthorized;
                    if (['FCR', 'FFR', 'FCR-D-INC', 'FCR-D-DEC', 'FCR-N', 'aFRR'].includes(childRoute.id)) {
                        if (bidMarkets.includes(childRoute.id)) {
                            componentToShow = childRoute.component;
                        }
                    } else {
                        componentToShow = childRoute.component;
                    }
                    return <Route path={`${route.url + childRoute.url}`} component={componentToShow} />;
                });
            } else if (route.childRoutes && route.id === 'insights') {
                return route.childRoutes.map((childRoute) => {
                    let componentToShow = UnAuthorized;
                    if (childRoute.id === 'dataHub' && !enabledLicense?.['645af12476b7895730655ba7']?.enabled) {
                        componentToShow = NotFound;
                    } else {
                        componentToShow = childRoute.component;
                    }
                    return <Route path={`${route.url + childRoute.url}`} component={componentToShow} />;
                });
            } else if (route.childRoutes) {
                return route.childRoutes.map((childRoute) => {
                    let componentToShow = UnAuthorized;
                    if (route.id === 'CFM' && licenses[route.id] && licenses[route.id].enabled) {
                        if (childRoute.id === 'tools') {
                            componentToShow = isAditorUser ? UnAuthorized : childRoute.component;
                        } else {
                            componentToShow = Boolean(!isCustomUser || (isCustomUser && customRoutes[childRoute.id])) ? childRoute.component : UnAuthorized;
                        }
                    } else if (route.id != 'CFM') {
                        componentToShow = Boolean(!isCustomUser || (isCustomUser && customRoutes[childRoute.id])) ? childRoute.component : UnAuthorized;
                    } else {
                        componentToShow = UnAuthorized;
                    }
                    return <Route path={`${route.url + childRoute.url}`} component={componentToShow} />;
                });
            } else {
                let componentToShow = Boolean(!isCustomUser || (isCustomUser && customRoutes[route.id])) ? route.component : UnAuthorized;
                if (route.id === 'cfmdashboard' && !licenses['CFM']?.enabled) {
                    componentToShow = UnAuthorized;
                }
                if (route.id === 'dashboard' && !isTruegreen) {
                    componentToShow = UnAuthorized;
                }

                return <Route exact path={route.url} component={componentToShow} />;
            }
        }).flat();
    processedRoutes?.push(<Route path={`*`} component={NotFound} />);

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = async () => {
        const params = {
            date: moment().format('YYYY-MM-DD'),
        };
        AlertService.GetNotifications(
            params,
            () => startLoader('notificationFetch'),
            ({ data }) => setNotifications(data.data),
            handleError,
            () => stopLoader('notificationFetch')
        );
    };

    const handleShowNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    const getProfileDetails = () => {
        return (
            <div className={classes.ProfilePreview}>
                {logo ? (
                    <div className={classes.CompanyLogoWrapper}>
                        <img src={logo} alt="logo" className={classes.CompanyLogo} />
                    </div>
                ) : (
                    <div>{lodashGet(state, 'user.customerId.name')}</div>
                )}
                <div className={classes.ProfileImageWrapper}>
                    <img src={profileImage} alt="dp" className={classes.ProfileImage} />
                </div>
            </div>
        );
    };

    const handleUploadClick = (e) => {
        const file = e.target.files[0];
        setUserProfileImage(file);
    };

    const handleUserEditSubmit = (values) => {
        const formData = new FormData();
        const isLogoImageValid = /(jpg|png|peg)$/.test(userProfileImage?.name?.slice(-4));
        if (userProfileImage.name && !isLogoImageValid) {
            toast.error('Please select a valid image format');
            return;
        }
        formData.append('profileImage', userProfileImage);
        formData.append('values', JSON.stringify(values));
        UserService.updateUserProfile(
            formData,
            () => startLoader('userProfileUpdate'),
            handleUpdateUserProfileSuccess,
            handleError,
            () => stopLoader('userProfileUpdate')
        );
    };

    const handleUpdateUserProfileSuccess = () => {
        toast.success('Profile updated successfully!');
        refreshProfile();
        setOpen(false);
    };

    const handleSwitchUser = (user) => {
        if (user.token) {
            localStorage.setItem('GM_TOKEN', user.token);
            window.location.reload();
        }
    };

    const goToLogin = () => {
        authDispatch({ type: REMOVE_USER });
        history.push('/auth/login');
    };

    const logoutAll = () => {
        authDispatch({ type: REMOVE_USER });
        removeAllUsers();
        history.push('/auth/login');
    };

    const tabs = [
        {
            name: 'Edit Profile',
            component: (
                <Formik
                    initialValues={initialUserProfileValues}
                    onSubmit={(val, { resetForm }) => handleUserEditSubmit(val, resetForm)}
                    validationSchema={userProfileValidation}
                    style={{ overflow: 'initial' }}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <div>
                                        <div>
                                            <div className={classes.FieldControl}>
                                                <label for="name">Name</label>
                                                <Input name="name" id="name" disabled={isEditingDisabled} />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="email">Email</label>
                                                <Input name="email" id="email" disabled={isEditingDisabled} />
                                            </div>

                                            <div className={classes.FieldControl}>
                                                <label for="contact">Contact</label>
                                                <div className={classes.contactContainer}>
                                                    <PhoneNumberComponent
                                                        inputName="contact"
                                                        countryCodeName="countryCode"
                                                        inputValues={values}
                                                        defaultCountryCode={{ value: initialValues.countryCode }}
                                                        disabled={isEditingDisabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="contact">Profile Image</label>
                                                <FileUpload name="logo" id="profileImage" accept="image/*" onChange={handleUploadClick} />
                                            </div>
                                        </div>

                                        {/* button */}
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button type="submit" className="btn-primary" disabled={isEditingDisabled}>
                                                    Submit
                                                </button>
                                            </div>
                                            <div
                                                className="btn-secondary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpen(false);
                                                }}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ),
        },
        {
            name: 'Edit Company Profile',
            component: (
                <Formik
                    initialValues={initialValues}
                    onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                    validationSchema={CustomerProfileValidation}
                    style={{ overflow: 'initial' }}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <div>
                                        <div>
                                            <div className={classes.FieldControl}>
                                                <label for="primaryContactName">Primary Contact Name</label>
                                                <Input name="primaryContactName" id="primaryContactName" disabled={isEditingDisabled} />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="primaryContactEmail">Primary Contact Email</label>
                                                <Input name="primaryContactEmail" id="primaryContactEmail" disabled={isEditingDisabled} />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="billingEmail">Billing Email</label>
                                                <Input name="billingEmail" id="billingEmail" disabled={isEditingDisabled} />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="contact">Contact</label>
                                                <div className={classes.contactContainer}>
                                                    <PhoneNumberComponent
                                                        inputName="contact"
                                                        countryCodeName="countryCode"
                                                        inputValues={values}
                                                        defaultCountryCode={{ value: initialValues.countryCode }}
                                                        disabled={isEditingDisabled}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button type="submit" className="btn-primary" disabled={isEditingDisabled}>
                                                    Submit
                                                </button>
                                            </div>
                                            <div
                                                className="btn-secondary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpen(false);
                                                }}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            ),
        },
    ];

    return (
        <>
            <div>
                <div className={classes.NavBar}>
                    <div className={classes.NavBarStart}>
                        <div className={classes.Title}>{title}</div>
                    </div>
                    <div className={classes.NavBarEnd}>
                        <div className={classes.NotificationIconwrapper} ref={notificationRef} {...notificationAnchorProps}>
                            <UilBell size="30px" style={{ color: 'var(--color-primary)', cursor: 'pointer' }} />
                            {lodashGet(notifications, 'totalCount') ? <div className={classes.NotificationDot}>{notifications.totalCount}</div> : null}
                            <ControlledMenu
                                state={showNotifications ? 'open' : 'closed'}
                                anchorRef={notificationRef}
                                onClose={handleShowNotifications}
                                direction="bottom"
                                arrow={true}
                                align="end"
                                menuClassName={classes.Notification}
                                offsetY={10}
                            >
                                {notifications && notifications.latestData?.length ? (
                                    notifications.latestData.map((notification, i) => (
                                        <MenuItem
                                            onClick={() => {
                                                history.push(`/e3-Legacy/${notification.source}`);
                                                setShowNotifications(!showNotifications);
                                            }}
                                            className={classes.MenuItems}
                                            style={i !== notifications.latestData?.length ? { borderBottom: '1px solid var(--gray-50)' } : {}}
                                        >
                                            <div className={classes.NotificationImageWrapper}>
                                                <img style={{ width: '2.2vw' }} src={NOTIFICATION_ICONS[lodashRandom(0, 4)]} alt="notification" />
                                            </div>
                                            <div className={classes.Content}>
                                                <div className={classes.NotificationTitle}>{notification.name}</div>
                                                <div className={classes.TimeAndSource}>
                                                    <div className={classes.SubTitle}>{notification.source}</div>
                                                    <div className={classes.Time} style={{ marginTop: '1px' }}>
                                                        {notification.triggerTime}
                                                    </div>
                                                </div>
                                            </div>
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem>No notifications for today!</MenuItem>
                                )}
                            </ControlledMenu>
                        </div>
                        <Clock />
                        <Menu menuButton={getProfileDetails()} align="end" arrow={true} menuClassName={classes.ProfileMenu}>
                            <MenuItem className={classes.RoleName}>
                                <div className={classes.UserName}>Hey, {state?.user?.name}</div>
                                <div className={classes.UserRole}>User Role: {state?.user?.userRole}</div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem className={classes.ProfileMenuItems} onClick={() => setOpen(true)}>
                                Edit Profile
                            </MenuItem>
                            <MenuItem className={classes.ProfileMenuItems} onClick={() => setOpenUpdatePassword(true)}>
                                Update Password
                            </MenuItem>
                            <MenuItem className={classes.ProfileMenuItems} onClick={() => history.push('/insights/supportNotes')}>
                                Support Notes
                            </MenuItem>
                            <MenuItem className={classes.ProfileMenuItems} onClick={() => history.push('/insights/versionhistory')}>
                                Version History
                            </MenuItem>
                            <MenuItem
                                className={classes.ProfileMenuItems}
                                onClick={() => {
                                    setSystemStatusModal(true);
                                }}
                            >
                                System Overview
                            </MenuItem>
                            <SubMenu label="Switch User" className={classes.ProfileMenuItems}>
                                <MenuItem className={classes.MenuItemClassNameSW2}>
                                    <div className={classes.CurUserImageWrapper}>
                                        <img src={curUser?.profileImage} className={classes.CurUserImage} />
                                    </div>
                                    <div>
                                        <div className={classes.UserCustomer}>Customer: {curUser?.customerName}</div>
                                        <div className={classes.UserEmail}>{curUser?.email}</div>
                                        <div className={classes.CurrentAccount}>Current</div>
                                    </div>
                                </MenuItem>
                                <MenuDivider className={classes.MenuDivider} />
                                <div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
                                    {canSwitchToUsers.length ? (
                                        <>
                                            {canSwitchToUsers.map((u) => (
                                                <MenuItem className={classes.MenuItemClassNameSW} onClick={() => handleSwitchUser(u)}>
                                                    <div className={classes.CurUImageWrapper}>
                                                        <div className={classes.CurUImageCont}>
                                                            <img src={u?.profileImage} className={classes.CurUserImage} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className={classes.UserCustomer}>Customer: {u?.customerName}</div>
                                                        <div className={classes.UserEmail}>{u?.email}</div>
                                                    </div>
                                                </MenuItem>
                                            ))}
                                            <MenuDivider className={classes.MenuDivider} />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                <MenuItem className={classes.MenuItemClassNameSW} onClick={goToLogin}>
                                    <div className={classes.CurUImageWrapper}>
                                        <div className={classes.CurUImageCont}>
                                            <img src={AddUserIcon} className={classes.CurUserImage} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.UserCustomer}>Add Account</div>
                                    </div>
                                </MenuItem>
                                <MenuItem className={classes.MenuItemClassNameSW} onClick={logoutAll}>
                                    <div className={classes.CurUImageWrapper}>
                                        <div className={classes.CurUImageCont}>
                                            <img src={LogoutAllIcon} className={classes.CurUserImage} />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.UserCustomer}>Logout All Accounts</div>
                                    </div>
                                </MenuItem>
                            </SubMenu>
                        </Menu>
                    </div>
                </div>
                <div className={classes.DashBoard}>
                    <Switch>{processedRoutes}</Switch>
                </div>
            </div>
            <ModalComponent isOpen={systemStatusModal} setOpen={setSystemStatusModal}>
                <SystemStatus setSystemStatusModal={setSystemStatusModal} />
            </ModalComponent>
            <ModalComponent isOpen={open} setOpen={setOpen} onClose={() => setOpen(false)}>
                <TabsComponent tabs={tabs} selectedIndex={0} />
            </ModalComponent>
            <ModalComponent
                isOpen={openUpdatePassword}
                setOpen={setOpenUpdatePassword}
                onClose={() => {
                    !passwordExpired && setOpenUpdatePassword(false);
                }}
            >
                <Formik initialValues={initialPasswordValues} validationSchema={CustomerPasswordValidation} onSubmit={(val, { resetForm }) => handleUpdatePassword(val, resetForm)}>
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <Typography content="Update Password" />
                                    {passwordExpired && <div className="error-msg">You need to change your password due to expiration.</div>}
                                    <div>
                                        <div>
                                            <div className={classes.FieldControl}>
                                                <label for="currentPassword">Current Password</label>
                                                <Password name="currentPassword" id="currentPassword" />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="newPassword">New Password</label>
                                                <Password name="newPassword" id="newPassword" />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="confirmNewPassword">Confirm New Password</label>
                                                <Password name="confirmNewPassword" id="confirmNewPassword" />
                                            </div>
                                        </div>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                            <div>
                                                {passwordExpired ? (
                                                    <div className="btn-secondary" onClick={handleLogout}>
                                                        Logout
                                                    </div>
                                                ) : (
                                                    <div className="btn-secondary" onClick={() => setOpenUpdatePassword(false)}>
                                                        Cancel
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </ModalComponent>
            <RecentApps setActive={setActive} />
        </>
    );
};

export default MainContainer;
