// Standard library imports
import React, { useContext, useState } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import TabsComponent from '../../components/Tabs/Tabs';
import Details from './Details/Details';

// Css module imports
import classes from './Economics.module.css';

function Economics() {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [queryParamsData] = useState({
        dateRange: queryParams.get('dateRange') ? JSON.parse(queryParams.get('dateRange')) : '',
    });

    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            queryParamsNew = new URLSearchParams();
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };

    const tabs = [
        {
            name: 'Overview',
            component: <Details updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
    ];
    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent tabs={tabs} />
        </div>
    );
}

export default Economics;
