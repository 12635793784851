import { FieldArray, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Input, DropdownComponent, ToggleButton, ToggleButtonWithState } from '../../../../components/Inputs/Input';
import Typography from '../../../../components/Typography/Typography';
import AddIcon from '../../../../assets/add.svg';
import RemoveIcon from '../../../../assets/remove.svg';
import { PricingEmailValidation } from '../../../../validations/Pricing/PricingValidation';
import TabsComponent from '../../../../components/Tabs/Tabs';
import DeleteModalComponent from '../../../../components/DeleteModal/DeleteModal';
import { EmailSubscriptionService } from '../../../../services/EmailSubscription';
import { useLoader } from '../../../../hooks';
import { toast } from 'react-toastify';
import { get as LodashGet } from 'lodash';
import { AuthContext } from '../../../../context/AuthContext';
import { USER_ROLE } from '../../../../constants';
import Table from '../../../../components/Table/Table';
import DeleteIcon from '../../../../assets/delete.png';
import { Capitalize } from '../../../../utils/stringHelper';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import InfoIcon from '../../../../assets/info.svg';
import Dropdown from '../../../../components/Inputs/Dropdown';

// Css imports
import classes from '../../DemandResponse.module.css';

const getLicenseMap = (licenses = []) => {
    const map = {};
    licenses.forEach((l) => {
        map[l._id] = l;
    });
    return map;
};

const getLicenseOptions = (licenses = []) => {
    const map = [{ label: 'None', value: null }];
    licenses.forEach((l) => map.push({ label: Capitalize(l._id), value: l._id }));
    return map;
};

const getInstanceNameMap = (licenses = []) => {
    const map = {};
    licenses.forEach((l) => {
        if (l.groups?.length) {
            l.groups.map((g) => {
                map[g._id] = g.name;
            });
        }
    });
    return map;
};

const AddEmails = ({ licenses, market }) => {
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const [startLoader, stopLoader] = useLoader();
    const [subscriptions, setSubscriptions] = useState([]);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [licenseMap] = useState(getLicenseMap(licenses));
    const [licenseOptions] = useState(getLicenseOptions(licenses));
    const [instanceOptions, setInstaceOptions] = useState([]);
    const [instanceNameMap] = useState(getInstanceNameMap(licenses));

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        if (market) {
            const params = {
                eventType: market,
            };
            EmailSubscriptionService.ReadAll(params, startLoader, handleDataSucces, handleError, stopLoader);
        }
    };

    const handleToggleChange = (value, type, _id) => {
        EmailSubscriptionService.UpdateActivationBidResult({ value, type, _id }, startLoader, handleUpdateSucces, handleError, stopLoader);
    };

    const handleUpdateSucces = () => {
        toast.success('Details Updated!');
    };

    const handleDataSucces = ({ data }) => {
        if (data.data) {
            const processed = data.data.map((item, index) => ({
                sno: index + 1,
                email: item.email,
                eventType: item.eventType,
                activation: (
                    <ToggleButtonWithState
                        value={item.activation}
                        onChange={(e) => handleToggleChange(e.target.checked, 'activation', item._id)}
                        warpperStyle={{ margin: '0', justifyContent: 'center' }}
                    />
                ),
                bidResult: (
                    <ToggleButtonWithState
                        value={item.bidResult}
                        onChange={(e) => handleToggleChange(e.target.checked, 'bidResult', item._id)}
                        warpperStyle={{ margin: '0', justifyContent: 'center' }}
                    />
                ),
                instanceName: (
                    <div>
                        {instanceNameMap[item.instanceId] ? (
                            instanceNameMap[item.instanceId]
                        ) : (
                            <CustomTooltip content={`Details for Instance "${item.instanceId}" is not available`}>
                                <div style={{ display: 'flex', gridColumnGap: '1vw', justifyContent: 'center' }}>
                                    <img className={classes.ActionIcon} src={InfoIcon} alt="" />
                                </div>
                            </CustomTooltip>
                        )}
                    </div>
                ),
                action: (
                    <div onClick={() => setDeleteModal({ status: true, id: item._id })}>
                        <img className={classes.ActionIcon} src={DeleteIcon} alt="" />
                    </div>
                ),
            }));
            setSubscriptions(processed);
        } else {
            setSubscriptions([]);
        }
    };

    const handleSubmit = (values, { resetForm }) => {
        if (market) {
            const data = {
                ...values,
                eventType: market,
            };
            EmailSubscriptionService.Create(data, startLoader, () => handleCreateSucces(resetForm), handleError, stopLoader);
        }
    };

    const handleCreateSucces = (resetForm) => {
        resetForm();
        fetchData();
        toast.success('Subscription Emails Added!');
    };

    const handleDelete = (id) => {
        if (id) {
            EmailSubscriptionService.Delete(id, startLoader, handleDeleteSuccess, handleError, stopLoader);
        }
    };

    const handleDeleteSuccess = () => {
        fetchData();
        toast.success('Subscription Emails Added!');
    };

    const handleError = (err) => {
        console.log(err);
    };

    const handleChangeLicense = (e) => {
        const selectedLicense = licenseMap[e.value];
        const options = [];
        selectedLicense?.groups?.forEach((group) => options.push({ label: group.name, value: group._id }));
        setInstaceOptions(options);
    };

    const tabs = [
        {
            name: 'Emails',
            component: (
                <div>
                    <Table
                        head={['S.No', 'Email', 'Instance', 'Activation', 'Bid Result', ...([USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? ['Actions'] : [])]}
                        keys={['sno', 'email', 'instanceName', 'activation', 'bidResult', ...([USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? ['action'] : [])]}
                        data={subscriptions}
                    />
                </div>
            ),
        },
        ...([USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole)
            ? [
                {
                    name: 'Add Emails',
                    component: (
                        <div>
                            <Formik
                                enableReinitialize
                                initialValues={{ emails: [''], instanceId: '', activation: false, bidResult: false }}
                                onSubmit={handleSubmit}
                                validationSchema={PricingEmailValidation}
                            >
                                {({ errors, touched, values, isValidating, submitCount, ...props }) => (
                                    <Form>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl}>
                                                <label for="depotEmail">License</label>
                                                <Dropdown
                                                    name="license"
                                                    label="License"
                                                    options={licenseOptions}
                                                    setFieldValue={props.setFieldValue}
                                                    setFieldTouched={props.setFieldTouched}
                                                    getFieldMeta={props.getFieldMeta}
                                                    onChange={handleChangeLicense}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl}>
                                                <label for="depotEmail">Instance</label>
                                                <Dropdown
                                                    name="instanceId"
                                                    label="Instance"
                                                    options={instanceOptions}
                                                    setFieldValue={props.setFieldValue}
                                                    setFieldTouched={props.setFieldTouched}
                                                    getFieldMeta={props.getFieldMeta}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', gap: '1vw' }}>
                                            <ToggleButton values={values} label="Activation" name="activation" />
                                            <ToggleButton values={values} label="Bid Result" name="bidResult" />
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl}>
                                                <label for="depotEmail">Emails</label>
                                                <FieldArray name="emails">
                                                    {({ push, remove, form }) => {
                                                        const { emails } = form.values;
                                                        return (
                                                            <div>
                                                                <div>
                                                                    {emails.map((email, index) => (
                                                                        <div key={index} className={classes.FormContentNoSpace}>
                                                                            <Input name={`emails[${index}]`} type="email" />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className={classes.addInputActionWrapper}>
                                                                    {emails?.length > 1 && (
                                                                        <span onClick={() => remove(emails.length - 1)}>
                                                                            <img src={RemoveIcon} className={classes.addInputActionbutton} />
                                                                        </span>
                                                                    )}
                                                                    <span onClick={() => push('')}>
                                                                        <img src={AddIcon} className={classes.addInputActionbutton} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </FieldArray>
                                            </div>
                                        </div>
                                        <div className={classes.ButtonContainer}>
                                            <div></div>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    ),
                },
            ]
            : []),
    ];

    return (
        <div>
            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => {
                    setDeleteModal({ status, id: '' });
                }}
                deviceId={deleteModal.id}
            />
            <div style={{ marginBottom: '1.5vh' }}>
                <Typography content="Email Subscriptions" />
            </div>
            <TabsComponent tabs={tabs} />
        </div>
    );
};

export default AddEmails;
