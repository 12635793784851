// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import moment from 'moment-timezone';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import ChartComponent from '../../../components/ApexCharts/Chart';
import { timeOnly } from '../../../utils/dateHelper';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import TabsComponent from '../../../components/Tabs/Tabs';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import { InstanceDataLogsService } from '../../../services/InstanceDataLogsService';

// Css imports
import classes from '../../../styles/Overview.module.css';

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const Overview = ({ device, startLoader, stopLoader, updateQueryParams, queryParamsData }) => {
    const [lastStatus, setLastStatus] = useState('--');
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : today);
    const [graphData, setGraphData] = useState([]);
    const [consumptionData, setConsumptionData] = useState([]);
    const [selectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        if (device.name !== 'overview' && device._id) {
            let filteredParams = {
                groupId: device._id,
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            };
            InstanceDataLogsService.Overview(
                filteredParams,
                () => startLoader('getInstanceOverview'),
                handleGetDeviceDataSuccess,
                handleError,
                () => stopLoader('getInstanceOverview')
            );
        }
    }, [device, date]);

    useEffect(() => {
        if (device._id) {
            InstanceDataLogsService.LastReported(
                { groupId: device._id },
                () => startLoader('getOverviewGraphLastReported'),
                handleGetLastReportedSuccess,
                handleError,
                () => stopLoader('getOverviewGraphLastReported')
            );
        }
    }, [device]);

    const handleGetDeviceDataSuccess = ({ data }) => {
        setGraphData(data.data.powerData);
        setConsumptionData(data.data.consumptionData || [{ data: [] }]);
    };
    const handleGetLastReportedSuccess = ({ data }) => {
        setLastStatus(data?.data?.lastReported);
    };

    const handleExport = (type) => {
        let downloadData = [];
        const headers = ['Date & Time'];
        const consumptions = consumptionData[0]?.data || [];
        if (type === 'power') {
            graphData[0]?.data?.length &&
                graphData[0]?.data?.map((item, index) => {
                    let temp = {
                        date: item.x,
                        power: item.y,
                    };
                    if (consumptions?.length) {
                        temp.consumption = consumptions?.[index].y ? consumptions?.[index].y : '--';
                    }
                    downloadData.push(temp);
                });
            headers.push('Power (kW)');
            if (consumptions?.length) {
                headers.push('Consumption (kWh)');
            }
        } else if (type === 'consumption') {
            consumptions?.length &&
                consumptions?.map((item, index) => {
                    let temp = {
                        date: item.x,
                        consumption: item.y,
                    };
                    downloadData.push(temp);
                });
            headers.push('Consumption (kWh)');
        }

        downloadData?.length &&
            DownloadAsExcel(downloadData, `${device.name} ${type === 'power' ? ' Power ' : ' Consumption '} Data ${moment(date).format('DD-MM-YYYY')}`, headers);
    };

    const handleError = (err) => console.log(err);

    const tabs = [
        {
            name: 'Power (kW)',
            component: (
                <div className={classes.ChartContainer}>
                    <div>
                        <div className={classes.Actions}>
                            <DatePicker date={date} setDate={setDate} />
                            <DownloadButton size="meduim" onClick={() => handleExport('power')} />
                        </div>
                    </div>
                    <ChartComponent type="area" series={graphData} xFormatter={timeOnly} />
                </div>
            ),
        },
        {
            name: 'Consumption (kWh)',
            component: (
                <div className={classes.ChartContainer}>
                    <div className={classes.Actions}>
                        <DatePicker date={date} setDate={setDate} />
                        <DownloadButton size="meduim" onClick={() => handleExport('consumption')} />
                    </div>
                    <ChartComponent type="area" series={consumptionData} xFormatter={timeOnly} />
                </div>
            ),
        },
    ];

    return (
        <div className={classes.Overview}>
            <div className={classes.Header}>
                <div>
                    <Typography content={device.name} />
                    <div>
                        <Typography subtext content={'Last Reported: ' + (lastStatus || ' --')} />
                    </div>
                </div>
            </div>
            <div className={classes.OverviewBorder}></div>
            <div className={classes.OverviewContent}>
                <TabsComponent tabs={tabs} selectedIndex={selectedIndex} onChangeTab={(index) => updateQueryParams('subTab', index)} />
            </div>
        </div>
    );
};

export default Overview;
