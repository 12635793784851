import React from 'react';
import Table from '../../../components/Table/Table';
import { UilRedo } from '@iconscout/react-unicons';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import classes from '../../../styles/Alerts.module.css';

const TriggeredAlerts = ({ alerts, getTriggeredAlerts, date, setDate }) => {
    const style = {
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gridColumnGap: '1vw',
    };
    return (
        <div className={classes.Alert}>
            <div>
                <div style={style}>
                    <div>
                        <DatePicker date={date} setDate={setDate} />
                    </div>
                    <UilRedo size="1.9vw" style={{ color: 'var(--color-primary)' }} onClick={() => getTriggeredAlerts()} />
                </div>
            </div>
            <Table head={['Name', 'Type', 'Trigger Time', 'Value', 'Criteria']} keys={['name', 'type', 'triggerTime', 'value', 'criteria']} data={alerts} />
        </div>
    );
};

export default TriggeredAlerts;
