import classes from '../CarbonEmission.module.css';
import React from 'react';
import ChartWithDateSelector from '../../../components/ApexCharts/ChartWithDateSelector';
import { timeOnly } from '../../../utils/dateHelper';

const ChartContainer = ({ graphData, date, setDate, lastStatus }) => {
    return (
        <div className={classes.BottomContainer}>
            <ChartWithDateSelector
                series={graphData}
                title="Carbon Emission"
                xFormatter={timeOnly}
                type="line"
                date={date}
                setDate={setDate}
                curve="stepline"
                lastReported={lastStatus}
            />
        </div>
    );
};

export default ChartContainer;
