import React, { useEffect, useContext, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import MainContainer from '../containers/MainContainer/MainContainer';
import { PROTECTED_ROUTES } from '../routes/Routes';
import { get as lodashGet } from 'lodash';
import { AuthContext } from '../context/AuthContext';

const getActiveRoute = () => {
    const curRoute = PROTECTED_ROUTES.slice()
        .reverse()
        .find((route) => window.location.pathname.includes(route.url));
    if (curRoute?.childRoutes) {
        let curSubRoute = '';
        if (curRoute.url === '/e3-App') {
            curSubRoute = curRoute.childRoutes.find((route) => {
                let childRouteUrl = route.url.split('?')[0];
                return window.location.pathname.replace(curRoute.url, '') === childRouteUrl;
            });
        } else {
            curSubRoute = curRoute.childRoutes.find((route) => window.location.pathname.replace(curRoute.url, '') === route.url);
        }
        if (curSubRoute) return `${curRoute?.name} > ${curSubRoute.name}`;
    } else {
        return curRoute?.name;
    }
};

const PublicLayout = () => {
    const history = useHistory();
    const [activeRoute, setActiveRoute] = useState(getActiveRoute());
    const { state } = useContext(AuthContext);
    const enabledLicense = lodashGet(state, 'user.customerId.enabledLicense');

    useEffect(() => {
        const route = getActiveRoute();
        setActiveRoute(route);
    }, [enabledLicense]);

    useEffect(() => {
        history.listen((location, action) => {
            window.scrollTo(0, 0);
        });
    });

    return (
        <div>
            <div className="main-content">
                <div>
                    <Sidebar active={activeRoute} setActive={setActiveRoute} />
                </div>
                <div style={{ height: '100vh', overflowY: 'auto', flexGrow: 1 }}>
                    <Switch>
                        <Route path={'/'} render={() => <MainContainer title={activeRoute} setActive={setActiveRoute} />} />
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default PublicLayout;
