// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import TabsComponent from '../../components/Tabs/Tabs';
import ChartContainer from './Tabs/ChartContainer';
import Live from './Tabs/Live';
import Events from './Events/Events';
import { useLoader } from '../../hooks';
import { FrequencyService } from '../../services/FrequencyService';
import RequestBid from '../../ReUsables/RequestBid';

// Css imports
import classes from './DemandResponse.module.css';
import SoldCapacity from './SoldCapacity/SoldCapacity';

const DemandResponse = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [queryParamsData] = useState({
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        dateRange: queryParams.get('dateRange') ? JSON.parse(queryParams.get('dateRange')) : '',
        direction: queryParams.get('direction') ? JSON.parse(queryParams.get('direction')) : '',
        deltaLoad: queryParams.get('deltaLoad') ? JSON.parse(queryParams.get('deltaLoad')) : '',
        duration: queryParams.get('duration') ? Number(queryParams.get('duration')) : '',
        signal: queryParams.get('signal') ? JSON.parse(queryParams.get('signal')) : '',
        priceType: queryParams.get('priceType') ? JSON.parse(queryParams.get('priceType')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        startTime: queryParams.get('startTime') ? queryParams.get('startTime') : '',
        endTime: queryParams.get('endTime') ? queryParams.get('endTime') : '',
        frequency: queryParams.get('frequency') ? JSON.parse(queryParams.get('frequency')) : '',
        license: queryParams.get('license') ? JSON.parse(queryParams.get('license')) : '',
        filter: queryParams.get('filter') ? JSON.parse(queryParams.get('filter')) : '',
    });

    const [startLoader, stopLoader] = useLoader();
    const [licenses, setLicenses] = useState([]);
    const [market, setMarket] = useState(location.pathname.split('/')[2]);
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);

    useEffect(() => {
        const market = location.pathname.split('/')[2];
        setMarket(market);
        if (selectedIndex === 3 && !['FFR', 'FCR-D', 'FCR-D-INC', 'FCR'].includes(market)) setSelectedIndex(0);
    }, [location.pathname]);

    useEffect(() => {
        FrequencyService.getMarketEnabledGroups(market, startLoader, handleSuccess, handleError, stopLoader);
    }, [market]);

    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            queryParamsNew = new URLSearchParams();
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };

    const handleSuccess = ({ data }) => {
        const res = data?.data?.licenses;
        if (res) setLicenses(res);
        else {
            setLicenses([]);
        }
    };

    const handleError = (err) => {
        console.log(err);
    };

    let tabs = [
        {
            name: 'Events',
            component: <Events market={market} licenses={licenses} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
        {
            name: 'Market',
            component: <ChartContainer market={market} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
        {
            name: 'Frequency',
            component: <Live updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
        ...(['FFR', 'FCR-D-INC', 'FCR-D-DEC', 'FCR'].includes(market)
            ? [
                  {
                      name: 'Request Bid',
                      component: <RequestBid market={market} licensesData={licenses} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
                  },
              ]
            : []),
        {
            name: 'Sold Capacity (kW)',
            component: <SoldCapacity market={market} licenses={licenses} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                tabs={tabs}
                selectedIndex={selectedIndex}
                onChangeTab={(index) => {
                    updateQueryParams('tab', index);
                    setSelectedIndex(index);
                }}
            />
        </div>
    );
};

export default DemandResponse;
