import React, { useContext, useEffect, useState } from 'react';
import { NordpoolService } from '../../../services/NordpoolService';
import moment from 'moment-timezone';
import { LoaderContext } from '../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import ChartWithDateSelector from '../../../components/ApexCharts/ChartWithDateSelector';
import { timeOnly } from '../../../utils/dateHelper';
import { DropdownComponent } from '../../../components/Inputs/Input';

// Css imports
import classes from '../DemandResponse.module.css';

const ChartContainer = ({ market, updateQueryParams, queryParamsData }) => {
    const [graphData, setGraphData] = useState([]);
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : moment().subtract(1, 'day').format());
    const [lastStatus, setLastStatus] = useState('--');
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    const SIGNAL_OPTIONS = [
        ...(market === 'FCR-D-INC'
            ? [
                  {
                      label: 'FCR-D Increased DK2',
                      value: 'FCR_D_INCREASED_DK2',
                  },
                  {
                      label: 'FCR-D Increased SE1',
                      value: 'FCR_D_INCREASED_SE1',
                  },
                  {
                      label: 'FCR-D Increased SE2',
                      value: 'FCR_D_INCREASED_SE2',
                  },
                  {
                      label: 'FCR-D Increased SE3',
                      value: 'FCR_D_INCREASED_SE3',
                  },
                  {
                      label: 'FCR-D Increased SE4',
                      value: 'FCR_D_INCREASED_SE4',
                  },
              ]
            : []),
        ...(market === 'FCR-D-DEC' || market === 'FCR-D-'
            ? [
                  {
                      label: 'FCR-D Decreased DK2',
                      value: 'FCR_D_DECREASED_DK2',
                  },
                  {
                      label: 'FCR-D Decreased SE1',
                      value: 'FCR_D_DECREASED_SE1',
                  },
                  {
                      label: 'FCR-D Decreased SE2',
                      value: 'FCR_D_DECREASED_SE2',
                  },
                  {
                      label: 'FCR-D Decreased SE3',
                      value: 'FCR_D_DECREASED_SE3',
                  },
                  {
                      label: 'FCR-D Decreased SE4',
                      value: 'FCR_D_DECREASED_SE4',
                  },
              ]
            : []),
        ...(market === 'FCR'
            ? [
                  {
                      label: 'FCR DK1',
                      value: 'FCR_DK1',
                  },
              ]
            : []),
        ...(market === 'aFRR'
            ? [
                  {
                      label: 'AFRR DECREASED DK2',
                      value: 'AFRR_DECREASED_DK2',
                  },
                  {
                      label: 'AFRR INCREASED DK2',
                      value: 'AFRR_INCREASED_DK2',
                  },
              ]
            : []),
        ...(market === 'mFRR'
            ? [
                  {
                      label: 'MFRR INCREASED DK1',
                      value: 'MFRR_INCREASED_DK1',
                  },
                  {
                      label: 'MFRR DECREASED DK1',
                      value: 'MFRR_DECREASED_DK1',
                  },
                  {
                      label: 'MFRR INCREASED DK2',
                      value: 'MFRR_INCREASED_DK2',
                  },
                  {
                      label: 'MFRR DECREASED DK2',
                      value: 'MFRR_DECREASED_DK2',
                  },
              ]
            : []),
    ];
    const PRICE_TYPE_OPTIONS = [
        {
            label: 'Total',
            value: 'total',
        },
        {
            label: 'D-1',
            value: 'D-1',
        },
        {
            label: 'D-2',
            value: 'D-2',
        },
    ];
    const [selectedSignal, setSelectedSignal] = useState(queryParamsData.signal ? queryParamsData.signal : SIGNAL_OPTIONS?.length ? SIGNAL_OPTIONS[0] : {});
    const [selectedPriceType, setSelectedPriceType] = useState(queryParamsData.priceType ? queryParamsData.priceType : PRICE_TYPE_OPTIONS[0]);
    useEffect(() => {
        if (selectedSignal?.value) {
            let processedDate = moment(date).tz('Europe/Berlin').format('YYYY-MM-DD');
            let signal = {
                signal: selectedSignal?.value,
            };
            NordpoolService.getPricing(
                signal.signal,
                market === 'FCR-D-INC' || 'FCR-D-DEC' || 'FCR-D-' ? selectedPriceType.value : 'total',
                processedDate,
                () => startLoader('getData'),
                handleGetDataSuccess,
                handleError,
                () => stopLoader('getData')
            );

            NordpoolService.lastReported(
                signal,
                () => startLoader('getData'),
                handleLastReportedSuccess,
                handleError,
                () => stopLoader('getData')
            );
        }
    }, [date, selectedSignal, selectedPriceType]);

    const handleGetDataSuccess = ({ data }) => {
        setGraphData(data.data.graph);
    };
    const handleLastReportedSuccess = ({ data }) => {
        const ndate = moment(data.data.price.start_time).tz('Europe/Berlin').format('YYYY-MM-DD');
        setLastStatus(ndate);
    };

    const handleError = (err) => console.log(err);

    return (
        <div>
            {SIGNAL_OPTIONS?.length > 0 && (
                <div className={classes.FilterContainer} style={{ justifyContent: 'flex-start', gap: '1vw', padding: '0.5vw 1vw 1vw' }}>
                    <div className={classes.FieldControl} style={{ margin: '0' }}>
                        <label for="signal">Signal</label>
                        <DropdownComponent
                            defaultValue={queryParamsData.signal ? queryParamsData.signal : SIGNAL_OPTIONS[0]}
                            options={SIGNAL_OPTIONS}
                            onChange={(e) => {
                                setSelectedSignal(e);
                                updateQueryParams('signal', JSON.stringify(e));
                            }}
                        />
                    </div>
                    {(market === 'FCR-D-INC' || market === 'FCR-D-DEC' || market === 'FCR-D-') && (
                        <div className={classes.FieldControl} style={{ margin: '0' }}>
                            <label for="signal">Type</label>
                            <DropdownComponent
                                defaultValue={selectedPriceType}
                                options={PRICE_TYPE_OPTIONS}
                                onChange={(e) => {
                                    setSelectedPriceType(e);
                                    updateQueryParams('priceType', JSON.stringify(e));
                                }}
                            />
                        </div>
                    )}
                </div>
            )}

            <div className={classes.BottomContainer}>
                <ChartWithDateSelector
                    series={graphData}
                    title={`${market} Pricing (DKK/MW)`}
                    xFormatter={timeOnly}
                    type="line"
                    date={date}
                    setDate={setDate}
                    curve="stepline"
                    lastReported={lastStatus}
                />
            </div>
        </div>
    );
};

export default ChartContainer;
