// Standard library imports
import React, { useState } from 'react';
// External library imports
import { Calendar } from 'react-date-range';
import moment from 'moment';
import { UilSchedule } from '@iconscout/react-unicons';

// Internal module imports
import ModalComponent from '../../ModalComponent/ModalComponent';

// Css imports
import classes from './DatePicker.module.css';

const DatePicker = ({ date, setDate, disabled, showArrow = true, size, ...props }) => {
    const [showCalender, setShowCalender] = useState(false);
    const handleSelect = (date) => {
        const currentDate = new Date();
        date.setHours(currentDate.getHours());
        date.setMinutes(currentDate.getMinutes());
        date.setSeconds(currentDate.getSeconds());
        setDate(date);
        setShowCalender(false);
    };
    const handleBackClick = () => {
        const previousDay = new Date(date);
        previousDay.setDate(previousDay.getDate() - 1);
        setDate(previousDay);
    };

    const handleNextClick = () => {
        const nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);
        setDate(nextDay);
    };
    return (
        <div>
            <div className={classes.DatePickerContainer} style={disabled ? { opacity: '0.5' } : {}}>
                {showArrow && (
                    <div className={`${classes.ArrowButton} ${size === 'large' ? classes.Large : ''}`} onClick={() => !disabled && handleBackClick()}>
                        {'<'}
                    </div>
                )}
                <div className={`${classes.DateContainer} ${size === 'large' ? classes.Large : ''}`} onClick={() => !disabled && setShowCalender(!showCalender)}>
                    <div className={classes.Date}>{date && moment(date).format('MMMM DD')}</div>
                    <div className={classes.CalendarIcon}>
                        <UilSchedule style={{ color: 'var(--color-primary)' }} size={'1.042vw'} />
                    </div>
                </div>
                {showArrow && (
                    <div className={`${classes.ArrowButton} ${size === 'large' ? classes.Large : ''}`} onClick={() => !disabled && handleNextClick()}>
                        {'>'}
                    </div>
                )}
            </div>

            {showCalender && (
                <ModalComponent isOpen={showCalender} setOpen={setShowCalender}>
                    <Calendar className={classes.CalendarPopUp} date={new Date(date)} onChange={handleSelect} autoFocus={false} color="var(--color-primary)" fixedHeight={true} />
                </ModalComponent>
            )}
        </div>
    );
};

export default DatePicker;
