// Standard library imports
import React, { useContext, useState } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import TabsComponent from '../../components/Tabs/Tabs';
import ChartContainer from './Tabs/ChartContainer';
import { LoaderContext } from '../../context/LoaderContext';
import { SET_USER, START_LOADER, STOP_LOADER, USER_ROLE } from '../../constants';
import { AuthContext } from '../../context/AuthContext';
import { get as LodashGet } from 'lodash';

// Css imports
import classes from './RealtimeMarket.module.css';

const RealtimeMarket = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [queryParamsData] = useState({
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        priceSignal: queryParams.get('priceSignal') ? queryParams.get('priceSignal') : '',
    });

    const [open, setOpen] = useState(false);
    const { state, dispatch: authDispatch } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');

    const [initialValues, setInitialValues] = useState(LodashGet(state, 'user.customerId.Taxes.powerTax') || {});
    const [refresh, setRefresh] = useState('');
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const dispatchSetUser = (payload) => authDispatch({ type: SET_USER, payload });

    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            queryParamsNew = new URLSearchParams();
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };

    let tabs = [
        {
            name: 'Details',
            component: <ChartContainer refresh={refresh} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent tabs={tabs} modal={false} isOpen={open} setOpen={setOpen} />
        </div>
    );
};

export default RealtimeMarket;
