import { Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import myclasses from '../../styles/AllDevices.module.css';
import { Input, TextArea, ToggleButton } from '../../components/Inputs/Input';
import DatePicker from '../../components/DatePicker/DatePicker';
import Typography from '../../components/Typography/Typography';
import classes from './SupportNotes.module.css';
import { SupportNotesService } from '../../services/SupportNotesService';
import { toast } from 'react-toastify';

function SupportNoteForm({ setOpen, data, startLoader, stopLoader, setEditData, fetchNotes }) {
    const [initialValues, setinitialValues] = useState({
        title: '',
        description: '',
        released: new Date().toISOString().split('T')[0],
        enable: true,
    });

    useEffect(() => {
        if (data?._id) {
            setinitialValues({
                ...data,
                released: data?.released.split('T')[0],
            });
        }
    }, [data]);

    const [date, setDate] = useState(new Date());
    const handleSubmit = (val) => {
        let payload = {
            supportNote: {
                ...val,
                released: new Date(val?.released),
            },
        };
        if (data?._id) {
            SupportNotesService.updateSupportNotes(
                data?._id,
                payload,
                () => startLoader('getVersions'),
                handleSuccess,
                handleError,
                () => stopLoader('getVersions')
            );
        } else {
            SupportNotesService.createSupportNotes(
                payload,
                () => startLoader('getVersions'),
                handleCreateSuccess,
                handleError,
                () => stopLoader('getVersions')
            );
        }
    };
    const handleCreateSuccess = ({ data }) => {
        setOpen(false);
        toast.success('The team will reach out to you in 24 hours.');
        fetchNotes();
    };
    const handleSuccess = ({ data }) => {
        setOpen(false);
        setEditData({});
        fetchNotes();
    };
    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };
    return (
        <div className={classes.SupportNoteForm}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                // validationSchema={ }
                onSubmit={(val) => {
                    handleSubmit(val);
                }}
            >
                {({ errors, touched, values, isValidating, ...props }) => (
                    <Form>
                        <div>
                            <Typography content="Support Note" />
                            <div>
                                <div>
                                    <div className={myclasses.FieldControl}>
                                        <label for="title">
                                            Title <span className="required">*</span>
                                        </label>
                                        <Input
                                            name="title"
                                            id="title"
                                            setFieldValue={props.setFieldValue}
                                            setFieldTouched={props.setFieldTouched}
                                            getFieldMeta={props.getFieldMeta}
                                        />
                                    </div>
                                </div>
                                <div></div>
                                <div className={myclasses.FieldControl}>
                                    <label for="released">
                                        Created At <span className="required">*</span>
                                    </label>
                                    <Input
                                        type="date"
                                        name="released"
                                        id="released"
                                        setFieldValue={props.setFieldValue}
                                        setFieldTouched={props.setFieldTouched}
                                        getFieldMeta={props.getFieldMeta}
                                    />
                                </div>
                                <div>
                                    <div className={myclasses.FieldControl}>
                                        <label for="description">
                                            Description <span className="required">*</span>
                                        </label>
                                        <TextArea
                                            name="description"
                                            label="description"
                                            setFieldValue={props.setFieldValue}
                                            setFieldTouched={props.setFieldTouched}
                                            getFieldMeta={props.getFieldMeta}
                                        />
                                    </div>
                                </div>
                                <div className={myclasses.ButtonContainer}>
                                    <div>
                                        <button
                                            className="btn-secondary"
                                            onClick={() => {
                                                setOpen(false);
                                                setEditData({});
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div>
                                        <button className="btn-primary">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default SupportNoteForm;
