// Standard library imports
import React from 'react';
// Internal module imports
import NoData from '../../assets/noData.png';
import classes from './index.module.css';

const NoDataComponent = () => {
    return (
        <div className={classes.MainContainer}>
            <div className={classes.NoData}>
                <div>
                    <img src={NoData} alt="" />
                </div>
                <div>No Records To Display</div>
            </div>
        </div>
    );
};

export default NoDataComponent;
