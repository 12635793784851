// Standard library imports
import React, { useContext, useEffect, useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import { meanBy as LodashMean, minBy as LodashMin, maxBy as LodashMax } from 'lodash';
import { UilEnvelopeAlt, UilInfoCircle } from '@iconscout/react-unicons';
import { flatten } from 'flat';
// Internal module imports
import TableWithHeading from '../../../components/TableWithHeading/TableWithHeading';
import { Input } from '../../../components/Inputs/Input';
import { FREQUENCY_DIRECTION, PERCENTAGE_DROPDOWN, START_LOADER, STOP_LOADER } from '../../../constants';
import { FrequencyService } from '../../../services/FrequencyService';
import { LoaderContext } from '../../../context/LoaderContext';
import DatePicker from '../../../components/DatePicker/DatePicker';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import GroupsList from '../PowerCapacity/GroupsList';
import { Capitalize } from '../../../utils/stringHelper';
import { getCETDate, momentTimeFormater } from '../../../utils/dateHelper';
import CommandLog from '../../../../src/assets/commandLog.svg';
import dataLog from '../../../../src/assets/dataLog.svg';
import AddEmails from './ModalComponent/SubscribedEmails';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import Dropdown from '../../../components/Inputs/Dropdown';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import DateRangePicker from '../../../components/Inputs/DateRangePicker/DateRangePicker';
import { truncateNumber } from '../../../utils/numberHelpers';
import DataLogModel from './ModalComponent/DataLogModel';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';

// Css imports
import DRClasses from '../DemandResponse.module.css';
import classes from '../../../styles/Events.module.css';

const Events = ({ market, licenses, updateQueryParams, queryParamsData }) => {
    const [emailModal, setEmailModal] = useState(false);
    let [filter, setFilter] = useState({
        deltaLoad: queryParamsData.deltaLoad ? queryParamsData.deltaLoad.value : 0,
        duration: queryParamsData.duration ? queryParamsData.duration : 25,
        direction: queryParamsData.direction ? queryParamsData.direction.value : '',
    });

    const [dateRange, setDateRange] = useState({
        startDate: queryParamsData.dateRange ? new Date(queryParamsData.dateRange.startDate) : new Date(),
        endDate: queryParamsData.dateRange ? new Date(queryParamsData.dateRange.endDate) : new Date(),
    });

    const [events, setEvents] = useState([]);

    const [durationData, setDurationData] = useState({
        min: '',
        max: '',
        avg: 0,
    });

    const [group, setGroup] = useState({});
    const [downloadable, setDownloadable] = useState([]);

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const [dataLogModal, setDataLogModal] = useState({ status: false, meta: {}, id: null });

    useEffect(() => {
        if (queryParamsData?.direction) {
            const data = {
                direction: queryParamsData.direction ? queryParamsData.direction.value : '',
                ...filter,
            };
            if (queryParamsData.direction.value === 'All') {
                data.direction = '';
            }
            setFilter(data);
        }
    }, [queryParamsData?.direction]);

    useEffect(() => {
        if (group._id) {
            fetchEvents();
        }
    }, [group, market]);

    useEffect(() => {
        for (let item of licenses) {
            if (item.groups?.length) {
                setGroup(item.groups[0]);
                break;
            }
        }
    }, [JSON.stringify(licenses)]);

    const fetchEvents = () => {
        FrequencyService.getAllEvents(
            { ...filter, type: market, group: group._id },
            dateRange,
            () => startLoader('getAllEvents'),
            handleEventsSuccess,
            handleError,
            () => stopLoader('getAllEvents')
        );
    };

    const calculateConsumption = (dataLog = [], duration = 0) => {
        let totalPower = 0;
        let count = dataLog.length;
        let consumption = '--';
        dataLog.map((item) => {
            totalPower += item.power || 0;
        });
        const avgPower = totalPower / count;
        if (avgPower > 0) {
            consumption = avgPower * (duration / 3600);
        }

        return isNaN(consumption) ? (
            consumption
        ) : (
            <span className={DRClasses.ConsumptionInfo}>
                <span style={{ marginBottom: '0.4vh' }}>{truncateNumber(consumption, 3).toLocaleString('da-DK')}</span>
                <CustomTooltip content={'Estimated Consumption'}>
                    <UilInfoCircle
                        size="1.2vw"
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </CustomTooltip>
            </span>
        );
    };

    const handleEventsSuccess = (res) => {
        let eventsData = res.data.data;
        const downloadableData = [];

        let processedEvents = eventsData.map((eventdata) => {
            // data for download
            const data = { ...eventdata };
            delete data.dataLog;
            delete data.commandLog;
            downloadableData.push(data);

            // data for table
            return {
                ...eventdata,
                deltaLoad: eventdata.deltaLoad,
                fmaxFrequency: truncateNumber(eventdata.maxFrequency, 3).toLocaleString('da-DK'),
                fconsumption: isNaN(eventdata.consumption)
                    ? calculateConsumption(eventdata.dataLog, eventdata.difference)
                    : truncateNumber(eventdata.consumption, 3).toLocaleString('da-DK'),
                direction: Capitalize(eventdata.direction),
                processedDeltaLoad:
                    eventdata.direction === 'increased' ? (
                        <div style={{ color: 'darkgreen' }}>{eventdata.deltaLoad.toLocaleString('da-DK')}</div>
                    ) : (
                        <div style={{ color: 'red' }}> {eventdata.deltaLoad.toLocaleString('da-DK')}</div>
                    ),
                download: (
                    <div className={classes.Action} onClick={() => handleDownload(eventdata._id)}>
                        Download
                    </div>
                ),
                action: (
                    <span style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <img
                            src={CommandLog}
                            onClick={() => {
                                let commandLogExcelData = eventdata.commandLog;
                                commandLogExcelData = commandLogExcelData.map((item) => ({
                                    command: item.command,
                                    deviceId: item.deviceId,
                                    timeStamp: item.timestamp
                                        ? getCETDate(momentTimeFormater(item.timestamp))
                                        : getCETDate(momentTimeFormater(item.timeStamp?.replace(' ', 'T') + 'Z')),
                                }));
                                if (commandLogExcelData.length) {
                                    const commandLogColumnNames = ['COMMAND', 'DEVICEID', 'TIMESTAMP (CET)'];
                                    DownloadAsExcel(commandLogExcelData, `${group.name} CommandLog - ${eventdata.startTime}`, commandLogColumnNames);
                                }
                            }}
                            style={{ height: '3vh' }}
                            alt=""
                            title="Command Log"
                        />
                        <br></br>
                        <img
                            src={dataLog}
                            onClick={() =>
                                setDataLogModal({
                                    status: true,
                                    id: eventdata._id,
                                    meta: { groupName: group.name, startTime: eventdata.startTime },
                                })
                            }
                            style={{ height: '3vh' }}
                            alt=""
                            title="Data Log"
                        />
                    </span>
                ),
            };
        });
        setEvents(processedEvents);
        setDownloadable(downloadableData);
        setDurationData({
            max: LodashMax(eventsData, 'difference'),
            min: LodashMin(eventsData, 'difference'),
            avg: LodashMean(eventsData, 'difference'),
        });
    };

    const handleDownload = (id) => {
        FrequencyService.exportEvent(
            id,
            () => startLoader('exportEvent'),
            handleExportSuccess,
            handleError,
            () => stopLoader('exportEvent')
        );
    };

    const handleExportSuccess = ({ data }) => {
        const excelData = data.data;
        if (excelData) {
            DownloadAsExcel(excelData, 'Event', ['Timestamp (CET)', 'Max Frequency (Hz)', 'Power (kW)']);
        }
    };

    const handleExportAllEvents = () => {
        let columnIndex = 0;
        let columnLength = 0;
        const flattenedData = downloadable
            .map((item) => flatten(item, { delimiter: '_' }))
            .map((item, index) => {
                const objectLength = Object.keys(item).length;
                if (objectLength > columnLength) {
                    columnLength = objectLength;
                    columnIndex = index;
                }
                return item;
            });

        const columnNames = [];
        for (let key in flattenedData[columnIndex]) {
            columnNames.push(key.toUpperCase());
        }
        if (flattenedData.length) {
            DownloadAsExcel(flattenedData, `AllEvents_${group?.name}`, columnNames, columnIndex);
        }
    };

    const handleSubmit = (val) => {
        let values = val.direction === 'All' ? { ...val, direction: '' } : { ...val };
        updateQueryParams('duration', values.duration);
        setFilter(values);
        FrequencyService.getAllEvents(
            { ...values, type: market, group: group._id },
            dateRange,
            () => startLoader('getAllEvents'),
            handleEventsSuccess,
            handleError,
            () => stopLoader('getAllEvents')
        );
    };

    const handleError = (err) => {
        console.log(err);
    };

    const closeDataLogModel = () => {
        setDataLogModal({ status: false, meta: {}, id: null });
    };

    let minutes = Math.floor(durationData.avg / 60);
    let seconds = durationData.avg - minutes * 60;

    return (
        <div>
            <div className={classes.Events + ` ${DRClasses.FilterWarapper}`}>
                <div className={classes.FilterContainer + ' frequency-filters'}>
                    <div>
                        <Formik initialValues={filter} onSubmit={handleSubmit}>
                            {({ errors, touched, values, isValidating, ...props }) => (
                                <Form>
                                    <div className={classes.InputContainer}>
                                        <div>
                                            <div className={classes.FieldControl}>
                                                <label for="direction">Direction</label>
                                                <Dropdown
                                                    options={FREQUENCY_DIRECTION}
                                                    defaultValue={queryParamsData.direction ? queryParamsData.direction : FREQUENCY_DIRECTION[0]}
                                                    name="direction"
                                                    label="direction"
                                                    onChange={(value) => updateQueryParams('direction', JSON.stringify(value))}
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <div className={classes.FieldControl}>
                                                <label for="deltaLoad">Percentage</label>
                                                <Dropdown
                                                    name="deltaLoad"
                                                    label="deltaLoad"
                                                    options={PERCENTAGE_DROPDOWN}
                                                    defaultValue={PERCENTAGE_DROPDOWN[0]}
                                                    onChange={(value) => updateQueryParams('deltaLoad', JSON.stringify(value))}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className={classes.FieldControl}>
                                                <label for="duration">Duration</label>
                                                <Input name="duration" />
                                            </div>
                                        </div>
                                        <div className={classes.DatePickers}>
                                            <div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className={classes.DateLabels}>Start Date</div>
                                                    <div className={classes.DateLabels} style={{ marginRight: '2.604vw' }}>
                                                        End Date
                                                    </div>
                                                </div>
                                                <DateRangePicker
                                                    startDate={dateRange.startDate}
                                                    endDate={dateRange.endDate}
                                                    onChange={(date) => {
                                                        const data = { startDate: new Date(date.startDate), endDate: new Date(date.endDate) };
                                                        setDateRange(data);
                                                        updateQueryParams('dateRange', JSON.stringify(data));
                                                    }}
                                                />
                                            </div>
                                            <div className={classes.SubmitButton}>
                                                <button className="btn-primary">Submit</button>
                                            </div>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    left: '26.031vw',
                                                    top: '-2px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gridColumnGap: '0.7vw',
                                                }}
                                            >
                                                <DownloadButton size="medium" onClick={() => handleExportAllEvents()} />
                                                <UilEnvelopeAlt size="1.9vw" style={{ color: 'var(--color-primary)', cursor: 'pointer' }} onClick={() => setEmailModal(true)} />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>

            <div style={{ marginTop: '1vw', display: 'flex', gridColumnGap: '1vw' }}>
                {/* <div className={classes.Events} style={{ borderRadius: '0.7vw', width: '15vw' }}> */}
                <GroupsList licenses={licenses} setGroup={setGroup} />
                {/* </div> */}
                <div className={classes.Events} style={{ borderRadius: '0.7vw', flexGrow: 1 }}>
                    <div className={classes.Duration} style={{ width: 'auto' }}>
                        <div className={classes.DurationContent}>
                            <div>
                                Min Duration: <span className="black">{durationData.min !== undefined ? durationData.min.duration : '--'}</span>
                            </div>
                            <div>
                                Average Duration: <span className="black">{durationData.avg ? `${minutes} Minutes and ${seconds.toFixed(0)} Seconds` : '--'}</span>
                            </div>
                            <div>
                                Max Duration: <span className="black"> {durationData.max !== undefined ? durationData.max.duration : '--'}</span>
                            </div>
                        </div>
                    </div>
                    <div className={DRClasses.DeviceDashboard + ` ${DRClasses.TableFirstChild}`}>
                        {/* download to be added*/}
                        <TableWithHeading
                            heading={`Frequency Events (${group.name})`}
                            head={['Direction', 'Start Time (CET)', 'Delta Load (%)', 'Max Frequency (Hz)', 'Duration', 'Consumption (kWh)', 'Action']}
                            data={events}
                            keys={['direction', 'startTime', 'processedDeltaLoad', 'fmaxFrequency', 'duration', 'fconsumption', 'action']}
                            count={events.length}
                        />
                        {/* </div> */}
                    </div>
                </div>
            </div>
            <ModalComponent isOpen={emailModal} setOpen={setEmailModal}>
                <div style={{ width: '41vw' }}>
                    <AddEmails licenses={licenses} market={market} />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={dataLogModal.status} setOpen={closeDataLogModel}>
                <DataLogModel setOpen={closeDataLogModel} dataLogModal={dataLogModal} market={market} />
            </ModalComponent>
        </div>
    );
};

export default Events;
