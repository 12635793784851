import React, { useState } from 'react';
import classes from '../../../styles/DeviceList.module.css';
import Typography from '../../../components/Typography/Typography';
import CollapsibleWithRename from '../../../components/CollapsibleWithRename/CollapsibleWithRename';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { Form, Formik } from 'formik';

const DeviceList = ({ groups, setDevice, setEditGroup, handleDeleteGroup, editGroup, setCreateGroupModal, createGroupModal }) => {
    const [collapsibleOpen, setCollapsibleOpen] = useState('');

    const handleCloseModal = () => {
        setEditGroup('');
        setCreateGroupModal(false);
    };

    return (
        <div className={classes.Details}>
            <div>
                <Typography content="Meters" />
            </div>
            {groups &&
                groups.map((group) => (
                    <CollapsibleWithRename
                        group={group}
                        setCreateGroupModal={setCreateGroupModal}
                        open={collapsibleOpen}
                        setOpen={setCollapsibleOpen}
                        setEditGroup={setEditGroup}
                        source="dataHub"
                        // disableDoubleClick
                        setDevice={setDevice}
                        // deviceIcon={CoolingIcon}
                        // tempName={true}
                        openFirstGroup={true}
                        setFirstDevice={false}
                    />
                ))}
            <ModalComponent isOpen={createGroupModal} setOpen={setCreateGroupModal} onClose={() => handleCloseModal()}>
                <div>
                    <Typography content={!editGroup ? 'Delete Group' : 'Delete Group'} />
                </div>
                <Formik onSubmit={() => {}}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div className={classes.ButtonContainer}>
                                {editGroup && (
                                    <div>
                                        <button type="button" className="btn-secondary btn-delete" onClick={() => handleDeleteGroup(editGroup)}>
                                            Delete Group
                                        </button>
                                    </div>
                                )}
                                <div>
                                    <button className="btn-secondary" onClick={() => handleCloseModal()}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalComponent>
        </div>
    );
};

export default DeviceList;
