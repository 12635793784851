import React, { useContext, useEffect, useState } from 'react';
import { get as lodashGet } from 'lodash';
import moment from 'moment-timezone';
import ChartComponent from '../../../components/ApexCharts/Chart';
import { momentTimeFormater, timeOnly } from '../../../utils/dateHelper';
import classes from '../Home.module.css';
import { AuthContext } from '../../../context/AuthContext';
import { useLoader } from '../../../hooks/use-loader.hook';
import { FrequencyService } from '../../../services/FrequencyService';
import { usToEuCurrencyFormat } from '../../../utils/currencyHelper';

const getFrequencySignalData = (customer, value) => {
    const { frequency = [] } = customer;
    const data = frequency.find((f) => f._id === value);
    return data;
};

const FrequencyCard = () => {
    const { state } = useContext(AuthContext);
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const e3DashboardConfig = lodashGet(state, 'user.customerId.e3DashboardConfig');
    const frequencyGraphProperties = lodashGet(state, 'user.customerId.properties');
    const frequencySignalData = getFrequencySignalData(lodashGet(state, 'user.customerId'), e3DashboardConfig.r1c1.value);
    const frequencyAnnotations = {
        yaxis: [
            {
                y: frequencyGraphProperties?.enableLineMin && frequencyGraphProperties.lineMin,
                borderColor: '#219e4d',
                label: {
                    borderColor: '#219e4d',
                    style: {
                        color: '#fff',
                        background: '#219e4d',
                        fontSize: '0.8vw',
                    },
                    text: String(frequencyGraphProperties.lineMin),
                },
            },
            {
                y: frequencyGraphProperties?.enableLineMax && frequencyGraphProperties.lineMax,
                borderColor: '#775DD0',
                label: {
                    borderColor: '#775DD0',
                    style: {
                        color: '#fff',
                        background: '#775DD0',
                        fontSize: '0.8vw',
                    },
                    text: String(frequencyGraphProperties.lineMax),
                },
            },
        ],
    };

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        if (e3DashboardConfig?.r1c1?.value) {
            const curCetDateTime = moment().tz('Europe/Berlin');
            const params = {
                date: momentTimeFormater(curCetDateTime).format('YYYY-MM-DD'),
                end: curCetDateTime.format('HH:mm'),
                start: curCetDateTime.subtract(30, 'minutes').format('HH:mm'),
            };
            FrequencyService.getFrequencyData(e3DashboardConfig.r1c1.value, params, startLoader, handleSuccess, handleError, stopLoader);
        }
    };

    const handleSuccess = ({ data }) => setData(data.data.graph);

    const handleError = (error) => {
        console.error(error);
    };

    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };

    return (
        <div className={classes.TopGraph} style={{ flexGrow: 2 }}>
            <div className={classes.FilterDropdown + ' filter-dropdown'}>
                {frequencySignalData && <strong style={{ marginTop: '0.45rem', marginRight: '0.6rem' }}>{frequencySignalData.name}</strong>}
            </div>
            <ChartComponent
                yProps={{
                    min: frequencyGraphProperties?.lowVisible,
                    max: frequencyGraphProperties?.highVisible,
                    tickAmount: 4,
                }}
                annotations={frequencyAnnotations}
                series={data}
                strokeWidth={2}
                colors={['#40D3E6']}
                title="Frequency (Hz)"
                type="line"
                xFormatter={timeOnly}
                // linkTitle={{
                //   name: 'Frequency (Hz)',
                //   link: '/demandResponse/fcr'
                // }}
                yFormatter={formatYAxisValue}
            />
        </div>
    );
};

export default FrequencyCard;
