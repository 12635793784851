import React, { useEffect, useState, useContext } from 'react';
import classes from './SoldCapacity.module.css';
import GroupsList from '../PowerCapacity/GroupsList';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import ChartComponent from '../../../components/ApexCharts/Chart';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { timeOnly } from '../../../utils/dateHelper';
import moment from 'moment';
import { BidMarketService } from '../../../services/BidMarketService';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import { LoaderContext } from '../../../context/LoaderContext';
import { InstanceDataLogsService } from '../../../services/InstanceDataLogsService';
import Typography from '../../../components/Typography/Typography';

const today = new Date();

const SoldCapacity = ({ market, licenses, updateQueryParams, queryParamsData }) => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [group, setGroup] = useState({});
    const [lastStatus, setLastStatus] = useState('--');
    const [soldCapacity, setSoldCapacity] = useState([]);
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : today);

    useEffect(() => {
        if (group?._id) {
            InstanceDataLogsService.LastReported(
                { groupId: group._id },
                () => startLoader('getSoldCapacityGraphLastReported'),
                handleGetLastReportedSuccess,
                handleError,
                () => stopLoader('getSoldCapacityGraphLastReported')
            );
        }
    }, [group]);

    const handleGetLastReportedSuccess = ({ data }) => {
        setLastStatus(data?.data?.lastReported);
    };

    useEffect(() => {
        if (market && group?._id) {
            const params = {
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
                market,
                instanceId: group._id,
            };
            BidMarketService.GetInstanceCapacity(
                params,
                () => startLoader(`${group._id}-capacity`),
                handleSuccessSoldCapacity,
                handleError,
                () => stopLoader(`${group._id}-capacity`)
            );
        }
    }, [date, group]);

    useEffect(() => {
        if (licenses?.length && licenses[0].groups.length) {
            setGroup(licenses[0].groups[0]);
        } else {
            setGroup({});
        }
    }, [licenses]);

    const handleError = (err) => {
        console.log(err);
    };

    const handleSuccessSoldCapacity = ({ data }) => {
        const { capacityData = [] } = data?.data;
        if (Array.isArray(capacityData)) {
            setSoldCapacity(capacityData);
        } else {
            setSoldCapacity([]);
        }
    };

    const handleSoldCapacityExport = () => {
        let downloadData = [];
        soldCapacity[0]?.data?.length &&
            soldCapacity[0]?.data?.map((item) => {
                let temp = {
                    date: item.x,
                    power: item.y,
                };
                downloadData.push(temp);
            });

        downloadData?.length && DownloadAsExcel(downloadData, `${group?.name} Capicity Data ${moment(date).tz('Europe/Berlin').format('YYYY-MM-DD')}`, ['Date & Time', 'Capacity']);
    };

    return (
        <div className={classes.DeviceDashboard}>
            <GroupsList licenses={licenses} setGroup={setGroup} />
            <div className={classes.ChartContainer}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <Typography content={`Sold Capacity (${group?.name})`} />
                        <div>
                            <Typography subtext content={'Last Reported: ' + (lastStatus || ' --')} />
                        </div>
                    </div>
                    <div className={classes.Actions}>
                        <DatePicker date={date} setDate={setDate} />
                        <DownloadButton size="medium" onClick={() => handleSoldCapacityExport()} />
                    </div>
                </div>
                <ChartComponent
                    type="line"
                    series={soldCapacity}
                    xFormatter={timeOnly}
                    curve="stepline"
                    xType={'category'}
                    xProps={{ tickAmount: 5 }}
                    xLabelProps={{ rotate: 0 }}
                />
            </div>
        </div>
    );
};

export default SoldCapacity;
