import React from 'react';
import Typography from '../../../../components/Typography/Typography';
import ChartComponent from '../../../../components/ApexCharts/Chart';
import { getCETDate, momentTimeFormater, timeOnly } from '../../../../utils/dateHelper';
import { useLoader } from '../../../../hooks';
import { FrequencyService } from '../../../../services/FrequencyService';
import { useState } from 'react';
import { useEffect } from 'react';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import DownloadButton from '../../../../components/Buttons/DownloadButton';
import { usToEuCurrencyFormat } from '../../../../utils/currencyHelper';

// Css imports
import classes from '../../DemandResponse.module.css';

const DataLogModel = ({ setOpen, dataLogModal, market }) => {
    const [startLoader, stopLoader] = useLoader();
    const [downloadData, setDownloadData] = useState([]);
    const [frequencyData, setFrequencyData] = useState([]);
    const [powerData, setPowerData] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        if (dataLogModal.id && market) {
            FrequencyService.getDataLog(dataLogModal.id, { type: market }, startLoader, handledataLogSuccess, handleError, stopLoader);
        }
    };

    const handledataLogSuccess = ({ data }) => {
        setDownloadData(data.data.downloadable);
        setPowerData(data.data.powerGraph);
        setFrequencyData(data.data.frequencyGraph);
    };

    const handleDownload = () => {
        const dataLogExcelData = downloadData.map((item) => ({
            power: item.power,
            consumption: item.consumption,
            frequency: item.frequency,
            timeStamp: getCETDate(momentTimeFormater(item.timestampUtc)),
            inject: item?.inject ? item?.inject : false,
        }));
        const dataLogColumnNames = ['POWER (kW)', 'CONSUMPTION (Wh)', 'FREQUENCY (Hz)', 'TIMESTAMP (CET)', 'INJECT'];
        DownloadAsExcel(dataLogExcelData, `${dataLogModal.meta.groupName} DataLog - ${dataLogModal.meta.startTime}`, dataLogColumnNames);
    };

    const handleError = (err) => {
        console.log(err);
    };
    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };
    return (
        <div style={{ width: '50vw', maxHeight: '90vh' }}>
            <Typography content={'Data Log'} />
            <div className={classes.ButtonContainer2}>
                <DownloadButton size={'medium'} onClick={handleDownload} />
            </div>
            <div>
                <div>
                    <ChartComponent
                        title="Frequency (Hz)"
                        strokeWidth={2}
                        type="line"
                        colors={['var(--color-primary)']}
                        series={frequencyData}
                        xFormatter={timeOnly}
                        yFormatter={formatYAxisValue}
                        chartProps={{ group: 'dataLog' }}
                    />
                </div>
                <div>
                    <ChartComponent
                        title="Power (kW)"
                        strokeWidth={2}
                        type="line"
                        colors={['var(--color-primary)']}
                        series={powerData}
                        xFormatter={timeOnly}
                        yFormatter={formatYAxisValue}
                        chartProps={{ group: 'dataLog' }}
                    />
                </div>
            </div>
            <div className={classes.ButtonContainer}>
                <div className="btn-secondary" onClick={setOpen}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default DataLogModel;
