// Standard library imports
import React, { useContext, useState, useEffect } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import TabsComponent from '../../components/Tabs/Tabs';
import ChartContainer from './Tabs/ChartContainer';
import { Form, Formik } from 'formik';
import Typography from '../../components/Typography/Typography';
import { Input } from '../../components/Inputs/Input';
import { NordpoolService } from '../../services/NordpoolService';
import { SET_USER, USER_ROLE } from '../../constants';
import { AuthContext } from '../../context/AuthContext';
import { get as LodashGet } from 'lodash';
import { toast } from 'react-toastify';
import { SignalTypesService } from '../../services/SignalTypesService';
import { useLoader } from '../../hooks';
import { camelCase } from 'lodash';
import UpdateTax from './Tabs/UpdateTax';

// Css imports
import classes from './Nordpool.module.css';

const getPowerCost = (customer) => {
    const { powerCost = [] } = customer;
    const options = [{ label: 'None', value: '' }];
    powerCost.forEach((item) => options.push({ label: item.name, value: item._id }));
    return options;
};

const Nordpool = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [queryParamsData] = useState({
        dateRange: queryParams.get('dateRange') ? JSON.parse(queryParams.get('dateRange')) : '',
        noOfDays: queryParams.get('noOfDays') ? JSON.parse(queryParams.get('noOfDays')) : '',
        settingTaxModal: queryParams.get('settingTaxModal') && queryParams.get('settingTaxModal') === 'true' ? Boolean(queryParams.get('settingTaxModal')) : false,

        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        direction: queryParams.get('direction') ? JSON.parse(queryParams.get('direction')) : '',
        deltaLoad: queryParams.get('deltaLoad') ? JSON.parse(queryParams.get('deltaLoad')) : '',
        duration: queryParams.get('duration') ? Number(queryParams.get('duration')) : '',
        signal: queryParams.get('signal') ? JSON.parse(queryParams.get('signal')) : '',
        priceType: queryParams.get('priceType') ? JSON.parse(queryParams.get('priceType')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        startTime: queryParams.get('startTime') ? queryParams.get('startTime') : '',
        endTime: queryParams.get('endTime') ? queryParams.get('endTime') : '',
        frequency: queryParams.get('frequency') ? JSON.parse(queryParams.get('frequency')) : '',
        license: queryParams.get('license') ? JSON.parse(queryParams.get('license')) : '',
        filter: queryParams.get('filter') ? JSON.parse(queryParams.get('filter')) : '',
    });

    const [startLoader, stopLoader] = useLoader();
    const [open, setOpen] = useState(queryParamsData.settingTaxModal ? queryParamsData.settingTaxModal : false);
    const { state, dispatch: authDispatch } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const [PriceDropdown] = useState(getPowerCost(LodashGet(state, 'user.customerId')));
    const [refresh, setRefresh] = useState('');
    const [updateTaxTabs, setUpdateTaxTabs] = useState([]);
    const dispatchSetUser = (payload) => authDispatch({ type: SET_USER, payload });
    const [priceSignalsTypes, setPriceSignalsTypes] = useState([]);

    useEffect(() => updateQueryParams('settingTaxModal', open), [open]);

    useEffect(() => {
        SignalTypesService.readAll(startLoader, handleSignalTypesSuccess, handleError, stopLoader);
    }, []);

    useEffect(() => {
        renderUpdateTaxTabs();
    }, [state.user.customerId.taxes]);

    useEffect(() => {
        renderUpdateTaxTabs();
    }, [JSON.stringify(priceSignalsTypes)]);

    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            queryParamsNew = new URLSearchParams();
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };

    const renderUpdateTaxTabs = () => {
        const curTabs = [];
        priceSignalsTypes.forEach((type) => {
            const key = camelCase(type.name);
            const signals = state.user.customerId[key];
            const taxes = state.user.customerId.taxes;
            if (type.tax && signals.length)
                curTabs.push({
                    name: type.name,
                    component: <UpdateTax signals={signals} taxes={taxes} setOpen={setOpen} handleSubmit={handleSubmit} />,
                });
        });
        setUpdateTaxTabs(curTabs);
    };

    const handleSignalTypesSuccess = ({ data }) => {
        if (data.data) setPriceSignalsTypes(data.data);
    };

    const handleSubmit = (values) => {
        NordpoolService.updateTax(values, startLoader, handleUpdateSuccess, handleError, stopLoader);
    };

    const handleUpdateSuccess = ({ data }) => {
        toast.success('Update success!');
        const user = {
            ...state.user,
            customerId: { ...state.user.customerId, taxes: { ...state.user.customerId.taxes, ...data.data } },
        };
        dispatchSetUser({
            user,
        });
        setRefresh(Math.random * 3);
    };

    const handleError = (err) => toast.error(err?.response?.data?.message);

    let tabs = [
        {
            name: 'Details',
            component: <ChartContainer PriceDropdown={PriceDropdown} refresh={refresh} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
    ];

    const modalComponent = (
        <div>
            <Typography content="Update Tax" />
            <div style={{ marginTop: '0.5vw' }}>
                <TabsComponent tabs={updateTaxTabs} />
            </div>
        </div>
    );

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent tabs={tabs} modal={[USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole)} modalComponent={modalComponent} isOpen={open} setOpen={setOpen} />
        </div>
    );
};

export default Nordpool;
