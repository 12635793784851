import Moment from 'moment';
import { extendMoment } from 'moment-range';
import * as momentTz from 'moment-timezone';

const moment = extendMoment(Moment);

export const momentTimeFormater = (date) => {
    let momentDate = momentTz.tz(date, 'Europe/Berlin');
    return momentDate;
};
// Returns time of given timestamp
export const timeOnly = (date) => {
    return moment(date).format('HH:mm:ss');
};
export const timeOnlyWithms = (date) => {
    return moment(date).format('HH:mm:ss.SSS');
};
export const datetimeOnly = (date) => {
    return moment(date).format('YY-MM-DD-HH:mm:ss');
};
export const datetimeOnlyForGraph = (date) => {
    return moment(date).format('DD-MM-YY-HH:mm:ss');
};
// Returns date in CET Format
export const getCETDate = (date) => {
    return date.tz('Europe/Berlin').format();
};

//Returns an array of hours between the startDate and startDate+1 in HH format
export const getHoursForDay = (startDate) => {
    let processedStartDate = moment(startDate).add('1', 'hours');
    let nextDate = moment(startDate).add('1', 'days');
    const range = moment.range(processedStartDate, nextDate);
    const hours = Array.from(range.by('hour')).map((m) => m.format('HH'));
    return hours;
};

//Will return an array of object
export const splitDatesToMonths = (sDate, eDate) => {
    let startDate = moment(sDate, 'YYYY-M-DD');
    let endDate = moment(eDate, 'YYYY-M-DD').endOf('month');
    let allMonthsInPeriod = [];

    while (startDate.isBefore(endDate)) {
        allMonthsInPeriod.push(startDate.format('YYYY-MM-DD'));
        startDate = startDate.add(1, 'month');
    }

    const processedRanges = allMonthsInPeriod.map((range, i) => {
        let endOfMonth = moment(range).endOf('month');
        return {
            startDate: i === 0 ? moment(sDate).format('YYYY-MM-DD') : moment(range).startOf('month').format('YYYY-MM-DD'),
            endDate: moment(eDate).isBetween(moment(range), moment(endOfMonth)) ? moment(eDate).format('YYYY-MM-DD') : moment(endOfMonth).format('YYYY-MM-DD'),
        };
    });

    return processedRanges;
};

export const enumerateDaysBetweenDates = function (startDate, endDate) {
    let dates = [];

    let currDate = moment(startDate);
    let lastDate = moment(endDate);
    if (dates.length === 0) {
        dates.push(currDate.clone().toDate());
    }
    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().toDate());
    }
    // dates.push(lastDate.clone().toDate());

    return dates;
};
