import { HttpClient } from '../utils/httpClient';

const PATH = {
    getAllVersions: '/client/version',
};

const getVersionHistory = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getAllVersions}`).then(callback).catch(error).finally(next);
};

export const VersionHistoryService = {
    getVersionHistory,
};
