import { HttpClient } from '../utils/httpClient';

const PATH = {
    getRevenue: '/client/revenue-calculator',
};

const getRevenue = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getRevenue}`, { params }).then(callback).catch(error).finally(next);
};

export const RevenueCalculatorService = {
    getRevenue,
};
