import React, { useState } from 'react';
import classes from './Login.module.css';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { UserService } from '../../services/UserService';
import { toast } from 'react-toastify';
import { useLoader } from '../../hooks/use-loader.hook';
import { useEffect } from 'react';
import { ForgetStage2Validation, ForgetState1Validation } from '../../validations/Login/LoginValidation';

const ForgotPassword = ({ setModalState, initialValue }) => {
    const [startLoader, stopLoader] = useLoader();
    const [resetEmail, setResetEmail] = useState('');
    const [stage, setStage] = useState(1);
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
    const [startTimer, setStartTimer] = useState(null);

    useEffect(() => {
        if (startTimer) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [seconds, startTimer]);

    const handleForgotPassword = (val) => {
        setResetEmail(val.email);
        UserService.resetPassword(
            val,
            () => {
                startLoader('reset');
            },
            () => {
                toast.success('OTP sent on your email!');
                setStage(2);
                setStartTimer(Date.now());
            },
            (err) => {
                if (err && err.response) toast.error(err.response.data.message);
            },
            () => {
                stopLoader('reset');
            }
        );
    };

    const handleResendOtp = () => {
        if (minutes === 0 && seconds === 0) {
            UserService.resendOtp(
                { email: resetEmail },
                () => {
                    startLoader('resend');
                },
                () => {
                    toast.success('OTP re-sent on your email!');
                    setMinutes(2);
                    setSeconds(0);
                    setStartTimer(Date.now());
                },
                (err) => {
                    if (err && err.response) toast.error(err.response.data.message);
                },
                () => {
                    stopLoader('resend');
                }
            );
        }
    };

    const handleResetWithOtpOtp = (val) => {
        UserService.resetWithOtp(
            { email: resetEmail, ...val },
            () => {
                startLoader('reset-otp');
            },
            () => {
                toast.success('Passwod reset successfull!');
                handleCloseModal();
            },
            (err) => {
                if (err && err.response) toast.error(err.response.data.message);
            },
            () => {
                stopLoader('reset-otp');
            }
        );
    };

    const handleCloseModal = () => {
        setModalState(false);
        setResetEmail('');
        setStartTimer(null);
    };

    return (
        <div className={classes.forgotPassword}>
            {stage === 1 ? (
                <Formik initialValues={{ email: initialValue }} onSubmit={handleForgotPassword} validationSchema={ForgetState1Validation}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div className={classes.Form}>
                                <div>Forgot Password</div>
                                <div>
                                    <label>Enter your email</label>
                                    <Field type="email" name="email" id="email" placeholder="Email" />
                                    <ErrorMessage name="email" component="div" className="error-msg" />
                                </div>

                                <button className="btn-primary" style={{ fontSize: '0.8vw' }}>
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : null}
            {stage === 2 ? (
                <Formik initialValues={{ otp: '', confirmNewPassword: '', newPassword: '' }} onSubmit={handleResetWithOtpOtp} validationSchema={ForgetStage2Validation}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div className={classes.Form}>
                                <div>Forgot Password</div>
                                <div>
                                    <label>Enter OTP sent on your email</label>
                                    <Field type="number" name="otp" id="otp" placeholder="OTP" />
                                    <ErrorMessage name="otp" component="div" className="error-msg" />
                                    <div className={classes.TimerContainer}>
                                        {seconds > 0 || minutes > 0 ? (
                                            <p className={classes.TimerText}>
                                                Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                            </p>
                                        ) : (
                                            <p className={classes.TimerText}>Didn't recieve code?</p>
                                        )}
                                        <a
                                            className={classes.ResendText}
                                            onClick={handleResendOtp}
                                            style={{ color: seconds > 0 || minutes > 0 ? 'var(--gray-700)' : 'var(--color-primary)' }}
                                        >
                                            Resend OTP
                                        </a>
                                    </div>
                                </div>
                                <div>
                                    <label>Enter New Passowrd</label>
                                    <Field type="password" name="newPassword" id="email" placeholder="New Password" />
                                    <ErrorMessage name="newPassword" component="div" className="error-msg" />
                                </div>
                                <div>
                                    <label>Confirm New Passowrd</label>
                                    <Field type="password" name="confirmNewPassword" id="email" placeholder="Confirm New Password" />
                                    <ErrorMessage name="confirmNewPassword" component="div" className="error-msg" />
                                </div>
                                <button className="btn-primary" style={{ fontSize: '0.8vw' }}>
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </div>
    );
};

export default ForgotPassword;
