// Standard library imports
import React, { useState } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import TabsComponent from '../../components/Tabs/Tabs';
import RevenueCalulator from './RevenueCalulator';

// Css module imports
import classes from './index.module.css';

function RevenuePotentialCalculator() {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [queryParamsData] = useState({
        dateRange: queryParams.get('dateRange') ? JSON.parse(queryParams.get('dateRange')) : '',
        capacity: queryParams.get('capacity') ? queryParams.get('capacity') : '',
        market: queryParams.get('market') ? JSON.parse(queryParams.get('market')) : '',
        area: queryParams.get('area') ? JSON.parse(queryParams.get('area')) : '',
        currency: queryParams.get('currency') ? JSON.parse(queryParams.get('currency')) : '',
    });

    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };

    const tabs = [
        {
            name: 'Revenue Potential Calculator',
            component: <RevenueCalulator updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
    ];
    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent tabs={tabs} />
        </div>
    );
}

export default RevenuePotentialCalculator;
