import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/client/email-subscription',
    read_all: '/client/email-subscription',
    read: '/client/email-subscription',
    update: '/client/email-subscription',
    delete: '/client/email-subscription',
    activationBidResult: '/client/email-subscription/activation-bidResult',
};

//Post call for creating a email subscription
const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

//Hit for getting all email subscription info from API
const ReadAll = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all, { params }).then(callback).catch(error).finally(next);
};

//Delete request for deleting a email subscription based on id
const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const UpdateActivationBidResult = (payload, start, callback, error, next) => {
    start();
    return HttpClient.put(PATH.activationBidResult, payload).then(callback).catch(error).finally(next);
};

export const EmailSubscriptionService = { Create, ReadAll, Delete, UpdateActivationBidResult };
