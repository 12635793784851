import classes from './CollapsibleWithRename.module.css';
import React, { useContext } from 'react';
import Typography from '../Typography/Typography';
import Collapsible from 'react-collapsible';
import { AuthContext } from '../../context/AuthContext';
import { get as LodashGet } from 'lodash';
import { USER_ROLE } from '../../constants';
import { UilLayers } from '@iconscout/react-unicons';

const CollapsibleWithRenameForGroup = ({
    group,
    setDevice,
    open,
    setOpen,
    setCreateGroupModal,
    setEditGroup,
    deviceIcon,
    disableDoubleClick,
    source,
    addDevice,
    content = 'Add Device',
    title = 'Instances',
}) => {
    let timeout = null;

    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');

    const onClick = (e, name) => {
        e.preventDefault();
        if (timeout === null) {
            timeout = window.setTimeout(() => {
                timeout = null;
                open === name ? setOpen('') : setOpen(name);
            }, 300);
        }
    };

    const onDoubleClick = (e) => {
        e.preventDefault();
        window.clearTimeout(timeout);
        setEditGroup({ id: group._id, name: group.name });
        timeout = null;
        setCreateGroupModal(true);
    };

    return (
        <div className={classes.Container}>
            <div className={classes.GroupsContainer}>
                <div
                    role="button"
                    onClick={(e) => onClick(e, title)}
                    onDoubleClickCapture={!disableDoubleClick && [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? onDoubleClick : ''}
                    className={classes.Label}
                >
                    <div className="img-container">{deviceIcon}</div>
                    <div>
                        <Typography size="14" content={title} />
                    </div>
                    <div></div>
                </div>
                <Collapsible open={open === title}>
                    <ul className={classes.DeviceList}>
                        {group?.length > 0 &&
                            group.map((device) => {
                                if (source === 'dataHub' && device.enabled === false) return;
                                return (
                                    <li onClick={() => setDevice(device)}>
                                        <div className={classes.Label} role="button">
                                            <div className="img-container">
                                                <UilLayers size="1.2vw" style={{ color: 'var(--color-primary)' }} />
                                            </div>
                                            <div>
                                                <Typography size="14" content={device.name} />
                                            </div>
                                            <div></div>
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </Collapsible>
            </div>
        </div>
    );
};

export default CollapsibleWithRenameForGroup;
