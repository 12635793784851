import classes from './CarbonEmission.module.css';
import React, { useContext, useEffect, useState } from 'react';
import TabsComponent from '../../components/Tabs/Tabs';
import ChartContainer from './Tabs/ChartContainer';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';
import moment from 'moment';
import { CarbonEmissionService } from '../../services/CarbonEmissionService';
import DatePicker from '../../components/Inputs/DatePicker/DatePicker';
import { DropdownComponent } from '../../components/Inputs/Input';
import ChartComponent from '../../components/ApexCharts/Chart';
import { timeOnly } from '../../utils/dateHelper';
import { get as lodashGet } from 'lodash';
import { AuthContext } from '../../context/AuthContext';

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const CarbonEmission = () => {
    const { state } = useContext(AuthContext);
    const customerArea = lodashGet(state, 'user.customerId.areas');

    const [lastStatus, setLastStatus] = useState('--');
    const [LastStatusFuture, setLastStatusFuture] = useState('--');
    const [emissionData, setEmissionData] = useState([]);
    const [emissionDate, setEmissionDate] = useState(yesterday);

    const [emissionPrognosisData, setEmissionPrognosisData] = useState([]);
    const [emissionPrognosisDate, setEmissionPrognosisDate] = useState(today);

    const [areas, setAreas] = useState([]);
    const [selectedArea, setSelectedArea] = useState({});

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    useEffect(() => {
        const temp = [];
        customerArea.forEach((area) => temp.push({ label: area, value: area }));
        setAreas(temp);
        temp.length && setSelectedArea(temp[0]);
    }, [customerArea]);

    useEffect(() => {
        getEmissionData();
    }, [emissionDate, selectedArea]);

    useEffect(() => {
        getEmissionPrognosisData();
    }, [emissionPrognosisDate]);
    useEffect(() => {
        getLastReported();
    }, [selectedArea]);
    useEffect(() => {
        getLastReportedFuture();
    }, []);
    const getEmissionData = () => {
        if (selectedArea.value) {
            let processedDate = moment(emissionDate).tz('Europe/Berlin').format('YYYY-MM-DD');
            CarbonEmissionService.getData(
                { date: processedDate, type: 'past', area: selectedArea.value },
                () => startLoader('getData'),
                handleGetDataSuccess,
                handleError,
                () => stopLoader('getData')
            );
        }
    };

    const getLastReported = () => {
        if (selectedArea.value) {
            CarbonEmissionService.lastReported(
                { type: 'past', area: selectedArea.value },
                () => startLoader('getConsumptionOverviewGraph'),
                handleGetLastReportedSuccess,
                handleError,
                () => stopLoader('getConsumptionOverviewGraph')
            );
        }
    };
    const getLastReportedFuture = () => {
        CarbonEmissionService.lastReported(
            { type: 'future' },
            () => startLoader('getConsumptionOverviewGraph'),
            handleGetLastReportedFutureSuccess,
            handleError,
            () => stopLoader('getConsumptionOverviewGraph')
        );
    };
    const handleGetLastReportedFutureSuccess = ({ data }) => {
        const date = moment(data.data.carbon.timestamp).tz('Europe/Berlin').format('YYYY-MM-DD');
        setLastStatusFuture(date);
        console.log(date);
    };
    const handleGetLastReportedSuccess = ({ data }) => {
        const date = moment(data.data.carbon.timestamp).tz('Europe/Berlin').format('YYYY-MM-DD');
        setLastStatus(date);
        console.log(date);
    };

    const getEmissionPrognosisData = () => {
        let processedDate = moment(emissionPrognosisDate).tz('Europe/Berlin').format('YYYY-MM-DD');
        CarbonEmissionService.getData(
            { date: processedDate, type: 'future' },
            () => startLoader('getPrognosisData'),
            handleGetPrognosisDataSuccess,
            handleError,
            () => stopLoader('getPrognosisData')
        );
    };

    const handleGetDataSuccess = ({ data }) => {
        setEmissionData(data.data.graph);
    };

    const handleGetPrognosisDataSuccess = ({ data }) => {
        setEmissionPrognosisData(data.data.graph);
    };

    const handleError = (err) => console.log(err);

    let tabs = [
        {
            name: 'Emission',
            component: (
                <>
                    <div className={classes.FilterBar}>
                        <div>
                            <div className={classes.FieldControl2}>
                                <label for="area">Area</label>
                                <DropdownComponent name="area" label="area" options={areas} defaultValue={selectedArea} onChange={setSelectedArea} />
                            </div>
                        </div>
                        <div>
                            <DatePicker date={emissionDate} setDate={setEmissionDate} />
                        </div>
                    </div>

                    <div className={classes.BottomContainer}>
                        <ChartComponent series={emissionData} title="Carbon Emission" xFormatter={timeOnly} type="line" curve="stepline" lastReported={lastStatus} />
                    </div>
                </>
            ),
        },
        {
            name: 'Emission Prognosis',
            component: <ChartContainer lastStatus={LastStatusFuture} graphData={emissionPrognosisData} date={emissionPrognosisDate} setDate={setEmissionPrognosisDate} />,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent tabs={tabs} />
        </div>
    );
};

export default CarbonEmission;
