import classes from '../../styles/Tabs.module.css';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ModalComponent from '../ModalComponent/ModalComponent';
import Settings from '../../assets/settings.png';
import EditIcon from '../../assets/settingicon.svg';
import RestartIcon from '../../assets/restartIcon.svg';
import ApiIcon from '../../assets/api.svg';
import FrequencyIcon from '../../assets/frequency.svg';
import KettleIcon from '../../assets/kettle.svg';
import { UilImport } from '@iconscout/react-unicons';

const TabsComponent = ({
    tabs,
    modal = null,
    modalComponent,
    isOpen = false,
    setOpen,
    selectedIndex,
    setEditDevice = null,
    setPrefillGroup = null,
    resetIndex = null,
    setConfigOpen,
    setRestartModel,
    setEditGroup = null,
    setExternalApiModal = null,
    setDevice = null,
    setFrequencyModal = null,
    setBoilerConfigModal = null,
    onChangeTab = null,
    setShowExportModal = null,
}) => {
    const [tabIndex, setTabIndex] = useState(selectedIndex ? selectedIndex : 0);
    useEffect(() => {
        if (tabIndex !== 0) setDevice && setDevice({ uId: '', name: '' });
    }, [tabIndex, setDevice]);
    useEffect(() => {
        if (selectedIndex >= 0) setTabIndex(selectedIndex);
    }, [selectedIndex]);

    useEffect(() => {
        if (resetIndex) setTabIndex(0);
    }, [resetIndex]);

    const handleTabChange = (index) => {
        if (setEditDevice && index !== selectedIndex) {
            setEditDevice({});
            if (setPrefillGroup) setPrefillGroup('');
        }
        if (setEditGroup && index !== selectedIndex) {
            setEditGroup({});
        }
        setTabIndex(index);
        onChangeTab && onChangeTab(index);
    };

    return (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => handleTabChange(index)}>
            <TabList>
                {tabs.map((tab) => (
                    <Tab>{tab.name}</Tab>
                ))}
                <div className={classes.ModalSettings}>
                    {setRestartModel ? (
                        <div onClick={() => setRestartModel(true)}>
                            <img src={RestartIcon} alt="" />
                        </div>
                    ) : null}
                    {setConfigOpen ? (
                        <div onClick={() => setConfigOpen(true)}>
                            <img src={EditIcon} alt="" />
                        </div>
                    ) : null}
                    {modal ? (
                        <div onClick={() => setOpen(true)}>
                            <img src={Settings} alt="" />
                        </div>
                    ) : null}
                    {setExternalApiModal ? (
                        <div onClick={() => setExternalApiModal(true)}>
                            <img src={ApiIcon} alt="ApiIcon" />
                        </div>
                    ) : null}
                    {setFrequencyModal ? (
                        <div onClick={() => setFrequencyModal(true)}>
                            <img src={FrequencyIcon} alt="ApiIcon" />
                        </div>
                    ) : null}
                    {setBoilerConfigModal ? (
                        <div onClick={() => setBoilerConfigModal(true)}>
                            <img src={KettleIcon} alt="ApiIcon" />
                        </div>
                    ) : null}
                    {setShowExportModal ? (
                        <UilImport size="1.5vw" style={{ color: 'var(--color-primary)', cursor: 'pointer', paddingBottom: '6px' }} onClick={() => setShowExportModal(true)} />
                    ) : null}
                </div>
            </TabList>

            {tabs.map((tab) => (
                <TabPanel style={{ marginTop: '1.5vw' }}>{tab.component}</TabPanel>
            ))}
            {modal ? (
                <ModalComponent isOpen={isOpen} setOpen={setOpen}>
                    {modalComponent}
                </ModalComponent>
            ) : null}
        </Tabs>
    );
};

export default TabsComponent;
