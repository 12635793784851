import classes from './Table.module.css';
import React from 'react';
import NoData from '../../assets/noData.png';
import { toast } from 'react-toastify';

const TableWithHeading = ({ data, head, keys, count, loading, heading }) => {
    return (
        <div className={classes.Table}>
            <div className={classes.Header}>
                <div className={classes.TableHeading}>{heading}</div>
                {count ? (
                    <div className={classes.TableCount}>
                        Total Count : <span className="black">{count}</span>
                    </div>
                ) : null}
            </div>
            <div className={classes.Content}>
                <table>
                    <tr className={classes.TableHeader}>{head && head.map((head) => <th>{head}</th>)}</tr>
                    {loading ? (
                        <tr>
                            <td colSpan={head.length}>Loading...</td>{' '}
                        </tr>
                    ) : null}
                    {data.length && !loading ? (
                        data.map((row) => (
                            <tr>
                                {keys.map((key) => (
                                    <td>{row[key]}</td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">
                                <div className={classes.NoData}>
                                    <img src={NoData} alt="" />
                                    <div>No Records To Display</div>
                                </div>
                            </td>
                        </tr>
                    )}
                </table>
            </div>
        </div>
    );
};

export default TableWithHeading;
