import classes from '../../../styles/AllDevices.module.css';
import React, { useContext, useEffect, useState } from 'react';
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { START_LOADER, STOP_LOADER } from '../../../constants';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import { LicenseService } from '../../../services/LicenseService';
import { LoaderContext } from '../../../context/LoaderContext';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';

const AllInstances = ({ devices }) => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const [instances, setInstances] = useState([]);

    useEffect(() => {
        fetchLicenseTypes();
    }, []);

    const handleError = (err) => {
        console.log(err);
    };

    const handleGetLicenseTypesSuccess = ({ data }) => {
        const licenseTypesHash = {};
        data?.data.forEach((type) => {
            licenseTypesHash[type._id] = type;
        });

        const processedData = devices.map((instance, index) => {
            return {
                's.no': index + 1,
                name: instance.name,
                description: !instance?.description ? (
                    '--'
                ) : instance.description.length > 40 ? (
                    <CustomTooltip content={instance.description}>{instance.description.substring(0, 40) + '...'}</CustomTooltip>
                ) : (
                    instance.description
                ),
                licenseType: licenseTypesHash[instance.licenseId].name,
                market: instance?.market?.length ? instance.market.join(',') : '--',
                monitoring: instance?.monitoring ? instance.monitoring : false,
                active: instance?.active ? instance.active : false,
                id: instance._id,
            };
        });
        setInstances(processedData);
    };

    const fetchLicenseTypes = () => {
        LicenseService.getLicenseTypes(
            () => startLoader('getInstances'),
            handleGetLicenseTypesSuccess,
            handleError,
            () => stopLoader('getInstances')
        );
    };

    const handleExport = () => {
        const processedData = [];
        devices.map((device) => {
            processedData.push({
                groupName: device.name,
                frequencySignal: device.populatedFrequency?.props.children,
                fcr: device.properties?.fcr?.enabled,
                decreased: device.properties?.fcr?.decreased,
                increased: device.properties?.fcr?.increased,
                groupId: device._id,
            });
        });
        DownloadAsExcel(processedData, 'Generic_Instance', ['Group Name', 'Frequency Signal', 'FCR', 'Decreased', 'Increased', 'Group ID']);
    };
    return (
        <div className={classes.AllDevices}>
            <div className={classes.Header}>
                <div>
                    <Typography content="All Instances" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={devices.length} />
                        </span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                        <DownloadButton size="medium" onClick={() => handleExport()} />
                    </div>
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', 'Name', 'Description', 'License Type', 'Market', 'Monitoring', 'Active', 'INSTANCE ID']}
                    keys={['s.no', 'name', 'description', 'licenseType', 'market', 'monitoring', 'active', 'id']}
                    data={instances}
                />
            </div>
        </div>
    );
};

export default AllInstances;
