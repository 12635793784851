import classes from './Chart.module.css';
import React from 'react';
import Chart from 'react-apexcharts';
import Typography from '../Typography/Typography';
import { usToEuCurrencyFormat } from '../../utils/currencyHelper';
import DatePicker from '../Inputs/DatePicker/DatePicker';
import DownloadButton from '../Buttons/DownloadButton';
import moment from 'moment-timezone';
import { DownloadAsExcel } from '../../utils/downloadAsExcel';

const ChartWithDateSelector = ({
    title,
    series,
    curve,
    colors,
    strokeWidth,
    markerSize,
    setDate,
    date,
    type,
    disableZoom,
    xFormatter = null,
    startDate = null,
    annotations = null,
    yProps = null,
    lastReported = null,
    yFormatter = null,
    showDownloadButton = false,
    deviceName = '',
    consumptionData = [],
    lastReportedStyle = {},
}) => {
    const state = {
        options: {
            annotations: { position: 'front', ...annotations },

            chart: {
                id: 'basic-bar',
                zoom: {
                    enabled: !disableZoom,
                },
                toolbar: {
                    show: true,
                    tools: {
                        reset: true | '<img src="/static/icons/reset.png" className="reset-icon" width="25">',
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            fill: {
                type: 'line',
            },
            stroke: {
                show: true,
                curve: curve || 'smooth',
                lineCap: 'butt',
                width: strokeWidth || 2,
                dashArray: 0,
            },
            dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
                color: 'yellow',
            },
            markers: {
                size: markerSize || 0,
                colors: colors || ['#40D3E6'],
                strokeColors: colors || ['#40D3E6'],
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: 'circle',
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: undefined,
                    sizeOffset: 3,
                },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    formatter: xFormatter ? (value, timestamp, opts) => xFormatter(value) : (value) => value,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    style: {
                        fontSize: '0.825vw',
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: yFormatter ? (value, timestamp, opts) => usToEuCurrencyFormat(parseFloat(value).toFixed(0)) : (value) => usToEuCurrencyFormat(value),
                    style: {
                        fontSize: '0.825vw',
                    },
                },
                ...yProps,
            },
            legend: {
                itemMargin: {
                    vertical: 10,
                },
            },
        },
        series,
    };

    const handleExport = () => {
        let downloadData = [];
        series[0]?.data?.length &&
            series[0]?.data?.map((item, index) => {
                let temp = {
                    date: item.x,
                    power: item.y,
                };
                if (consumptionData.length) {
                    temp.consumption = consumptionData?.[index].y ? consumptionData?.[index].y : '--';
                }
                downloadData.push(temp);
            });

        const headers = ['Date & Time', 'Power'];

        if (consumptionData.length) {
            headers.push('Consumption');
        }

        downloadData?.length && DownloadAsExcel(downloadData, `${deviceName} Power Data ${moment(date).tz('Europe/Berlin').format('YYYY-MM-DD')}`, headers);
    };

    return (
        <div className={classes.ChartContainer}>
            <div className={classes.Head}>
                <div>
                    <div className={classes.Title}>{title}</div>
                    {lastReported ? (
                        <div className={classes.lastReported} style={lastReportedStyle}>
                            <Typography subtext content={'Last Reported: ' + (lastReported || ' - ')} />
                        </div>
                    ) : null}
                </div>

                <div style={{ display: 'flex', gap: '1vw' }}>
                    <DatePicker date={date} setDate={setDate} startDate={startDate} />
                    {showDownloadButton && <DownloadButton size="meduim" onClick={handleExport} />}
                </div>
            </div>
            <Chart options={state.options} series={state.series} type={type || 'area'} height="80%" />
        </div>
    );
};

export default ChartWithDateSelector;
