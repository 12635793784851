import { HttpClient } from '../utils/httpClient';

const PATH = {
    instanceCapacity: '/client/data/instance-capacity',
};

const GetInstanceCapacity = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.instanceCapacity}`, { params }).then(callback).catch(error).finally(next);
};

export const BidMarketService = {
    GetInstanceCapacity,
};
