import React, { useContext, useState, useEffect } from 'react';
import Table from '../../../components/Table/Table';
import classes from '../../../styles/Alerts.module.css';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { Form, Formik } from 'formik';
import Typography from '../../../components/Typography/Typography';
import { Input, ToggleButton } from '../../../components/Inputs/Input';
import { CRITERIA_DROPDOWN, USER_ROLE } from '../../../constants';
import { CreateAlertValidation } from '../../../validations/Alerts/CreateAlertValidation';
import { UilRedo } from '@iconscout/react-unicons';
import { AuthContext } from '../../../context/AuthContext';
import { get as LodashGet } from 'lodash';
import CreateButton from '../../../components/Buttons/CreateButton';
import Dropdown from '../../../components/Inputs/Dropdown';
import CustomTimePicker from '../../../components/Inputs/TimePicker/TimePicker';

const AlertsComponent = ({ alerts, handleSubmit, initialValues, open, setOpen, handleClose, getAllAlerts, license, updateQueryParams }) => {
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const [groups, setGroups] = useState([{ label: '', value: '' }]);
    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('23:59');

    const alertType = [
        { label: 'Power', value: 'power' },
        { label: 'Consumption', value: 'consumption' },
    ];

    useEffect(() => {
        if (initialValues?.properties?.startTime) {
            setStartTime(initialValues?.properties?.startTime);
        }
        if (initialValues?.properties?.endTime) {
            setEndTime(initialValues?.properties?.endTime);
        }
    }, [initialValues?.properties?.startTime, initialValues?.properties?.endTime]);

    useEffect(() => {
        if (license?.groups) {
            let allGroups = [];
            license?.groups.map(({ name, _id }) => {
                allGroups.push({
                    label: name,
                    value: _id,
                });
            });
            setGroups(allGroups);
        } else {
            setGroups([{ label: '', value: '' }]);
        }
    }, [license]);
    return (
        <div className={classes.Alert}>
            {[USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? (
                <div className={classes.CreateButton}>
                    <CreateButton
                        size="medium"
                        text="Create Alert"
                        onClick={() => {
                            setOpen(!open);
                            updateQueryParams('createAlertModal', !open);
                        }}
                    />
                    <UilRedo size="1.9vw" style={{ color: 'var(--color-primary)' }} onClick={() => getAllAlerts()} />
                </div>
            ) : null}
            <Table
                head={[
                    'Name',
                    'Instance',
                    'Criteria',
                    'Type',
                    'Threshold/Value',
                    'Alert Interval',
                    'Schedule Start/End',
                    'Email',
                    'Status',
                    ...([USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? ['Actions'] : []),
                ]}
                keys={[
                    'name',
                    'properties.groupName',
                    'typeN',
                    'criteriaN',
                    'value',
                    'alertInterval',
                    'scheduleStartAndEnd',
                    'email',
                    'status',
                    ...([USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? ['action'] : []),
                ]}
                data={alerts}
            />
            <ModalComponent isOpen={open} setOpen={setOpen} onClose={handleClose}>
                <div className={classes.ModalWidth}>
                    <Formik initialValues={initialValues} validationSchema={CreateAlertValidation} onSubmit={(val, { resetForm }) => {
                        val.properties.startTime = startTime;
                        val.properties.endTime = endTime;
                        handleSubmit(val, resetForm);
                    }}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <Typography content="Create Alerts" />
                                    <div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="name">
                                                    Name <span className="required">*</span>
                                                </label>
                                                <Input name="name" id="name" />
                                            </div>

                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="type">
                                                    Type <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Dropdown
                                                        name="properties.type"
                                                        options={alertType}
                                                        defaultValue={values?.properties?.type && alertType.filter((type) => type.value === values?.properties?.type)[0]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="criteria">
                                                    Instances <span className="required">*</span>
                                                </label>
                                                <Dropdown
                                                    name="properties.group"
                                                    options={groups}
                                                    defaultValue={values?.properties?.group && groups.filter((group) => group.value === values?.properties?.group)[0]}
                                                />
                                            </div>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="criteria">
                                                    Criteria <span className="required">*</span>
                                                </label>
                                                <Dropdown
                                                    name="criteria"
                                                    options={CRITERIA_DROPDOWN}
                                                    defaultValue={
                                                        values?.criteria ? CRITERIA_DROPDOWN.filter((criteria) => criteria.value === values?.criteria)[0] : CRITERIA_DROPDOWN[0]
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="value">
                                                    Value <span className="required">*</span>
                                                </label>
                                                <Input name="value" id="value" />
                                            </div>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="email">
                                                    Email <span className="required">*</span>
                                                </label>
                                                <Input name="email" id="email" />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="startTime">
                                                    Start Time
                                                </label>
                                                <CustomTimePicker startTime={startTime} setStartTime={setStartTime} type="start" />
                                            </div>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="endTime">
                                                    End Time
                                                </label>
                                                <CustomTimePicker endTime={endTime} setEndTime={setEndTime} type="end" />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <label for="alertInterval">
                                                    Alert Interval Minutes <span className="required">*</span>
                                                </label>
                                                <Input name="properties.alertInterval" id="alertInterval" />
                                            </div>
                                            <div className={classes.FieldControl2 + ' ' + classes.FieldControlGrow}>
                                                <ToggleButton label={'Status'} values={values} name="properties.notification" id="notification" />
                                            </div>
                                        </div>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <div
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setOpen(false);
                                                        updateQueryParams('createAlertModal', false);
                                                    }}
                                                >
                                                    Cancel
                                                </div>
                                            </div>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>
        </div>
    );
};

export default AlertsComponent;
