import { HttpClient } from '../utils/httpClient';

const PATH = {
    requestBids: '/client/data/bid-request',
    maintenanceHour: '/client/data/maintenance-hour',
};

const GetBidRequest = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.requestBids}`, { params }).then(callback).catch(error).finally(next);
};

const CreateBidRequest = (body, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.requestBids}`, body).then(callback).catch(error).finally(next);
};

const UpdateBidRequest = (id, body, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.requestBids}/${id}`, body).then(callback).catch(error).finally(next);
};

const DeleteBidRequest = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.requestBids}/${id}`).then(callback).catch(error).finally(next);
};

const GetMaintenanceHour = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.maintenanceHour}`, { params }).then(callback).catch(error).finally(next);
};

const CreateMaintenanceHour = (body, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.maintenanceHour}`, body).then(callback).catch(error).finally(next);
};

const UpdateMaintenanceHour = (id, body, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.maintenanceHour}/${id}`, body).then(callback).catch(error).finally(next);
};

const DeleteMaintenanceHour = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.maintenanceHour}/${id}`).then(callback).catch(error).finally(next);
};

export const BidRequestService = {
    GetBidRequest,
    CreateBidRequest,
    UpdateBidRequest,
    DeleteBidRequest,
    GetMaintenanceHour,
    CreateMaintenanceHour,
    UpdateMaintenanceHour,
    DeleteMaintenanceHour,
};
