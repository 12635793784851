// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getEconomicData: '/client/data/eConomics',
    licenseTypes: '/client/data/eConomics/license',
    instance: '/client/data/eConomics/instance',
};

const getEconomicData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getEconomicData}`, { params }).then(callback).catch(error).finally(next);
};

const getLicenseTypes = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.licenseTypes}`).then(callback).catch(error).finally(next);
};
const getInstance = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.instance}`, { params }).then(callback).catch(error).finally(next);
};
export const DataServices = {
    getEconomicData,
    getLicenseTypes,
    getInstance,
};
