import { USER_ROLE } from '../../constants';
import { get as LodashGet } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import classes from '../../styles/Bids.module.css';
import Table from '../../components/Table/Table';
import Typography from '../../components/Typography/Typography';
import { useLoader } from '../../hooks/use-loader.hook';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import RequestContent from './ModalComponent/RequestContent';
import { BidRequestService } from '../../services/BidRequestService';
import { momentTimeFormater } from '../../utils/dateHelper';
import DeleteModalComponent from '../../components/DeleteModal/DeleteModal';
import { toast } from 'react-toastify';
import RequestOverview from './ModalComponent/RequestOverview';
import { DropdownComponent } from '../../components/Inputs/Input';
import { Capitalize } from '../../utils/stringHelper';
import CustomTooltip from '../../components/CustomToolTip/CustomTooltip';
import { UilInfoCircle, UilEye, UilPen, UilTrash } from '@iconscout/react-unicons';

const getGroupHashMap = (groups) => {
    const hashMap = {};
    groups.forEach((g) => {
        hashMap[g._id] = g;
    });
    return hashMap;
};

const RequestBid = ({ market, groups = [], licenseId, options = [], selectedOption, onChange, updateQueryParams, queryParamsData }) => {
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');
    const [startLoader, stopLoader] = useLoader();
    const [groupHashMap, setGroupHashMap] = useState({});
    const [requests, setRequests] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [openRequestModal, setOpenRequestModal] = useState(false);
    const [editRequest, setEditRequest] = useState({});
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [overviewModal, setoverviewModal] = useState({ status: false, request: {} });
    const [filterType, setFilterType] = useState(queryParamsData.filter ? queryParamsData.filter : { label: 'Current', value: 'current' });

    useEffect(() => {
        if (licenseId && Object.keys(groupHashMap).length) {
            fetchRequests();
        }
    }, [refresh, licenseId, groupHashMap, filterType]);

    useEffect(() => {
        updateQueryParams('filter', JSON.stringify(filterType));
    }, [filterType]);

    useEffect(() => {
        if (groups.length) {
            const groupsHash = getGroupHashMap(groups);
            setGroupHashMap(groupsHash);
        }
    }, [groups]);

    const fetchRequests = () => {
        const params = {
            filterType: filterType?.value,
            licenseId,
            market,
        };
        BidRequestService.GetBidRequest(params, startLoader, handleFetchSuccess, handleError, stopLoader);
    };

    const openEditModal = (vals) => {
        setEditRequest(vals);
        setOpenRequestModal(true);
    };

    const closeCreateEditModal = (status) => {
        setOpenRequestModal(status);
        setEditRequest({});
    };

    const closeOverviewModal = (status) => {
        setoverviewModal({ status, request: {} });
    };

    const handleFetchSuccess = ({ data }) => {
        let processed = [];
        if (Array.isArray(data.data)) {
            processed = data.data.map((i) => ({
                ...i,
                startDate: momentTimeFormater(i.startDate).format('YYYY-MM-DD'),
                endDate: momentTimeFormater(i.endDate).format('YYYY-MM-DD'),
                createdAt: momentTimeFormater(i.createdAt).format('YYYY-MM-DD'),
                updatedAt: momentTimeFormater(i.updatedAt).format('YYYY-MM-DD'),
                noBid: i.excludedDays?.length,
                instance: groupHashMap[i.groupId]?.name,
                status: i?.status ? Capitalize(i?.status) : '--',
                biddingMarket: i?.biddingMarket ? Capitalize(i?.biddingMarket) : '--',
                action: (
                    <div
                        style={{
                            display: 'flex',
                            gridColumnGap: '0.5vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: '#000000',
                            cursor: 'pointer',
                        }}
                    >
                        <div onClick={() => setoverviewModal({ status: true, request: i })}>
                            <UilEye size="1.2vw" />
                        </div>
                        {i?.status === 'pending' && [USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) && (
                            <>
                                <div onClick={() => openEditModal(i)}>
                                    <UilPen size="1.2vw" />
                                </div>
                                <div onClick={() => setDeleteModal({ status: true, id: i._id })}>
                                    <UilTrash size="1.2vw" />
                                </div>
                            </>
                        )}
                        {i?.status === 'placed' && (
                            <>
                                <CustomTooltip content={'The request has been sent to Energinet, incase of any change reach out to us over email.'}>
                                    <UilInfoCircle size="1.2vw" />
                                </CustomTooltip>
                            </>
                        )}
                    </div>
                ),
            }));
        }
        setRequests(processed);
    };

    const handleDelete = (id) => {
        BidRequestService.DeleteBidRequest(id, startLoader, deleteSuccess, handleError, stopLoader);
    };

    const deleteSuccess = () => {
        toast.success('Bid request deleted successfully!');
        setRefresh(Math.random() * 1000);
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className={classes.Bids}>
            <div className={classes.Header} style={{ marginTop: '1vw' }}>
                <div>
                    <Typography content="Request Bid" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={requests.length} />
                        </span>
                    </div>
                    <div className={classes.InputContainer}>
                        <div className={classes.FieldControl2}>
                            <div style={{ minWidth: '10vw' }}>
                                <DropdownComponent
                                    name="license"
                                    options={options}
                                    defaultValue={selectedOption?.value ? selectedOption : options[0]}
                                    onChange={(e) => {
                                        if (e.value !== selectedOption?.value) {
                                            onChange(e);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '0.5vw' }}>
                    <div className={classes.InputContainer}>
                        <div className={classes.FieldControl2}>
                            <div style={{ minWidth: '10vw' }}>
                                <DropdownComponent
                                    name="type"
                                    options={[
                                        { label: 'Current', value: 'current' },
                                        { label: 'Past', value: 'past' },
                                    ]}
                                    defaultValue={filterType}
                                    onChange={(e) => {
                                        if (e.value !== filterType?.value) {
                                            setFilterType(e);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.ButtonsContainer}>
                        <div>
                            {[USER_ROLE.SUDOER, USER_ROLE.EXPERT].includes(userRole) ? (
                                <button
                                    onClick={() => {
                                        setOpenRequestModal(true);
                                    }}
                                    style={{ width: '7vw', margin: 0 }}
                                    className="btn-primary"
                                >
                                    Request
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <Table
                head={['Start Date', 'End Date', 'Excluded Count', 'Created At', 'Updated At', 'Instance', 'Market', 'Status', 'Action']}
                keys={['startDate', 'endDate', 'noBid', 'createdAt', 'updatedAt', 'instance', 'biddingMarket', 'status', 'action']}
                data={requests}
            />
            <ModalComponent isOpen={openRequestModal} setOpen={closeCreateEditModal} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                <RequestContent market={market} groups={groups} setOpen={closeCreateEditModal} setRefresh={setRefresh} licenseId={licenseId} editRequest={editRequest} />
            </ModalComponent>
            <ModalComponent isOpen={overviewModal.status} setOpen={closeOverviewModal} style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
                <RequestOverview request={overviewModal.request} setOpen={closeOverviewModal} />
            </ModalComponent>
            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ id: '', status })}
                deviceId={deleteModal.id}
            ></DeleteModalComponent>
        </div>
    );
};

export default RequestBid;
