// Standard library imports
import React, { useState, useEffect } from 'react';

// External library imports
import { UilRedo, UilImport } from '@iconscout/react-unicons';
import moment from 'moment';

// Internal module imports
import Table from '../../../components/Table/Table';
import classes from '../../../styles/AllDevices.module.css';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import Typography from '../../../components/Typography/Typography';
import myClasses from '../E3App.module.css';
import { DashboardService } from '../../../services/DashboardService';
import { momentTimeFormater } from '../../../utils/dateHelper';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import { InputWithoutFormik } from '../../../components/Inputs/Input';
import IntMetaLogModal from '../ModalComponent/IntMetaLogsModal';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';

const style = {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gridColumnGap: '1vw',
};

const Events = ({ license, source, startLoader, stopLoader, setEditDevice, devices, updateQueryParams, queryParamsData }) => {
    const [activationEvents, setActivationEvents] = useState([]);
    const [limit, setLimit] = useState(queryParamsData.limit ? queryParamsData.limit : 100);
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date());
    const [logModal, setLogModal] = useState({
        status: false,
        data: [],
    });

    useEffect(() => {
        fetchActivationsData();
    }, [limit, date, license]);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        updateQueryParams('limit', limit);
    }, [limit]);

    const { _id: licenseId, type: licenseType } = license;

    const fetchActivationsData = () => {
        DashboardService.getAppActivationData(
            { licenseId, limit, date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD') },
            startLoader,
            handleActivationDataSuccess,
            handleError,
            stopLoader
        );
    };

    const handleActivationDataSuccess = (response) => {
        const datas = response.data?.data;
        const processEvents = [];

        for (let data in datas) {
            const log = datas[data];
            processEvents.push({
                activationId: data,
                groupName: log.instanceName ? log.instanceName : '--',
                startTime: log.startTime ? momentTimeFormater(log.startTime).format('YYYY-MM-DD HH:mm:ss') : '--',
                endTime: log.endTime ? momentTimeFormater(log.endTime).format('YYYY-MM-DD HH:mm:ss') : '--',
                duration: log.duration ? log.duration : '--',
                action: (
                    <div className={myClasses.LogContainer} onClick={() => setLogModal({ status: true, data: log.logs })}>
                        logs
                    </div>
                ),
            });
        }
        processEvents.sort((a, b) => moment(b.startTime).valueOf() - moment(a.startTime).valueOf());
        setActivationEvents(processEvents);
    };

    const handleError = (err) => {
        console.log(err);
    };
    const handleExport = (type) => {
        const excelData = activationEvents.map((data) => {
            return {
                v: data.activationId,
                w: data.groupName,
                u: data.startTime,
                y: data.endTime,
                z: data.duration,
            };
        });
        DownloadAsExcel(excelData, `${licenseType} ${type} Events`, ['ACTIVATION ID', 'GROUP NAME', 'START TIME (CET)', 'END TIME (CET)', 'DURATION(SECONDS)']);
    };

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <div className={classes.Alert}>
                <div className={myClasses.wholeFilterWrapper}>
                    <div className={myClasses.HeadingContainer}>
                        <Typography size={14} content={`Command Logs`} />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={activationEvents.length} />
                            </span>
                        </div>
                    </div>
                    <div style={style}>
                        <DatePicker date={date} setDate={setDate} />
                        <div className={'inputStyle'} style={{ display: 'flex', alignItems: 'center', gap: '0.2vw' }}>
                            <span>Limit: </span>
                            <InputWithoutFormik
                                type="number"
                                name="limit"
                                id=""
                                value={limit}
                                onChange={(e) => {
                                    setLimit(e.target.value);
                                }}
                            />
                        </div>
                        <UilRedo size="1.8vw" style={{ color: 'var(--color-primary)' }} onClick={fetchActivationsData} />
                        <UilImport size="1.8vw" style={{ color: 'var(--color-primary)' }} onClick={() => handleExport('activation')} />
                    </div>
                </div>
                <Table
                    head={['ACTIVATION ID', 'GROUP NAME', 'START TIME (CET)', 'END TIME (CET)	', 'DURATION(SECONDS)	', 'LOGS']}
                    keys={['activationId', 'groupName', 'startTime', 'endTime', 'duration', 'action']}
                    data={activationEvents}
                />
                <ModalComponent isOpen={logModal.status} setOpen={() => setLogModal({ status: false, data: [] })}>
                    <IntMetaLogModal setOpen={() => setLogModal({ status: false, data: [] })} logData={logModal.data} />
                </ModalComponent>
            </div>
        </div>
    );
};

export default Events;
