import React, { useEffect, useState } from 'react';
import momentTz from 'moment-timezone';
import ChartComponent from '../../../components/ApexCharts/Chart';
import { momentTimeFormater, timeOnly } from '../../../utils/dateHelper';
import classes from '../Home.module.css';
import { useLoader } from '../../../hooks/use-loader.hook';
import { FrequencyService } from '../../../services/FrequencyService';
import { usToEuCurrencyFormat } from '../../../utils/currencyHelper';

const AfrrCard = ({ groupId }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [group, setGroup] = useState({});
    const [lastStatus, setLastStatus] = useState('--');
    const [lastReportedTimeStamp, setLastReportedTimeStamp] = useState();

    useEffect(() => {
        getLastReportedTimeStamp();
    }, []);

    useEffect(() => {
        lastReportedTimeStamp && getData(lastReportedTimeStamp);
    }, [lastReportedTimeStamp]);

    const getLastReportedTimeStamp = () => {
        if (groupId) {
            FrequencyService.capacityLastReported(
                () => startLoader('getData1'),
                handleLastReportedSuccess,
                handleError,
                () => stopLoader('getData1'),
                'aFRR',
                groupId
            );
        }
    };

    const handleLastReportedSuccess = (lastReportedDate) => {
        const lastReported = lastReportedDate.data.data?.capacity?.timestamp;
        if (lastReported) {
            setLastReportedTimeStamp(lastReported);
            const date = momentTimeFormater(lastReported).format('YYYY-MM-DD HH:mm:ss');
            setLastStatus(date);
        }
    };

    const getData = (date) => {
        const curCetDateTime = momentTimeFormater(date);
        const params = {
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
            endTime: curCetDateTime.add(1, 'minute').format('HH:mm'),
            startTime: curCetDateTime.subtract(16, 'minutes').format('HH:mm'),
            market: 'aFRR',
            instanceId: groupId,
        };
        FrequencyService.getPowerCapacity(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const { graph, group } = data?.data;
        if (graph[0]?.data?.length > 0) {
            setData(graph);
        } else {
            setData([]);
        }
        if (group) setGroup(group);
        else {
            setGroup({});
        }
    };

    const handleError = (error) => {
        console.error(error);
    };

    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };

    return (
        <div className={classes.TopGraph} style={{ flexGrow: 2 }}>
            <div className={classes.FilterDropdown + ' filter-dropdown'}>
                <div className={classes.ChartGroupName}>{group?.name || ''}</div>
            </div>
            <ChartComponent
                title="aFRR Power Capacity (kW)"
                strokeWidth={2}
                type="line"
                colors={['var(--color-primary)']}
                series={data}
                xFormatter={timeOnly}
                yFormatter={formatYAxisValue}
                yProps={{ tickAmount: 5 }}
                lastReported={lastStatus}
            />
        </div>
    );
};

export default AfrrCard;
