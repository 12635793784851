import { HttpClient } from '../utils/httpClient';

const PATH = {
    getData: '/client/data/carbon-emission',
    lastReported: '/client/data/carbon-emission/last-reported',
};

const getData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getData}`, { params }).then(callback).catch(error).finally(next);
};
const lastReported = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.lastReported}`, { params }).then(callback).catch(error).finally(next);
};

const updateTax = (values, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.updateTax}`, values).then(callback).catch(error).finally(next);
};

export const CarbonEmissionService = {
    getData,
    updateTax,
    lastReported,
};
