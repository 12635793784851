import classes from './VersionHistory.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { VersionHistoryService } from '../../services/VersionHistoryService';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';
import { toast } from 'react-toastify';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import VersionDetails from './versionDetails';
import { momentTimeFormater } from '../../utils/dateHelper';
import NoDataComponent from '../../components/NoDataComponent';

const VersionHistory = () => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [selectedVersion, setSelectedVersion] = useState({
        status: false,
        version: {},
    });
    const [versions, setVersions] = useState([]);

    useEffect(() => {
        VersionHistoryService.getVersionHistory(
            () => startLoader('getVersions'),
            handleSuccess,
            handleError,
            () => stopLoader('getVersions')
        );
    }, []);

    const handleSuccess = ({ data }) => {
        setVersions(data.data);
        console.log(data.data);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const closeVersionDetails = () => {
        setSelectedVersion({ status: false, version: {} });
    };

    const getSubList = (text) => {
        let splitText = text?.split('\n').filter((e) => !!e.replace(/\s/g, ''));
        if (splitText.length > 3) {
            splitText = splitText.slice(0, 3);
            splitText[2] += '...';
        }
        return splitText.map((e) => <li>{e}</li>);
    };

    return (
        <>
            <ModalComponent isOpen={selectedVersion.status} setOpen={closeVersionDetails} style={{ maxWidth: '50vw' }}>
                <VersionDetails setOpen={closeVersionDetails} version={selectedVersion.version} />
            </ModalComponent>
            {versions.length > 0 ? (
                <div className={classes.CardsContainer}>
                    {versions.map((version) => (
                        <div className={classes.Card} onClick={() => setSelectedVersion({ status: true, version })}>
                            <div>
                                <div className={classes.Title}> {version.title}</div>
                                <div className={classes.SubTitleWrapper}>
                                    <div className={classes.SubTitle}> {version.application}</div>
                                    <div className={classes.SubTitle}>{version.released ? momentTimeFormater(version.released).format('YYYY-MM-DD') : '--'}</div>
                                </div>
                            </div>
                            <div className={classes.Content}>
                                <ul>{version.description?.length > 200 ? getSubList(version.description?.substring(0, 200) + '...') : getSubList(version.description)}</ul>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <NoDataComponent />
            )}
        </>
    );
};

export default VersionHistory;
