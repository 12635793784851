import { SET_USER, REMOVE_USER } from '../../constants';

export const AuthReducer = (state, action) => {
    switch (action.type) {
        case SET_USER:
            if (action.payload.token) localStorage.setItem('GM_TOKEN', action.payload.token);
            localStorage.setItem('THEME', JSON.stringify(action.payload.user?.customerId?.theme));
            return {
                ...state,
                user: action.payload.user,
                isAuthorized: true,
            };
        case REMOVE_USER:
            localStorage.removeItem('GM_TOKEN');
            return {
                ...state,
                user: {},
                isAuthorized: false,
            };
        default:
            return state;
    }
};
