// Standard library imports
import React, { useContext } from 'react';

// External library imports
import { get as lodashGet } from 'lodash';

// Internal module imports
import { AuthContext } from '../../context/AuthContext';
import FrequencyCard from './GraphComponents/FrequencyCard';
import FcrDCard from './GraphComponents/FcrDCard';
import MonthsRevenueCard from './GraphComponents/MonthsRevenueCard';
import NordPoolCard from './GraphComponents/NordPoolCard';
import EventFaultCard from './GraphComponents/EventFaultCard';
import AfrrCard from './GraphComponents/AFrrCard';
import MarketInstanceCapacityCard from './GraphComponents/MarketInstanceCapacityCard';
import FfrCard from './GraphComponents/FfrCard';
import LicenseOverview from './LicenseOverview';
// Css imports
import classes from './Home.module.css';

const getBiddingComponents = ({ value, groupId = '' }) => {
    switch (value) {
        case 'FCR-D-INC':
            return <FcrDCard groupId={groupId} />;
        case 'aFRR':
            return <AfrrCard groupId={groupId} />;
        case 'FFR':
            return <FfrCard groupId={groupId} />;
        default:
            return <></>;
    }
};

const getR2Components = ({ value, groupId }) => {
    return <LicenseOverview groupId={groupId} license={value} />;
};

const getR3Components = ({ value, groupId }) => {
    return <MarketInstanceCapacityCard market={value} groupId={groupId} />;
};

const Home = () => {
    const { state } = useContext(AuthContext);
    const isCustomUser = lodashGet(state, 'user.userRole') === 'custom';
    const customRoutes = isCustomUser ? state.user.allowed : null;
    const e3DashboardConfig = lodashGet(state, 'user.customerId.e3DashboardConfig');

    return (
        <div className={classes.Home}>
            {e3DashboardConfig?.enableFirstRow && (
                <div className={classes.ChartContainer}>
                    {e3DashboardConfig?.r1c1?.value && <FrequencyCard />}
                    {e3DashboardConfig?.r1c2?.value && getBiddingComponents(e3DashboardConfig?.r1c2)}
                    {e3DashboardConfig?.r1c3?.value && <MonthsRevenueCard />}
                </div>
            )}
            {e3DashboardConfig?.enableSecondRow && (
                <div className={classes.ChartContainer}>
                    {e3DashboardConfig?.r2c1?.value && getR2Components(e3DashboardConfig?.r2c1)}
                    {e3DashboardConfig?.r2c2?.value && getR2Components(e3DashboardConfig?.r2c2)}
                </div>
            )}
            {e3DashboardConfig?.enableThirdRow && (
                <div className={classes.ChartContainer}>
                    {e3DashboardConfig?.r3c1?.value && getR3Components(e3DashboardConfig?.r3c1)}
                    {e3DashboardConfig?.r3c2?.value && getR3Components(e3DashboardConfig?.r3c2)}
                </div>
            )}
            {(isCustomUser && customRoutes['nordPool']) || !isCustomUser ? <NordPoolCard /> : null}
            {e3DashboardConfig.enableEventFaultRow && <EventFaultCard />}
        </div>
    );
};

export default Home;
