export const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
};

export const sortByName = (data = []) => {
    let devices = data.sort(compareDeviceName);
    return devices;
};
function compareDeviceName(a, b) {
    let d = a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    return d;
}
export const findMaxPowermanagementGraph = (a, b, arr) => {
    const maxvalue = Math.max(...arr.map((o) => o.y));
    const newarray = [a, b, maxvalue];
    return Math.max(...newarray);
};
